import ResourceList from '../../../components/admin/ResourceList'

export default function UserPagesList() {
    return (
        <>
            <ResourceList
                paginated
                paginationLimit={20}
                getItemsFromResponse={(data) => data?.results}
                apiUrl={'/pages'}
                newItemLabel={'New page'}
                newItemPath={
                    '/all-pages-request-feature?requestFeature=create_pages'
                }
                renderMainButton={true}
                itemClickPath={'/pages/:item.slug'}
                filters={{
                    search: true,
                }}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `pages/${item.parentPage ? `${item.parentPage.slug}/` : ''}${item.slug}`,
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) => item.title,
                        getClassName: () => 'title',
                    },
                ]}
            />
        </>
    )
}
