import { useEffect, useRef, useState } from 'react'
import useData from '../../../common/hooks/useData'
import useOutsideClick from '../../../common/hooks/useOutsideClick'
import useModal from '../../../common/hooks/useModal'
import { useStore } from '../../../common/Context'
import Button from '../Button'
import ResourceList from '../../admin/ResourceList'
import Spinner from '../Spinner'
import TextInput from '../data-form/TextInput'

function AllItemsModalContent({
    url,
    onConfirm,
    getSelectedItemText,
    getItemsFromResponse,
}) {
    const [state, dispatch] = useStore()

    function onCancel() {
        dispatch({ type: 'CLOSE_MODAL' })
    }

    function onSubmit(item) {
        onConfirm(item)
        dispatch({ type: 'CLOSE_MODAL' })
    }

    const apiUrl = url.replace('/v1', '').split('?')[0]
    const query = url
        .replace('/v1', '')
        .split('?')[1]
        .split('&')
        .map((q) => {
            const [key, value] = q.split('=')
            return { key, value }
        })
        .reduce((acc, q) => {
            acc[q.key] = q.value
            return acc
        }, {})

    return (
        <>
            <div>
                <div className="modal-body">
                    <ResourceList
                        apiUrl={apiUrl}
                        initialQuery={query}
                        getSelectedItemText={getSelectedItemText}
                        getItemsFromResponse={getItemsFromResponse}
                        // filters={filters}
                        loadMoreButton
                        isSelectable
                        selectLimit={1}
                        paginated
                        paginationLimit={5}
                        preselectedIds={[]}
                        onSingleItemSelect={(item) => onSubmit(item)}
                        fields={[
                            {
                                column: 1,
                                getValue: getSelectedItemText,
                            },
                        ]}
                    />
                </div>
                <div className="modal-buttons">
                    <Button
                        small
                        destructive
                        link
                        onClick={onCancel}
                        text={'Cancel'}
                        disabled={state.isModalLoading}
                    />
                </div>
            </div>
        </>
    )
}

function LiveSearch({
    id,
    getResultValue,
    url,
    getItemsFromResults,
    items: itemsArr,
    onItemClick,
    showAll = true,
    defaultLabel,
    limit,
    onChange,
    filters,
    searchInExistingOnly,
    errors,
}) {
    const [query, setQuery] = useState('')
    const [debouncedQuery, setDebouncedQuery] = useState('')
    const [isResultsOpen, setIsResultsOpen] = useState(false)
    const [hasSetDefault, setHasSetDefault] = useState(false)
    const debounceTimeoutRef = useRef(undefined)
    const elRef = useOutsideClick(() => setIsResultsOpen(false))
    const { setModal } = useModal()

    useEffect(() => {
        if (!defaultLabel || hasSetDefault) {
            return
        }

        if (!query) {
            setQuery(defaultLabel)
        }
        setHasSetDefault(true)
    }, [defaultLabel])

    useEffect(() => {
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
            }
        }
    }, [])

    // When input ref is clicked, open results. Otherwise, close results.
    useEffect(() => {
        function handleClickOutside(e) {
            if (elRef.current && !elRef.current.contains(e.target)) {
                setIsResultsOpen(false)
            } else {
                setIsResultsOpen(true)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [elRef])

    const { items, itemsError, itemsLoading } = useData(
        debouncedQuery && url
            ? `${url}${url.includes('?') ? '&' : '?'}q=${debouncedQuery}${
                  limit ? `&limit=${limit}&page=1` : ''
              }`
            : null,
        'items',
        getItemsFromResults,
        false,
        false,
        (data) => setIsResultsOpen(getItemsFromResults(data)?.length),
    )

    function debounceSetQuery(newQuery) {
        clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(function () {
            setDebouncedQuery(newQuery)
        }, 500)
    }

    function handleChange(v) {
        setQuery(v || '')

        if (url && !searchInExistingOnly) {
            debounceSetQuery(v || '')
        }
        if (onChange) {
            onChange(v || '')
        }
        if (!v && onItemClick) {
            onItemClick(null)
        }
        // if (hasStrValueOnNull)
    }

    function handleItemClick(item) {
        if (!onItemClick) return
        setIsResultsOpen(false)

        setQuery(getResultValue(item))
        onItemClick(item)
    }

    function openAllPopup() {
        setModal(
            <AllItemsModalContent
                url={url}
                getSelectedItemText={getResultValue}
                onConfirm={handleItemClick}
                getItemsFromResponse={getItemsFromResults}
                filters={filters}
            />,
        )
    }

    let finalItems
    if (url) {
        finalItems = items
    } else if (itemsArr) {
        if (!query) {
            finalItems = itemsArr
        } else {
            if (typeof itemsArr[0] === 'object') {
                finalItems = itemsArr.filter((item) =>
                    getResultValue(item)
                        .toLowerCase()
                        .includes(query.toLowerCase()),
                )
            } else {
                finalItems = itemsArr.filter((item) =>
                    item.toLowerCase().includes(query.toLowerCase()),
                )
            }
        }
    }

    return (
        <div
            ref={elRef}
            className={`live-search${
                isResultsOpen || itemsLoading || itemsError
                    ? ' live-search-open'
                    : ''
            }`}
        >
            <TextInput
                withClear
                autoComplete="off"
                id={id}
                placeholder="Search"
                type="text"
                onChange={handleChange}
                value={query}
                errors={errors}
            />

            {isResultsOpen || itemsLoading || itemsError ? (
                <div className="live-results">
                    {itemsLoading && !itemsError && <Spinner />}

                    {itemsError ? (
                        <div className="live-error">{itemsError}</div>
                    ) : null}

                    {!finalItems?.length && !itemsLoading ? (
                        <div className="live-empty">No results</div>
                    ) : null}

                    {finalItems?.length ? (
                        <ul>
                            {finalItems.map((item, i) => (
                                <li
                                    role="button"
                                    onClick={() => handleItemClick(item)}
                                    key={i}
                                    className={
                                        onItemClick
                                            ? 'live-result-clickable'
                                            : ''
                                    }
                                >
                                    {getResultValue(item)}
                                </li>
                            ))}
                        </ul>
                    ) : null}

                    {showAll && (
                        <div role="button" onClick={openAllPopup} className="a">
                            Show all
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    )
}

export default LiveSearch
