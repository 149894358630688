import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import useError from '../../common/hooks/useError'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useToast from '../../common/hooks/useToast'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import fetchAPI from '../../common/fetchAPI'
import Button from './Button'
import Toggle from './Toggle'
import MainButton from '../admin/MainButton'
import Input from './data-form/Input'
import MessageSection from './MessageSection'
import Spinner from './Spinner'
import ErrorMessage from './ErrorMessage'
import { getErrorsForInput } from '../../common/helpers'
import Break from './Break'

function hasDataChanged(prev, next) {
    if (
        (prev === undefined || prev === '') &&
        (next === undefined || next === '')
    ) {
        return false
    }
    if (prev === null && prev === next) {
        return false
    }
    if (Array.isArray(prev) && Array.isArray(next)) {
        if (prev.length !== next.length) {
            return true
        }
        for (let i = 0; i < prev.length; i++) {
            if (hasDataChanged(prev[i], next[i])) {
                return true
            }
        }
        return false
    }
    if (typeof prev === 'object' && typeof next === 'object') {
        for (const key of Object.keys(prev)) {
            if (hasDataChanged(prev[key], next[key])) {
                return true
            }
        }
        return false
    }
    return prev !== next
}

function getChangedData(prev, next) {
    const changedData = {}
    for (const key of Object.keys(prev)) {
        if (hasDataChanged(prev[key], next[key])) {
            changedData[key] = next[key]
        }
    }
    for (const key of Object.keys(next)) {
        if (key in prev) continue
        changedData[key] = next[key]
    }
    return changedData
}

function populateUrlVariables(url, item) {
    const urlParts = url.split('/')
    const newUrlParts = []
    for (const part of urlParts) {
        if (part.startsWith(':item')) {
            newUrlParts.push(item[part.split('.')[1]])
        } else {
            newUrlParts.push(part)
        }
    }
    return newUrlParts.join('/')
}

const InputItem = memo(
    function InputItem({
        input,
        data,
        setData,
        optionsState,
        setOptionsState,
        errors,
        isScrollField,
    }) {
        const value = data[input.key]

        const handleChange = useCallback((v, multiItemOrStr) => {
            // TODO: fix this, it's because the 2nd arg
            // may be the item in a search input.
            const multiItem =
                typeof multiItemOrStr === 'string' ? multiItemOrStr : null

            let value = v
            setData((data) => {
                if (
                    v?.length &&
                    Array.isArray(v) &&
                    input.type === 'addInputGroupsInput'
                ) {
                    for (let i = 0; i < v.length; i++) {
                        const group = v[i]
                        for (const key of Object.keys(group)) {
                            const inputItem = input.inputs.find(
                                (item) => item.key === key,
                            )
                            if (inputItem.transformValue) {
                                v[i][key] = inputItem.transformValue(
                                    v[i][key],
                                    data,
                                    i,
                                )
                            }
                        }
                    }
                }
                value = input.transformValue ? input.transformValue(v, data) : v
                const key = multiItem ? `${input.key}_${multiItem}` : input.key
                return {
                    ...data,
                    [key]: value,
                }
            })

            if (input.setDataOnChange) {
                setData((data) => input.setDataOnChange(value, data))
            }
        }, [])

        const shouldHide =
            input.shouldHide && input.shouldHide(data, null, optionsState)

        const type =
            input.shouldBeText && input.shouldBeText(data) ? 'text' : input.type

        if (input.getOverride) {
            const overridenRest = input.getOverride(data)
            Object.assign(input, overridenRest)
        }

        if (shouldHide) return null

        // if (input.multiplyFor) {
        //     return input
        //         .multiplyFor(data)
        //         .map((item) => (
        //             <Input
        //                 {...input}
        //                 type={type}
        //                 value={value}
        //                 onChange={(v) => handleChange(v, item)}
        //                 key={`${input.key}_${item}`}
        //                 inputKey={`${input.key}_${item}`}
        //                 label={`${input.label}: ${item}`}
        //                 optionsState={optionsState}
        //                 setOptionsState={setOptionsState}
        //                 errors={errors}
        //                 shouldScrollTo={isScrollField}
        //             />
        //         ))
        // }

        return (
            <Input
                {...input}
                key={input.key}
                inputKey={input.key}
                type={type}
                value={value}
                onChange={handleChange}
                optionsState={optionsState}
                setOptionsState={setOptionsState}
                errors={errors}
                shouldScrollTo={isScrollField}
            />
        )
    },
    (prevProps, nextProps) => {
        if (prevProps.input !== nextProps.input) {
            return false
        }
        if (prevProps.errors !== nextProps.errors) {
            // return false
        }
        if (prevProps.isScrollField !== nextProps.isScrollField) {
            return false
        }
        if (prevProps.optionsState !== nextProps.optionsState) {
            return false
        }
        if (
            prevProps.data[prevProps?.input?.key] !==
            nextProps.data[nextProps?.input?.key]
        ) {
            return false
        }

        if (
            prevProps.input.shouldHide &&
            nextProps.input.shouldHide &&
            prevProps.input.shouldHide(
                prevProps.data,
                null,
                prevProps.optionsState,
            ) !==
                nextProps.input.shouldHide(
                    nextProps.data,
                    null,
                    nextProps.optionsState,
                )
        ) {
            return false
        }

        if (
            prevProps.input.shouldBeText &&
            nextProps.input.shouldBeText &&
            prevProps.input.shouldBeText(prevProps.data) !==
                nextProps.input.shouldBeText(nextProps.data)
        ) {
            return false
        }

        if (
            prevProps.input.getOverride &&
            nextProps.input.getOverride &&
            prevProps.input.getOverride(prevProps.data) !==
                nextProps.input.getOverride(nextProps.data)
        ) {
            return false
        }

        return true
    },
)

export default function DataFormNew({
    inputs = [],
    submitText = 'Submit',
    initData = {},
    submitToast = 'Submitted',
    submitNavArg,
    getSubmitNavArg,
    getSubmitMessage,
    getInitializeAfterSaveFields,
    mutationRegexes = [],
    url,
    getUrl,
    sendQuery,
    method = 'POST',
    getBody,
    mapItemToData,
    onSuccess,
    mainButton,
    fetchItemFirst,
    inline,
    saveAndNewButton,
    onlySaveChanges,
    beforeCompareChanges,
    getOverride,
}) {
    const [data, setData] = useState({
        ...inputs.reduce((a, b) => ({ ...a, [b.key]: b.defaultValue }), {}),
        ...initData,
    })

    const [loading, setLoading] = useState(false)
    const [error, setError, errorFields] = useError(null, {
        linksInMessage: true,
    })
    const [message, setMessage] = useState('')
    const navigate = useNavigate()
    const setToast = useToast()
    const mutate = useMatchMutate()
    const [searchParams] = useSearchParams()
    const returnTo = searchParams.get('returnTo')
    const scrollField = searchParams.get('scrollField')
    const [item, setItem] = useState(null)
    const [mappedItem, setMappedItem] = useState(null)
    const [itemError, setItemError] = useState(null)
    const [itemLoading, setItemLoading] = useState(false)
    const [optionsState, setOptionsState] = useState({})

    // Only supports primitive fields and primitive arrays
    const [_history, setHistory] = useState([{ ...initData }])

    useEffect(function () {
        if (method !== 'PATCH' && !fetchItemFirst) return
        async function fetchItem() {
            setItemLoading(true)
            const { error, responseData } = await fetchAPI(
                getUrl || url,
                null,
                'GET',
            )
            setItemLoading(false)
            if (error) {
                setItemError(error)
                return
            }
            setItem(responseData)
            if (mapItemToData) {
                const newData = mapItemToData(responseData)
                setData({ ...newData })
                setMappedItem(newData)
                setHistory([{ ...newData }])
            }
        }
        fetchItem()
    }, [])

    const changedData = useMemo(() => {
        if (!mappedItem) return {}
        return getChangedData(
            mappedItem,
            beforeCompareChanges ? beforeCompareChanges(data) : data,
        )
    }, [mappedItem, data, beforeCompareChanges])

    const getErrors = useCallback(
        (key, type) => getErrorsForInput(key, type, errorFields),
        [errorFields],
    )

    function initializeAfterSave(allFields) {
        if (allFields) {
            setData({ ...initData })
            window.scrollTo(0, 0)
            return
        }
        const newData = { ...initData, ...data }

        if (getInitializeAfterSaveFields) {
            const newFields = getInitializeAfterSaveFields(data)
            for (const [k, v] of Object.entries(newFields)) {
                // TODO: other cases
                newData[k] = v
            }
        }

        setData(newData)
        window.scrollTo(0, 0)
    }

    async function onSubmit(e, resetAndStay) {
        e.preventDefault()
        setError('')

        let body = { ...data }
        if (getBody) {
            try {
                body = await getBody(
                    onlySaveChanges
                        ? getChangedData(
                              mappedItem,
                              beforeCompareChanges
                                  ? beforeCompareChanges(data)
                                  : data,
                          )
                        : data,
                    item,
                    mappedItem,
                )
            } catch (error) {
                console.error(error)
                setError(error.message || 'Unexpected error')
                return
            }
        }

        setLoading(true)
        const fetchUrl = getUrl ? populateUrlVariables(url, item) : url
        const { error, responseData, meta } = await fetchAPI(
            `${fetchUrl.split('?')[0]}${sendQuery ? `${sendQuery}` : ''}`,
            body,
            method,
            body instanceof FormData ? {} : undefined,
        )
        setLoading(false)

        if (error) {
            setError(error, meta?.fields, meta?.messages)
            return
        }

        submitToast !== '' && setToast(submitToast)
        if (getSubmitMessage && responseData) {
            const message = getSubmitMessage(responseData, data)
            if (message) {
                setMessage(message)
            }
        }

        if (returnTo && !resetAndStay) {
            navigate(returnTo)
        } else if (getSubmitNavArg && !resetAndStay) {
            navigate(getSubmitNavArg(responseData))
        } else if (submitNavArg && !resetAndStay) {
            navigate(submitNavArg)
        } else if (getInitializeAfterSaveFields || resetAndStay) {
            initializeAfterSave(resetAndStay)
        }
        for (const item of mutationRegexes) {
            mutate(item)
        }

        onSuccess && (await onSuccess(responseData || {}))
    }

    if (itemLoading) {
        return <Spinner />
    }

    if (itemError) {
        return <ErrorMessage>{itemError}</ErrorMessage>
    }

    if ((method === 'PATCH' || fetchItemFirst) && !item && !itemError) {
        return <Spinner />
    }

    if (!mappedItem && mapItemToData) {
        return <Spinner />
    }

    const inputComponents = []
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i]

        if (input.toggleGroup) {
            const prevInput = inputs?.[i - 1]
            if (prevInput?.toggleGroup === input.toggleGroup) {
                const prevComponent =
                    inputComponents[inputComponents.length - 1]
                prevComponent.push(input)
            } else {
                inputComponents.push([input])
            }
        } else {
            inputComponents.push(input)
        }
    }

    return (
        <div className="">
            {message && (
                <>
                    <MessageSection
                        type="success"
                        onDismiss={() => setMessage('')}
                    >
                        <div>{message}</div>
                    </MessageSection>
                    <Break />
                </>
            )}

            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <form
                onSubmit={onSubmit}
                className={`data-form${inline ? ' data-form-inline' : ''}`}
            >
                {inputComponents.map((item) =>
                    Array.isArray(item) ? (
                        <Toggle
                            title={item[0].toggleGroup}
                            key={item[0].toggleGroup}
                        >
                            {item.map((innerItem) => (
                                <InputItem
                                    errors={getErrors(
                                        innerItem.key,
                                        innerItem.type,
                                        errorFields,
                                    )}
                                    key={innerItem.key}
                                    input={innerItem}
                                    data={data}
                                    setData={setData}
                                    optionsState={optionsState}
                                    setOptionsState={setOptionsState}
                                    isScrollField={
                                        innerItem.key === scrollField
                                    }
                                    getOverride={getOverride}
                                />
                            ))}
                        </Toggle>
                    ) : (
                        <InputItem
                            errors={getErrors(item.key, item.type, errorFields)}
                            key={item.key}
                            input={item}
                            data={data}
                            setData={setData}
                            optionsState={optionsState}
                            setOptionsState={setOptionsState}
                            isScrollField={item.key === scrollField}
                            getOverride={getOverride}
                        />
                    ),
                )}

                <div>
                    {Boolean(saveAndNewButton) && (
                        <Button
                            outline
                            text="Save and create new"
                            onClick={(e) => onSubmit(e, true)}
                            type="button"
                            isLoading={loading}
                        />
                    )}
                    <Button
                        text={submitText}
                        type="submit"
                        isLoading={loading}
                        disabled={
                            loading ||
                            (onlySaveChanges &&
                                !Object.keys(changedData).length)
                        }
                        functionality="SAVE"
                    />
                </div>
                {Boolean(mainButton) && (
                    <MainButton
                        disabled={
                            loading ||
                            (onlySaveChanges &&
                                !Object.keys(changedData).length)
                        }
                        functionality="SAVE"
                        loading={loading}
                    />
                )}
            </form>
        </div>
    )
}
