import fetchAPI from '../../../../common/fetchAPI'
import useMatchMutate from '../../../../common/hooks/useMatchMutate'
import useModal from '../../../../common/hooks/useModal'
import useToast from '../../../../common/hooks/useToast'
import ConfirmModalContent from '../../../../components/common/ConfirmModalContent'

export default function useActions() {
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/tasks/${item.id}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    matchMutate(/^\/v1\/tasks/)
                }}
            />,
            'Are you sure you want to delete this task?',
        )
    }

    return {
        onDelete,
    }
}
