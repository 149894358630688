import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import { useUploads } from '../../../common/UploadContext'
import { API_URL } from '../../../common/constants'
import useToast from '../../../common/hooks/useToast'
import { useEffect, useState } from 'react'
import useAuth from '../../../common/hooks/useAuth'

function EditTaskAttachments() {
    const auth = useAuth()
    const { taskId } = useParams()
    const { progress, uploadFiles } = useUploads()
    const setToast = useToast()
    const [key, setKey] = useState(Date.now())

    useEffect(
        function () {
            if (progress === 0) {
                setKey(Date.now())
            }
        },
        [progress],
    )

    const inputs = [
        {
            shouldHide: (data) => !data.attachmentFileIdsUrls?.length,
            key: 'attachmentFileIdsUrls',
            type: 'removeFiles',
            label: 'Remove attachments',
            id: 'form-attachment-file-ids-urls',
        },
        {
            key: 'attachmentFiles',
            type: 'file',
            label: 'Upload attachments',
            id: 'form-attachment-browser',
            multiple: true,
            infoBottom: 'Add attachments from device.',
            small: true,
        },
    ]

    function mapItemToData(item) {
        const newData = {
            attachmentFileIdsUrls: [
                ...item.attachmentFileIds,
                ...item.attachmentFileUrls,
            ],
        }
        return newData
    }

    const getBody = async (data) => {
        const MAX_FILE_SIZE = 50 * 1024 * 1024

        for (const file of data.attachmentFiles || []) {
            if (file.size > MAX_FILE_SIZE && !auth.isAdmin) {
                throw new Error(`Max file size is 50MB.`)
            }
        }

        const finalUrls = data.attachmentFileIdsUrls.filter((idOrUrl) =>
            idOrUrl.includes('http'),
        )

        const body = {
            attachmentFileUrls: finalUrls,
        }

        const formData = new FormData()
        const bodyData = { ...body }

        formData.append('data', JSON.stringify(bodyData))

        if (data.attachmentFiles?.length) {
            const { error } = uploadFiles(data.attachmentFiles, {
                patchUrl: `${API_URL}/v1/tasks/${taskId}`,
                patchField: 'attachmentFileUrlsAdd',
            })
            if (error) {
                setToast(error, 'alert')
            }
        }

        return formData
    }

    return (
        <DataFormNew
            getSubmitMessage={(_, data) => {
                if (data.attachmentFiles?.length) {
                    return 'Files upload in progress, it may take a few minutes.'
                }
                // return ''
            }}
            key={key}
            url={`/v1/tasks/${taskId}`}
            method="PATCH"
            submitText="Save"
            submitToast=""
            submitNavArg={`/tasks/${taskId}`}
            inputs={inputs}
            getBody={getBody}
            mapItemToData={mapItemToData}
            mainButton
            mutationRegexes={[/\/v1\/tasks/]}
            hasHistory
            onSuccess={() => {
                setKey(Date.now())
            }}
        />
    )
}

export default EditTaskAttachments
