import './App.scss'
import { Outlet, useViewTransitionState } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useStore } from './common/Context'
import fetchAPI from './common/fetchAPI'
import Modal from './components/common/Modal'
import Toasts from './components/common/Toasts'
import { SWRConfig } from 'swr'
import useScrollAnchor from './common/hooks/useScrollAnchor'
import ScrollTopButton from './components/common/ScrollTopButton'
import ReactGA from 'react-ga4'
import Button from './components/common/Button'
import Spinner from './components/common/Spinner'
import DonationModal from './pages/admin/donations/DonationModal'

function App() {
    const [state, dispatch] = useStore()
    const { isTransitioning } = useViewTransitionState()

    useEffect(() => {
        async function getUserFromServer() {
            dispatch({ type: 'START_INITIAL_USER_LOAD' })

            const { responseData, status, error } = await fetchAPI(
                `/v1/users/me`,
                {},
                'GET',
            )

            if (error || status !== 200) {
                dispatch({
                    type: 'FINISH_INITIAL_USER_LOAD',
                    payload: null,
                })
                return
            }

            dispatch({
                type: 'FINISH_INITIAL_USER_LOAD',
                payload: responseData,
            })

            ReactGA.set({
                userId: responseData.id,
            })

            const storedTheme = localStorage.getItem('theme')
            if (storedTheme) {
                dispatch({ type: 'SET_THEME', payload: storedTheme })
            }
        }

        getUserFromServer()
    }, [])

    useEffect(() => {
        async function getMenus() {
            const { responseData, error, status } = await fetchAPI(
                `/v1/settings/menuTiles`,
                {},
                'GET',
            )

            if (error && status !== 404) {
                console.error(error)
                return
            }

            if (status === 404) {
                return
            }

            dispatch({
                type: 'SET_MENU_TILES',
                payload: JSON.parse(responseData?.settingValue || '[]'),
            })
        }

        getMenus()
    }, [])

    const swrGlobalOptions = {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    }

    useScrollAnchor()

    function handleDevSession() {
        const v =
            localStorage.getItem('use_session_auth') === 'true'
                ? 'false'
                : 'true'
        localStorage.setItem('use_session_auth', v)
    }

    if (state.initLoading) {
        return <Spinner marginTop />
    }

    const usingSessionStorage =
        localStorage.getItem('use_session_auth') === 'true'

    return (
        <SWRConfig value={swrGlobalOptions}>
            <div
                className={`App${state.isModalOpen ? ' modal-open' : ''} theme-${state.theme}${isTransitioning ? ' transitioning' : ''}`}
            >
                {process.env.NODE_ENV === 'development' && (
                    <>
                        <div style={{ display: 'none' }}>
                            <Button
                                onClick={handleDevSession}
                                text={`SESSION STORAGE (${
                                    usingSessionStorage ? 'USING' : 'NOT USING'
                                })`}
                                tiny
                                outline
                            />
                        </div>
                        {/* <style jsx="true">{`
                            .adsbygoogle {
                                display: none !important;
                            }
                        `}</style> */}
                    </>
                )}
                <Outlet />
                <Modal />
                <Toasts />
                <ScrollTopButton />
                <DonationModal />
            </div>
        </SWRConfig>
    )
}

export default App
