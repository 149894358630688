import { useEffect, useState } from 'react'
import useAuth from '../../common/hooks/useAuth'

export default function Ad() {
    const auth = useAuth()
    const [isSet, setIsSet] = useState(false)

    useEffect(
        function () {
            if (!auth?.seesAds) {
                return
            }
            if (isSet) return
            try {
                ;(window.adsbygoogle = window.adsbygoogle || []).push({})
            } catch (error) {
                console.error(error)
            }
            setIsSet(true)
        },
        [auth],
    )

    if (!auth?.seesAds) {
        return null
    }

    return (
        <>
            <ins
                className="adsbygoogle ad"
                style={{
                    minHeight: 60,
                    height: 60,
                    width: '100%',
                    display: 'block',
                }}
                data-ad-client="ca-pub-1004776563063175"
                data-ad-slot="8690619677"
                // data-full-width-responsive="true"
                // data-ad-format="auto"
            ></ins>
        </>
    )
}
