import { useState } from 'react'
import { useStore } from '../../common/Context'
import Button from './Button'
import TextInput from './data-form/TextInput'
import LiveSearchAddItemsInput from './data-form/LiveSearchAddItemsInput'
import Break from './Break'
import useError from '../../common/hooks/useError'
import ErrorMessage from './ErrorMessage'
import LiveSearchInput from './data-form/LiveSearchInput'

function InputModalContent({
    onCancel,
    onConfirm,
    // label, // TODO
    type,
    confirmText,
    defaultValue,
    topHtml,

    items,
    url,
    getItemText,
    fields,
    getItemsFromResults,
    canAddNewItem = false,
}) {
    const [state, dispatch] = useStore()
    const [data, setData] = useState({
        text: defaultValue || '',
        items: [],
        item: null,
    })
    const [error, setError, fieldErrors] = useError('')

    const handleCancel = () => {
        dispatch({ type: 'CLOSE_MODAL' })
        onCancel && onCancel()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onConfirm(data, setError)
    }

    let inputHtml
    if (type === 'liveSearchAddItems') {
        inputHtml = (
            <LiveSearchAddItemsInput
                errors={fieldErrors}
                defaultValue={defaultValue}
                loadMoreButton
                canAddNewItem={canAddNewItem}
                value={data.items}
                onChange={(v) => setData({ ...data, items: v })}
                items={items}
                url={url}
                getItemText={(item) => (getItemText ? getItemText(item) : item)}
                getItemsFromResults={getItemsFromResults}
                fields={
                    fields || [
                        {
                            column: 1,
                            getValue: (item) => item,
                        },
                    ]
                }
            />
        )
    } else if (type === 'liveSearch') {
        inputHtml = (
            <LiveSearchInput
                /*
                      hasStrValueOnNull: true,
            */
                errors={fieldErrors}
                url={url}
                value={data.item?.id || null}
                onChange={(id, item) => setData({ ...data, item })}
                // defaultValue={defaultValue}
                showAll={false}
                getItemsFromResults={getItemsFromResults}
                getResultValue={getItemText}
            />
        )
    } else {
        inputHtml = (
            <TextInput
                errors={fieldErrors}
                value={data.text || ''}
                onChange={(v) => setData({ ...data, text: v })}
                type={type || 'text'}
                autoComplete="off"
                id="modal-input"
            />
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    {topHtml && (
                        <>
                            {topHtml}
                            <Break />
                        </>
                    )}
                    {!!error && (
                        <ErrorMessage onDismiss={() => setError('')}>
                            {error}
                        </ErrorMessage>
                    )}
                    {inputHtml}
                </div>
                <div className="modal-buttons">
                    <Button
                        small
                        destructive
                        link
                        onClick={handleCancel}
                        text={'Cancel'}
                        disabled={state.isModalLoading}
                    />
                    <Button
                        small
                        type="submit"
                        isLoading={state.isModalLoading}
                        text={confirmText || 'Submit'}
                    />
                </div>
            </form>
        </>
    )
}

export default InputModalContent
