import { useViewTransitionState } from 'react-router-dom'
import { useStore } from '../../common/Context'
import useAuth from '../../common/hooks/useAuth'
import useData from '../../common/hooks/useData'
import Button from '../../components/common/Button'
import ButtonGroup from '../../components/common/ButtonGroup'
import SectionContainer from '../../components/common/SectionContainer'
import Toggle from '../../components/common/Toggle'

export default function Menu() {
    const auth = useAuth()
    const [state] = useStore()

    const pagesUrl = auth.isClient
        ? '/v1/pages?showOnClientMenu=true&limit=200&page=1'
        : '/v1/pages?showOnTeamMenu=true&limit=200&page=1'

    const { pages } = useData(pagesUrl, 'pages', (data) => data?.results || [])

    return (
        <aside className="menu">
            <SectionContainer>
                <ButtonGroup grid>
                    {state.menuTiles
                        .filter((t) => {
                            if (auth.isAdmin) return true
                            if (
                                auth.isAssistant &&
                                t.showToAssistants === 'true'
                            ) {
                                return true
                            }
                            if (auth.isStaff && t.showToStaff === 'true') {
                                return true
                            }
                            if (auth.isClient && t.showToClients === 'true') {
                                return true
                            }
                            return false
                        })
                        .map((t, i) => (
                            <Button
                                key={i}
                                outline
                                card
                                href={t.href}
                                text={t.label}
                                icon={t.icon || undefined}
                            />
                        ))}
                </ButtonGroup>
            </SectionContainer>
            {pages?.length ? (
                <SectionContainer>
                    <Toggle title="Pages" open>
                        <ButtonGroup grid>
                            {pages.map((page) => (
                                <Button
                                    key={page.id}
                                    href={`/pages/${page.slug}`}
                                    outline
                                    card
                                    text={page.title}
                                />
                            ))}
                        </ButtonGroup>
                    </Toggle>
                </SectionContainer>
            ) : null}
        </aside>
    )
}
