import { TASK_STATUS_LABELS } from '../../../../common/constants'

export default function getFilters({
    searchOnly,
    statusTabs,
    targets,
    paidOnly,
}) {
    const statusFilters = {
        statusOpen: {
            label: TASK_STATUS_LABELS.open.label,
            dbField: 'statusOpen',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusAccepted: {
            label: TASK_STATUS_LABELS.accepted.label,
            dbField: 'statusAccepted',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusReview: {
            label: TASK_STATUS_LABELS.review.label,
            dbField: 'statusReview',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusComplete: {
            label: TASK_STATUS_LABELS.complete.label,
            dbField: 'statusComplete',
            default: 'false',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusRejected: {
            label: TASK_STATUS_LABELS.rejected.label,
            dbField: 'statusRejected',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusDenied: {
            label: TASK_STATUS_LABELS.denied.label,
            dbField: 'statusDenied',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusApproval: {
            label: TASK_STATUS_LABELS.approval.label,
            dbField: 'statusApproval',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        statusRecreation: {
            label: TASK_STATUS_LABELS.recreation.label,
            dbField: 'statusRecreation',
            options: [
                {
                    label: '',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    }

    const filters = searchOnly
        ? {
              search: true,
          }
        : {
              search: true,
              sort: {
                  defaultOrder: {
                      label: 'Default',
                      dbFields: ['default'],
                      asc: true,
                  },
                  creationDate: {
                      label: 'Creation date',
                      dbFields: ['createdAt'],
                  },
                  title: {
                      label: 'Title',
                      dbFields: ['title'],
                      asc: true,
                  },
                  priority: {
                      label: 'Priority',
                      dbFields: ['priority'],
                      asc: true,
                  },
                  order: {
                      label: 'Global order',
                      dbFields: ['order'],
                      asc: true,
                  },
              },
              ...(statusTabs?.length
                  ? {
                        filterCombos: {
                            allTab: {
                                label: 'All',
                                fields: {
                                    statusComplete: 'false',
                                },
                            },
                            ...statusTabs.reduce(
                                (a, b) => ({
                                    ...a,
                                    [`${b}Tab`]: {
                                        label: TASK_STATUS_LABELS[b].label,
                                        fields: {
                                            [`status${
                                                b.charAt(0).toUpperCase() +
                                                b.slice(1)
                                            }`]: 'true',
                                            statusComplete: 'false',
                                        },
                                    },
                                }),
                                {},
                            ),

                            ...(targets.includes('client')
                                ? {}
                                : {
                                      clientReview: {
                                          label: 'Client review',
                                          fields: {
                                              clientReviewStatus: 'submitted',
                                              statusComplete: 'false',
                                          },
                                      },
                                  }),
                        },
                    }
                  : {}),

              filters: {
                  ...statusFilters,
                  priority: {
                      label: 'Priority',
                      dbField: 'priority',
                      options: [
                          {
                              label: 'Any',
                              value: 'undefined',
                          },
                          {
                              label: 'High',
                              value: 'high',
                          },
                          {
                              label: 'Medium',
                              value: 'medium',
                          },
                          {
                              label: 'Low',
                              value: 'low',
                          },
                      ],
                  },
                  //   attachmentFiles: {
                  //       label: 'Has attachments',
                  //       dbField: 'attachmentFiles',
                  //       options: [
                  //           {
                  //               label: 'Any',
                  //               value: 'undefined',
                  //           },
                  //           {
                  //               label: 'Yes',
                  //               value: 'true',
                  //           },
                  //           {
                  //               label: 'No',
                  //               value: 'false',
                  //           },
                  //       ],
                  //   },
                  roleWithStaffAssigned: {
                      label: 'Team task assigned to a team member',
                      dbField: 'roleWithStaffAssigned',
                      options: [
                          {
                              label: 'Any',
                              value: 'undefined',
                          },
                          {
                              label: 'Yes',
                              value: 'true',
                          },
                          {
                              label: 'No',
                              value: 'false',
                          },
                      ],
                  },

                  ...(targets.includes('client')
                      ? {}
                      : {
                            clientReviewStatus: {
                                label: 'Client review status',
                                dbField: 'clientReviewStatus',
                                options: [
                                    {
                                        label: 'Any',
                                        value: 'undefined',
                                    },
                                    {
                                        label: 'Submitted',
                                        value: 'submitted',
                                    },
                                ],
                            },
                        }),

                  ...(paidOnly
                      ? {
                            isForPaidUser: {
                                label: 'For paid user',
                                dbField: 'isForPaidUser',
                                default: 'true',
                                options: [
                                    {
                                        label: 'Any',
                                        value: 'undefined',
                                    },
                                    {
                                        label: 'Yes',
                                        value: 'true',
                                    },
                                ],
                            },
                        }
                      : {}),
              },
          }

    return filters
}
