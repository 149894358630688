import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import { DEFAULT_TEAM_ROLES, ICONS } from '../../../common/constants'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../../common/hooks/useAuth'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import React from 'react'
import useError from '../../../common/hooks/useError'

const userCategories = ['free', 'premium']

export default function PermissionSection({ resource, id }) {
    const navigate = useNavigate()
    const auth = useAuth()
    const [itemLoading, setItemLoading] = useState(null)
    const setToast = useToast()
    const [error, setError, errorFields] = useError()

    const { teamRolesLoading, teamRolesError, teamRoles } = useData(
        '/v1/settings/teamRoles',
        'teamRoles',
        (data) => data?.settingValue || '',
    )

    const { data, dataLoading, dataError } = useData(
        `/v1/settings/permissions/resources/${resource}/scopes`,
        'data',
    )

    const {
        permissions,
        permissionsLoading,
        permissionsError,
        permissionsMutate,
    } = useData(
        `/v1/settings/permissions/resources/${resource}/${id}`,
        'permissions',
    )

    async function handleChange(checked, scope, target, targetType) {
        setItemLoading({ scope, target })
        const { error } = await fetchAPI(
            `/v1/settings/permissions/resources/${resource}/${id}`,
            {
                scope,
                target,
                value: checked,
                targetType,
            },
            'PATCH',
        )
        setItemLoading(null)
        permissionsMutate()
        if (error) {
            setError(error, [`${scope}_${target}`])
            return
        }
        setToast('Saved')
    }

    if (!resource || !id) {
        return null
    }

    if (dataLoading || permissionsLoading || teamRolesLoading) {
        return <Spinner />
    }

    if (dataError || permissionsError || teamRolesError) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {dataError || permissionsError || teamRolesError}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    const teams = [
        'admin',
        'all staff',
        ...DEFAULT_TEAM_ROLES,
        ...teamRoles.split('\n'),
    ]

    const permissionsMap = {}
    for (const item of permissions) {
        if (!permissionsMap[item.scope]) {
            permissionsMap[item.scope] = {}
        }
        if ([...teams, ...userCategories].includes(item.target)) {
            permissionsMap[item.scope][item.target] = true
        } else {
            if (!permissionsMap[item.scope].userCount) {
                permissionsMap[item.scope].userCount = 0
            }
            permissionsMap[item.scope].userCount += 1
        }
    }

    return (
        <SectionContainer>
            {!!error && (
                <SectionContainer>
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                </SectionContainer>
            )}

            <div className="permissions-section">
                <h3>
                    {data.title} / {id}
                </h3>
                <div>{data.description}</div>
                <div>
                    {(auth.isAdmin || auth.isAssistant) && (
                        <table cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Staff</th>
                                    {teams.map((t) => (
                                        <th className="" key={t}>
                                            {t}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data.permissions).map(
                                    ([scope, value]) => {
                                        return (
                                            <tr key={scope}>
                                                <td>{value.label}</td>
                                                {teams.map((target) => (
                                                    <td
                                                        className=""
                                                        key={target}
                                                    >
                                                        <div>
                                                            <CheckboxInput
                                                                label="permission"
                                                                hideLabel
                                                                disabled={
                                                                    itemLoading
                                                                }
                                                                value={Boolean(
                                                                    permissionsMap[
                                                                        scope
                                                                    ]?.[target],
                                                                )}
                                                                onChange={(v) =>
                                                                    handleChange(
                                                                        v,
                                                                        scope,
                                                                        target,
                                                                        'team',
                                                                    )
                                                                }
                                                                errors={errorFields.filter(
                                                                    (f) =>
                                                                        f ===
                                                                        `${scope}_${target}`,
                                                                )}
                                                            />
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        )
                                    },
                                )}
                            </tbody>
                        </table>
                    )}
                    {!auth.isClient && (
                        <table cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Clients</th>
                                    {userCategories.map((t) => (
                                        <th className="" key={t}>
                                            {t}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data.permissions).map(
                                    ([scope, value]) => {
                                        return (
                                            <tr key={scope}>
                                                <td>{value.label}</td>
                                                {userCategories.map(
                                                    (target) => (
                                                        <td
                                                            className=""
                                                            key={target}
                                                        >
                                                            <div>
                                                                <CheckboxInput
                                                                    label="permission"
                                                                    hideLabel
                                                                    disabled={
                                                                        itemLoading
                                                                    }
                                                                    value={Boolean(
                                                                        permissionsMap[
                                                                            scope
                                                                        ]?.[
                                                                            target
                                                                        ],
                                                                    )}
                                                                    onChange={(
                                                                        v,
                                                                    ) =>
                                                                        handleChange(
                                                                            v,
                                                                            scope,
                                                                            target,
                                                                            'client',
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    ),
                                                )}
                                            </tr>
                                        )
                                    },
                                )}
                            </tbody>
                        </table>
                    )}
                    {!auth.isClient && (
                        <table cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Individual</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data.permissions).map(
                                    ([scope, value]) => {
                                        return (
                                            <tr key={scope}>
                                                <td>{value.label}</td>
                                                <td className="">
                                                    <div>
                                                        <button
                                                            onClick={() =>
                                                                navigate(
                                                                    `/settings/permissions/${resource}/${id}/${scope}/user`,
                                                                )
                                                            }
                                                            title="user specific"
                                                        >
                                                            <img
                                                                src={
                                                                    ICONS.USER_ACTIVE
                                                                }
                                                                alt=""
                                                            />
                                                            {permissionsMap[
                                                                scope
                                                            ]?.userCount || 0}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    },
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
                {auth.isAdmin && (
                    <p className="text-subdued">
                        Permissions are shown only to the user that assigned
                        them. Admins see all permissions assigned by all users.
                    </p>
                )}
            </div>
        </SectionContainer>
    )
}
