import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setSearchParams } from '../helpers'

function useFilter(opts = {}) {
    const {
        filterCombos = {},
        filters = {},
        sort = {},
        search = false,
        urlKey,
    } = opts

    const debounceTimeoutRef = useRef(undefined)
    const [searchParams] = useSearchParams()
    const [initializedParams, setInitializedParams] = useState(false)

    let initFilter = {}

    if (Object.keys(filterCombos).length) {
        initFilter = Object.values(filterCombos)[0].fields
    } else {
        for (const [key, value] of Object.entries(filters)) {
            if (!value.options) {
                // TODO: support .default
                continue
            }
            if (value.default === undefined) {
                initFilter[key] = value.options[0].value
                continue
            }
            if (Array.isArray(value.default)) {
                initFilter[key] = value.options.find(
                    (option) =>
                        option.label.toLowerCase() ===
                        value.default[1].toLowerCase(),
                ).value
                continue
            }
            initFilter[key] = value.default
        }
    }

    // const initFilter = Object.keys(filterCombos).length
    // ? Object.values(filterCombos)[0].fields
    // : Object.keys(filters).reduce(
    //       (a, b) => ({
    //           ...a,
    //           [b]:
    //               typeof filters[b].default === 'undefined'
    //                   ? filters[b].options[0].value
    //                   : Array.isArray(filters[b].default)
    //                   ? filters[b].options.find(
    //                         (option) =>
    //                             option.label?.toLowerCase() ===
    //                             filters[b].default[1]?.toLowerCase(),
    //                     )?.value
    //                   : filters[b].default,
    //       }),
    //       {},
    //   )

    const initSort = {
        key: Object.keys(sort)?.[0],
        isAsc: Boolean(Object.values(sort)?.[0]?.asc),
    }

    let initQ = ''

    const hasQueryFilters = urlKey && searchParams.get('f_url_key') === urlKey

    if (hasQueryFilters) {
        for (const [key, value] of searchParams.entries()) {
            if (key.startsWith('f_')) {
                if (key === 'f_sortKey') {
                    initSort.key = value
                } else if (key === 'f_sortIsAsc') {
                    initSort.isAsc = value === 'true'
                } else if (key === 'f_q') {
                    initQ = value
                } else {
                    initFilter[key.replace('f_', '')] = value
                }
            }
        }
    }

    const [currFilter, setCurrFilter] = useState(initFilter)
    const [currSort, setCurrSort] = useState(initSort)
    const [currQueryStr, setCurrQueryStr] = useState(initQ)

    useEffect(
        function () {
            if (!urlKey) {
                return
            }
            if (!initializedParams) {
                setInitializedParams(true)
                return
            }
            const newQueryParams = {}

            if (currSort.key) {
                newQueryParams.f_sortKey = currSort.key
                newQueryParams.f_sortIsAsc = currSort.isAsc
            }
            if (filterQueryUrlObj.q) {
                newQueryParams.f_q = filterQueryUrlObj.q
            }
            for (const [key, value] of Object.entries(currFilter)) {
                if (value === 'undefined' || typeof value === 'undefined') {
                    continue
                }
                newQueryParams[`f_${key}`] = value
            }

            const searchParams = new URLSearchParams(window.location.search)
            for (const [key, value] of searchParams.entries()) {
                if (key.startsWith('f_')) continue
                newQueryParams[key] = value
            }

            if (Object.keys(newQueryParams).some((k) => k.startsWith('f_'))) {
                setSearchParams({ ...newQueryParams, f_url_key: urlKey })
            }
        },
        [currFilter, currSort, currQueryStr],
    )

    useEffect(() => {
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
            }
        }
    }, [])

    if (!Object.keys(filters).length && !Object.keys(sort).length && !search) {
        return {}
    }

    function onSortKeyChange(key) {
        setCurrSort({ key, isAsc: Boolean(sort[key]?.asc) })
    }

    function onAscChange(isAsc) {
        setCurrSort((currSort) => ({ ...currSort, isAsc }))
    }

    function onFilterChange(data, clearPrev) {
        setCurrFilter(
            clearPrev
                ? { ...data }
                : (currFilter) => ({ ...currFilter, ...data }),
        )
        for (const [key, value] of Object.entries(data)) {
            if (filters[key].onChange) {
                filters[key].onChange(
                    value,
                    filters[key].options.find(
                        (option) => option.value === value,
                    ).label,
                )
            }
        }
    }

    function debounceSetQuery(query) {
        clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(function () {
            setCurrQueryStr(query || '')
        }, 500)
    }

    const filterSetters = {
        setSortKey: onSortKeyChange,
        setIsAsc: onAscChange,
        setFilter: onFilterChange,
        setQueryStr: debounceSetQuery,
    }

    const filterQueryUrlObj = {}

    if (currSort.key && sort[currSort.key]) {
        filterQueryUrlObj.sortBy = sort[currSort.key].dbFields
            .map((field) => `${field}:${currSort.isAsc ? 'asc' : 'desc'}`)
            .join(',')
    }

    for (const key of Object.keys(currFilter)) {
        if (!filters[key]) continue
        let value = currFilter[key]
        if (value === 'undefined' || typeof value === 'undefined') continue
        filterQueryUrlObj[filters[key].dbField] = currFilter[key]
    }

    if (search && currQueryStr) {
        filterQueryUrlObj.q = currQueryStr.toLowerCase()
    }

    const currTabFilterKey = Object.entries(filters).find(
        ([_k, v]) => v.tabs,
    )?.[0]

    const hasActiveFilter = Object.entries(currFilter).some(([k, v]) => {
        if (filters[k]?.tabs) return false
        return String(v) !== String(initFilter[v])
    })

    return {
        filterQueryUrlObj,
        currFilter,
        currSort,
        currQueryStr,
        currTabFilterKey,
        filterSetters,
        hasActiveFilter,
    }
}

export default useFilter
