import useToast from '../../../common/hooks/useToast'
import { useState } from 'react'
import useData from '../../../common/hooks/useData'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import TreeInput from '../../../components/common/data-form/TreeInput'
import MainButton from '../../../components/admin/MainButton'
import Button from '../../../components/common/Button'
import useError from '../../../common/hooks/useError'
import { routesHandleMap } from '../../../routes'

const DEFAULT_SIDEBAR_ITEMS = [
    {
        title: 'Home',
        url: '#home',
        items: [],
    },
    // Add more default items if needed
]

function mergeData(settingItems) {
    const result = [...settingItems]

    for (const item of DEFAULT_SIDEBAR_ITEMS) {
        const itemInData = settingItems.find((i) => i.url === item.url)
        if (!itemInData) {
            result.push(item)
        } else {
            itemInData.isDefault = true
        }
    }

    return result
}

export default function SidebarItems() {
    const [data, setData] = useState(DEFAULT_SIDEBAR_ITEMS)
    const [saveLoading, setSaveLoading] = useState(false)
    const setToast = useToast()
    const [error, setError, fieldErrors] = useError(null)

    const { itemsError, itemsLoading, itemsMutate, itemsStatus } = useData(
        `/v1/settings/sidebarItems`,
        'items',
        (data) => JSON.parse(data?.settingValue || '[]'),
        null,
        null,
        (data) => setData(mergeData(JSON.parse(data?.settingValue || '[]'))),
    )

    async function onSubmit() {
        setSaveLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/settings/sidebarItems`,
            { settingValue: JSON.stringify(data) },
            'PATCH',
        )
        setSaveLoading(false)

        if (error) {
            setError(error)
            return
        }

        itemsMutate(responseData)
        setToast('Saved')
    }

    if (itemsLoading) {
        return <Spinner />
    }

    if (itemsError && itemsStatus !== 404) {
        return (
            <SectionContainer>
                <ErrorMessage>{itemsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {!!error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}
            <Break />
            <Break />
            <Break />
            <TreeInput
                titlessLevels
                additionalFields={[
                    {
                        key: 'routeHandle',
                        label: 'Route handle',
                        type: 'liveSearch',
                        items: Array.from(routesHandleMap.keys()),
                        getResultValue: (item) => item,
                        searchInExistingOnly: true,
                    },
                    {
                        key: 'url',
                        label: 'URL',
                        type: 'text',
                    },
                ]}
                errors={fieldErrors}
                onChange={(d) => {
                    setData(d)
                }}
                value={data}
            />
            <Break />
            <Break />
            <Break />
            <Button text="Save" onClick={onSubmit} isLoading={saveLoading} />

            <MainButton
                onClick={onSubmit}
                functionality="SAVE"
                loading={saveLoading}
            />
        </SectionContainer>
    )
}
