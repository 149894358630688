import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
} from 'react-router-dom'
import routes from './routes'
import RequireAuth from './common/RequireAuth'
import MainHeader from './components/common/MainHeader'
import Ad from './components/ads/Ad'
import Terms from './components/common/Terms'
import MainFooter from './components/common/MainFooter'
import useAuth from './common/hooks/useAuth'
import NotFound from './pages/not-found/NotFound'
import App from './App'

function AdminLayout() {
    return (
        <>
            <Ad />
            <main className="admin-layout">
                <Outlet />
            </main>
            <Terms>
                <Ad />
            </Terms>

            <MainFooter />
        </>
    )
}

function AdminMenuLayout() {
    return (
        <>
            <Ad />
            <main className="admin-layout admin-layout-menu">
                <Outlet />
            </main>

            <MainFooter />
        </>
    )
}

function PublicLayout() {
    return (
        <>
            <main className="public-layout">
                <Outlet />
            </main>
            <Terms />
        </>
    )
}

function MixedLayout() {
    const auth = useAuth()
    return auth.user ? <AdminLayout /> : <PublicLayout />
}

function RegistrationLayout() {
    return (
        <>
            <main className="registration-layout">
                <Outlet />
            </main>
            <Terms />
        </>
    )
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route element={<PublicLayout />}>
                {routes
                    .filter((item) => item.public && !item.registrationFlow)
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={item.component}
                        />
                    ))}
            </Route>
            <Route element={<MixedLayout />}>
                {routes
                    .filter((item) => item.mixedAuth)
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={
                                <>
                                    <MainHeader
                                        title={item.title}
                                        backUrl={item.backUrl}
                                        getBreadcrumbs={item.getBreadcrumbs}
                                        getTitle={item.getTitle}
                                    />
                                    <div className={item.className}>
                                        {item.component}
                                    </div>
                                </>
                            }
                        />
                    ))}
            </Route>

            <Route element={<RegistrationLayout />}>
                {routes
                    .filter((item) => item.public && item.registrationFlow)
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={item.component}
                        />
                    ))}
            </Route>
            <Route element={<RegistrationLayout />}>
                {routes
                    .filter((item) => item.registrationFlow)
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={
                                <RequireAuth routeItem={item}>
                                    <div className={item.className}>
                                        {item.component}
                                    </div>
                                </RequireAuth>
                            }
                        />
                    ))}
            </Route>
            <Route element={<AdminLayout />}>
                {routes
                    .filter(
                        (item) =>
                            !item.public &&
                            !item.registrationFlow &&
                            !item.url.startsWith('more'),
                    )
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={
                                <RequireAuth routeItem={item}>
                                    <MainHeader
                                        title={item.title}
                                        backUrl={item.backUrl}
                                        getBreadcrumbs={item.getBreadcrumbs}
                                        getTitle={item.getTitle}
                                    />
                                    <div className={item.className}>
                                        {item.component}
                                    </div>
                                </RequireAuth>
                            }
                        />
                    ))}
            </Route>
            <Route element={<AdminMenuLayout />}>
                {routes
                    .filter((item) => {
                        return item.url.startsWith('more')
                    })
                    .map((item) => (
                        <Route
                            key={item.url}
                            path={item.url}
                            element={
                                <RequireAuth routeItem={item}>
                                    <MainHeader
                                        title={item.title}
                                        backUrl={item.backUrl}
                                        getBreadcrumbs={item.getBreadcrumbs}
                                        getTitle={item.getTitle}
                                    />
                                    <div className={item.className}>
                                        {item.component}
                                    </div>
                                </RequireAuth>
                            }
                        />
                    ))}
            </Route>

            <Route path="*" element={<NotFound />} />
        </Route>,
    ),
)

export default router
