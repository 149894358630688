import { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../Button'
import { getImageDimensions, sanitizeHTML } from '../../../common/helpers'
import TextInput from './TextInput'
import InlineStack from '../InlineStack'
import Popover from '../Popover'
import { ICONS } from '../../../common/constants'
import CheckboxInput from './CheckboxInput'
import useFilePicker from '../FilePicker'
import useModal from '../../../common/hooks/useModal'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'

function rgbToHex(rgb) {
    const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(rgb)
    return result
        ? `#${parseInt(result[1], 10).toString(16).padStart(2, '0')}${parseInt(result[2], 10).toString(16).padStart(2, '0')}${parseInt(result[3], 10).toString(16).padStart(2, '0')}`
        : 'transparent'
}

export default function HtmlInput({
    value,
    onChange,
    defaultValue,
    id,
    onEditorChange,
    setOptionsState,
    infoContent,
    errors,
    ...rest
}) {
    const [initValue, setInitValue] = useState(value)
    const editableRef = useRef(null)
    const controlsRef = useRef(null)
    const formatRef = useRef(null)
    const alignRef = useRef(null)
    const linkRef = useRef(null)
    const [showCode, setShowCode] = useState(false)
    const [linkUrl, setLinkUrl] = useState('')
    const [openInNewTab, setOpenInNewTab] = useState(false)
    const [color, setColor] = useState('#000000')
    const [bgColor, setBgColor] = useState('transparent')
    const { pickerHtml, openPicker } = useFilePicker()
    const { setModal } = useModal()
    const setToast = useToast()
    const [savedSelection, setSavedSelection] = useState(null)

    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || '')
        }
    }, [])

    useEffect(function () {
        function scrollHandler() {
            const controlsHeight = controlsRef.current.offsetHeight
            const headerHeight = 55
            const editable = editableRef.current

            // if ()

            const rect = editable.getBoundingClientRect()

            const delta = controlsHeight + headerHeight - rect.top
            if (delta > 0) {
                console.log('higher')
            }

            const scrollTop = window.scrollY - rect.top - 160
            // By half because it's moving in two directions
            const paddingTop = Math.max(0, delta)

            const TOTAL_PADDING = 17
            if (paddingTop > editable.offsetHeight - TOTAL_PADDING) {
                // Stop increasing the padding here
                return
            }

            editable.style.paddingTop = `${paddingTop}px`
        }

        window.addEventListener('scroll', scrollHandler)

        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    // useEffect(
    //     function () {
    //         if (initValue === value) {
    //             return
    //         }

    //         if (value === '') {
    //             setInitValue('')
    //             setInitialized(false)
    //             return
    //         }

    //         if (initialized) {
    //             return
    //         }
    //         setInitialized(true)

    //         async function update() {
    //             if (!value) return
    //             setInitValue(value)
    //         }

    //         update()
    //     },
    //     [value],
    // )
    useEffect(function () {
        setInitValue(value)
    }, [])

    useEffect(function () {
        function handleTabKey(e) {
            if (e.key !== 'Tab') return
            const selection = window.getSelection()
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0)
                const container = range.startContainer.parentNode

                if (!container.closest('ol') && !container.closest('ul')) {
                    return
                }

                e.preventDefault()

                let nestingLevel = 0
                let currentElement = container
                while (currentElement) {
                    if (
                        currentElement.tagName === 'OL' ||
                        currentElement.tagName === 'UL'
                    ) {
                        nestingLevel++
                    }
                    currentElement = currentElement.parentNode
                }

                if (!e.shiftKey && nestingLevel < 3) {
                    document.execCommand('indent')
                } else if (e.shiftKey && nestingLevel > 1) {
                    document.execCommand('outdent')
                }
            }
        }

        const editable = editableRef.current
        editable.addEventListener('keydown', handleTabKey)

        return () => {
            editable.removeEventListener('keydown', handleTabKey)
        }
    }, [])

    async function handleFilesDrop(file) {
        saveSelection()
        if (!file?.type.startsWith('image')) {
            return
        }
        const fileDimensions = await getImageDimensions(file)

        const loadingDiv = document.createElement('div')
        loadingDiv.className = 'img-loading'
        const ratio = fileDimensions?.width / fileDimensions?.height
        loadingDiv.style.aspectRatio = ratio
        loadingDiv.style.width = `${fileDimensions?.width}px`

        const selection = window.getSelection()
        let range
        if (
            selection?.rangeCount > 0 &&
            editableRef.current.contains(selection.anchorNode)
        ) {
            range = selection.getRangeAt(0)
        } else {
            range = document.createRange()
            range.setStart(editableRef.current, 0)
            range.collapse(true)
        }

        range.insertNode(loadingDiv)
        range.setStartAfter(loadingDiv)
        range.collapse(true)
        selection.removeAllRanges()
        selection.addRange(range)

        const { responseData: urlResponseData, error: urlError } =
            await fetchAPI(
                `/v1/files/list/me`,
                {
                    files: [
                        {
                            fileName: file.name,
                            width: fileDimensions?.width,
                            height: fileDimensions?.height,
                            mimeType: file.type,
                            size: file.size,
                        },
                    ],
                },
                'POST',
            )

        if (urlError) {
            setToast(urlError, 'alert')
            loadingDiv.remove()
            return
        }

        let finalUrl

        try {
            const dimensions = fileDimensions
            const { uploadUrl, dbId } = urlResponseData[0]
            const uploadResponse = await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                    'x-goog-meta-filename': file.name,
                    ...(dimensions
                        ? {
                              'x-goog-meta-width': dimensions.width,
                              'x-goog-meta-height': dimensions.height,
                          }
                        : {}),
                },
                body: file,
            })
            if (!uploadResponse?.ok) {
                loadingDiv.remove()
                throw new Error('File upload failed')
            }
            const { responseData, error } = await fetchAPI(
                `/v1/files/list/me/${dbId}/make-public`,
                {},
                'POST',
            )

            if (error) {
                loadingDiv.remove()
                throw new Error('File make public failed')
            }
            finalUrl = responseData.publicUrl
        } catch (error) {
            console.error(error)
            loadingDiv.remove()
            setToast(`File upload failed for ${file.name}`, 'alert')
        }

        if (!finalUrl) {
            loadingDiv.remove()
            return
        }

        const img = document.createElement('img')
        img.src = finalUrl
        img.width = fileDimensions?.width
        img.height = fileDimensions?.height
        loadingDiv.replaceWith(img)

        handleCodeChange(editableRef.current.innerHTML)
    }

    function handleDrop(e) {
        e.preventDefault()
        const files = Array.from(e.dataTransfer.files)
        const imageFiles = files.filter((file) =>
            file.type.startsWith('image/'),
        )
        if (imageFiles.length === 1) {
            handleFilesDrop(imageFiles[0])
        }
    }

    function handleDragOver(e) {
        e.preventDefault()
    }

    function openInfo() {
        setModal(infoContent)
    }

    function saveSelection() {
        const selection = window.getSelection()
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0)
            setSavedSelection(range)
        }
    }

    function restoreSelection() {
        if (!savedSelection) return
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(savedSelection)
    }

    const handleCodeBlur = useCallback((e) =>
        onChange(sanitizeHTML(e.currentTarget.innerHTML)),
    )

    function handleCodeChange(v) {
        onChange(v)
        setInitValue(v)
    }

    function handleTextChange(v) {
        onChange(v.currentTarget.innerHTML)
    }

    function handleTextClick(e) {
        if (e.target.tagName === 'A') {
            setLinkUrl(e.target.href)
            setOpenInNewTab(e.target.target === '_blank')
        } else {
            setLinkUrl('')
            setOpenInNewTab(false)
        }
        if (e.target.tagName === 'FONT') {
            setColor(e.target.color)
        } else {
            setColor('#000000')
        }

        if (e.target.getAttribute('style')?.includes('background-color')) {
            const currBgColor = rgbToHex(
                window.getComputedStyle(e.target).backgroundColor,
            )
            setBgColor(currBgColor)
        } else {
            setBgColor('transparent')
        }
        // TODO
    }

    async function handlePaste(e) {
        const items = Array.from(e.clipboardData.items)
        const imageItem = items.find((item) => item.type.startsWith('image/'))

        if (!imageItem) {
            return
        }

        e.preventDefault()

        const file = imageItem.getAsFile()
        if (file) {
            await handleFilesDrop(file)
        }
    }

    return (
        <div className={`html-input${errors?.length ? ' has-error' : ''}`}>
            <div ref={controlsRef} className="controls">
                <InlineStack wrap>
                    {!showCode && (
                        <>
                            <Popover
                                ref={formatRef}
                                closeOnChildClick
                                renderActivator={(ref, listeners) => (
                                    <div ref={ref}>
                                        <Button
                                            {...listeners}
                                            small
                                            text={
                                                <InlineStack>
                                                    <span>A</span>
                                                    <div
                                                        style={{
                                                            transform:
                                                                'rotate(90deg)',
                                                        }}
                                                    >
                                                        <img
                                                            width={16}
                                                            height={16}
                                                            alt="toggle"
                                                            src={
                                                                ICONS.CHEVRON_RIGHT_WHITE
                                                            }
                                                        />
                                                    </div>
                                                </InlineStack>
                                            }
                                        />
                                    </div>
                                )}
                            >
                                {[
                                    {
                                        tag: 'h1',
                                        title: 'Page Title (H1)',
                                        className: 'editor-h1 h1',
                                    },
                                    {
                                        tag: 'h2',
                                        title: 'Section Header (H2)',
                                        className: 'editor-h2 h2',
                                    },
                                    {
                                        tag: 'h3',
                                        title: 'Sub-Header (H3)',
                                        className: 'editor-h3 h3',
                                    },
                                    {
                                        tag: 'h4',
                                        title: 'Heading (H4)',
                                        className: 'editor-h4 h4',
                                    },
                                    {
                                        tag: 'h5',
                                        title: 'Heading (H5)',
                                        className: 'editor-h5 h5',
                                    },
                                    {
                                        tag: 'h6',
                                        title: 'Heading (H6)',
                                        className: 'editor-h6 h6',
                                    },
                                    {
                                        tag: 'p',
                                        title: 'Paragraph',
                                        className: 'editor-p p',
                                    },
                                    {
                                        tag: 'blockquote',
                                        title: 'Blockquote',
                                        className:
                                            'editor-blockquote blockquote',
                                    },
                                ].map(({ tag, title, className }) => {
                                    return (
                                        <div
                                            className={`editor-item ${className}`}
                                            role="button"
                                            key={tag}
                                            title={tag}
                                            onClick={() => {
                                                document.execCommand(
                                                    'formatBlock',
                                                    false,
                                                    tag,
                                                )
                                            }}
                                        >
                                            <div>{title}</div>
                                        </div>
                                    )
                                })}
                            </Popover>
                            <Button
                                onClick={() => document.execCommand('bold')}
                                small
                                text={<strong>B</strong>}
                            ></Button>
                            <Button
                                onClick={() => document.execCommand('italic')}
                                small
                                text={<em>I</em>}
                            ></Button>
                            <Button
                                onClick={() =>
                                    document.execCommand('underline')
                                }
                                small
                                text={<u>U</u>}
                            ></Button>
                            <Button
                                onClick={() =>
                                    document.execCommand('insertOrderedList')
                                }
                                small
                                icon={ICONS.ORDERED_LIST_WHITE}
                            ></Button>
                            <Button
                                onClick={() =>
                                    document.execCommand('insertUnorderedList')
                                }
                                small
                                icon={ICONS.UNORDERED_LIST_WHITE}
                            ></Button>
                            <Popover
                                closeOnChildClick
                                ref={alignRef}
                                renderActivator={(ref, listeners) => (
                                    <div ref={ref}>
                                        <Button
                                            small
                                            text={
                                                <InlineStack itemsCenter>
                                                    <img
                                                        width={16}
                                                        height={16}
                                                        alt="align"
                                                        src={
                                                            ICONS.ALIGN_LEFT_WHITE
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            transform:
                                                                'rotate(90deg)',
                                                        }}
                                                    >
                                                        <img
                                                            width={16}
                                                            height={16}
                                                            alt="toggle"
                                                            src={
                                                                ICONS.CHEVRON_RIGHT_WHITE
                                                            }
                                                        />
                                                    </div>
                                                </InlineStack>
                                            }
                                            {...listeners}
                                            type="button"
                                        />
                                    </div>
                                )}
                            >
                                {[
                                    { tag: 'justifyLeft', title: 'Left' },
                                    { tag: 'justifyCenter', title: 'Center' },
                                    { tag: 'justifyRight', title: 'Right' },
                                ].map(({ tag, title }) => {
                                    return (
                                        <div
                                            role="button"
                                            className="editor-item"
                                            key={tag}
                                            title={tag}
                                            onClick={() => {
                                                document.execCommand(tag)
                                            }}
                                        >
                                            <div>{title}</div>
                                        </div>
                                    )
                                })}
                            </Popover>
                            <Popover
                                ref={linkRef}
                                renderActivator={(ref, listeners) => (
                                    <div ref={ref}>
                                        <Button
                                            small
                                            icon={ICONS.LINK_WHITE}
                                            {...listeners}
                                            type="button"
                                        />
                                    </div>
                                )}
                            >
                                <div className="editor-link">
                                    <TextInput
                                        type="text"
                                        placeholder="URL"
                                        id="link-url"
                                        value={linkUrl}
                                        onChange={(v) => setLinkUrl(v)}
                                    />
                                    <CheckboxInput
                                        label="Open in new tab"
                                        value={openInNewTab}
                                        onChange={setOpenInNewTab}
                                    />
                                    <Button
                                        onClick={() => {
                                            if (
                                                savedSelection.toString() === ''
                                            ) {
                                                return
                                            }
                                            restoreSelection()
                                            const TEMP_HREF = 'temp-href'
                                            document.execCommand(
                                                'createLink',
                                                false,
                                                TEMP_HREF,
                                            )
                                            if (openInNewTab) {
                                                document.querySelector(
                                                    `a[href="${TEMP_HREF}"]`,
                                                ).target = '_blank'
                                            }
                                            document.querySelector(
                                                `a[href="${TEMP_HREF}"]`,
                                            ).href = linkUrl
                                            setLinkUrl('')
                                            setOpenInNewTab(false)
                                        }}
                                        text="Insert"
                                    />
                                </div>
                            </Popover>
                            <div className="color-picker-container">
                                <div
                                    style={{
                                        color,
                                    }}
                                >
                                    a
                                </div>
                                <input
                                    type="color"
                                    value={color}
                                    onInput={(e) => {
                                        setColor(e.target.value)
                                        document.execCommand(
                                            'foreColor',
                                            false,
                                            e.target.value,
                                        )
                                    }}
                                />
                            </div>
                            <div className="bg-color-picker-container">
                                <div
                                    style={{
                                        '--bg-input': bgColor,
                                    }}
                                ></div>
                                <input
                                    type="color"
                                    value={bgColor}
                                    onInput={(e) => {
                                        setBgColor(e.target.value)
                                        document.execCommand(
                                            'backColor',
                                            false,
                                            e.target.value,
                                        )
                                    }}
                                />
                            </div>

                            <Button
                                small
                                icon={ICONS.IMAGE_WHITE}
                                onClick={async () => {
                                    saveSelection()
                                    const files = await openPicker({
                                        isPublic: true,
                                        maxFiles: 10,
                                    })

                                    if (!files) {
                                        return
                                    }

                                    for (const file of files) {
                                        // TODO: This shouldn't be sent as a publicUrl, it has access keys
                                        // Remove all query params
                                        const url = file.publicUrl.split('?')[0]
                                        if (!url) {
                                            return
                                        }

                                        if (!savedSelection) {
                                            const range = document.createRange()
                                            range.selectNodeContents(
                                                editableRef.current,
                                            )
                                            range.collapse(false)
                                            window
                                                .getSelection()
                                                .removeAllRanges()
                                            window
                                                .getSelection()
                                                .addRange(range)
                                        } else {
                                            restoreSelection()
                                        }
                                        const { width, height } = file

                                        const img =
                                            document.createElement('img')
                                        img.width = width
                                        img.height = height
                                        img.src = url

                                        // img.classList.add('img-loading')
                                        // img.onload = function () {
                                        //     img.classList.remove('img-loading')
                                        //     onChange(editableRef.current.innerHTML)
                                        // }
                                        const range = window
                                            .getSelection()
                                            .getRangeAt(0)
                                        range.insertNode(img)
                                        range.setStartAfter(img)
                                        range.collapse(true)
                                        window.getSelection().removeAllRanges()
                                    }

                                    onChange(editableRef.current.innerHTML)
                                }}
                            />

                            {!!infoContent && (
                                <Button
                                    plain
                                    small
                                    icon={ICONS.HELP_WHITE}
                                    onClick={openInfo}
                                />
                            )}
                        </>
                    )}
                    <Button
                        tiny
                        outline
                        text={showCode ? 'Show text' : '</>'}
                        alignRight
                        onClick={() => {
                            const inputEvent = new Event('input', {
                                bubbles: true,
                                cancelable: true,
                            })
                            editableRef.current.dispatchEvent(inputEvent)
                            setShowCode(!showCode)

                            if (onEditorChange && setOptionsState) {
                                onEditorChange(
                                    !showCode ? 'code' : 'text',
                                    setOptionsState,
                                )
                            }
                        }}
                    />
                </InlineStack>
            </div>
            <div className={showCode ? '' : 'aria-hidden'}>
                <TextInput
                    type="textarea"
                    id={id}
                    value={value}
                    onChange={handleCodeChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                />
            </div>

            <div
                ref={editableRef}
                onBlur={handleCodeBlur}
                onClick={handleTextClick}
                onSelect={saveSelection}
                onInput={handleTextChange}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onPaste={handlePaste}
                className={`html-editable ${showCode ? 'aria-hidden' : ''}`}
                contentEditable
                id={id}
                required={rest.required}
                autoComplete={rest.autoComplete}
                minLength={rest.minLength}
                maxLength={rest.maxLength}
                disabled={rest.disabled}
                placeholder={rest.placeholder}
                dangerouslySetInnerHTML={{ __html: initValue }}
            ></div>

            {pickerHtml}
        </div>
    )
}
