import { useParams, useSearchParams } from 'react-router-dom'
import {
    deleteUndefinedValues,
    formatZipCode,
    getUserDisplayName,
} from '../../../common/helpers'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'
import {
    COMPANY_TYPES,
    COUNTRIES,
    DEFAULT_TEAM_ROLES,
    ICONS,
    OTHER_CONTACT_DETAILS_FIELDS,
    US_STATES,
} from '../../../common/constants'
import useData from '../../../common/hooks/useData'
import { useEffect } from 'react'
import useToast from '../../../common/hooks/useToast'
import MessageSection from '../../../components/common/MessageSection'
import useAuth from '../../../common/hooks/useAuth'
import Break from '../../../components/common/Break'

function EditContact() {
    const [searchParams] = useSearchParams()
    const typeValue = searchParams.get('type') || 'contact'
    const isOwnProfile = searchParams.get('isOwnProfile')
    const { contactId, userId, companyId } = useParams()
    const auth = useAuth()
    const id =
        contactId || userId || companyId || (isOwnProfile && auth.user.id)
    const setToast = useToast()

    const { labelsError, labels } = useData(
        !auth.isClient && '/v1/settings/userLabels',
        'labels',
        (data) => [...(data?.settingValue?.split('\n') || []), 'active'],
    )

    useEffect(
        function () {
            if (labelsError && labelsError !== 'Setting not found') {
                setToast(labelsError, 'alert')
            }
        },
        [labelsError],
    )

    const allInputs = [
        ...(['friend', 'family', 'lead', 'client', 'contact'].includes(
            typeValue,
        )
            ? [
                  {
                      key: 'contactType',
                      type: 'radios',
                      label: 'Contact',
                      nullValue: 'contact',
                      options: [
                          {
                              value: 'friend',
                              label: 'Friend',
                          },
                          {
                              value: 'family',
                              label: 'Family',
                          },
                          {
                              value: 'lead',
                              label: 'Lead',
                          },
                          {
                              value: 'client',
                              label: 'Client',
                          },
                      ],
                  },
              ]
            : []),
        {
            key: typeValue === 'company' ? 'logoUrl' : 'avatarUrl',
            label: typeValue !== 'company' ? 'Avatar' : 'Logo',
            type: 'filePicker',
            accept: 'image/*',
            externalSources: isOwnProfile && [
                {
                    apiUrl: '/v1/connected-accounts/me/facebook-profile-image',
                    label: 'Use from Facebook',
                },
            ],
        },
        ...(typeValue !== 'company'
            ? [
                  {
                      key: 'firstName',
                      label: isOwnProfile ? 'Legal first name' : 'First name',
                      required: ['user', 'staff'].includes(typeValue),
                  },
                  ...(typeValue === 'user'
                      ? [
                            {
                                key: 'contactDetails_middleName',
                                label: 'Legal middle name',
                            },
                        ]
                      : []),
                  {
                      key: 'lastName',
                      label: isOwnProfile ? 'Legal last name' : 'Last name',
                      required: ['user', 'staff'].includes(typeValue),
                  },
              ]
            : [
                  {
                      key: 'name',
                      label: 'Name',
                      required: true,
                      autoComplete: 'new-password',
                  },
                  {
                      key: 'companyInfo_companyType',
                      label: 'Company Type',
                      type: 'select',
                      options: [
                          {
                              value: '',
                              label: 'Unknown',
                          },
                          ...COMPANY_TYPES.map((text) => ({
                              value: text,
                              label: text,
                          })),
                      ],
                  },
              ]),
        ...(typeValue !== 'company'
            ? [
                  {
                      key: 'contactDetails_otherNames',
                      type: 'multipleInputs',
                      label: 'Nicknames',
                      toggleGroup: 'Other names',
                  },
                  ...(isOwnProfile
                      ? [
                            {
                                key: 'contactDetails_preferredName',
                                label: 'Preferred name',
                                toggleGroup: 'Other names',
                            },
                        ]
                      : []),
                  {
                      key: 'contactDetails_alternativeFirstName',
                      label: 'Alternative first name',
                      toggleGroup: 'Other names',
                  },
                  {
                      key: 'contactDetails_alternativeLastName',
                      label: 'Alternative last name',
                      toggleGroup: 'Other names',
                  },
              ]
            : []),
        {
            key: 'contactDetails_emails',
            label: 'Email',
            type: 'multipleInputs',
            inputType: 'email',
            autoComplete: 'new-password',
            typeLabels: {
                main: 'Main',
                business: 'Work',
                other: 'Other',
            },
            icon: ICONS.MAIL_GRAY,
            requiredType: 'main',
            uniqueType: 'main',
        },
        {
            label: 'Phone',
            key: 'contactDetails_phones',
            type: 'multiplePhoneInputs',
            uniqueType: 'main',
            typeLabels: {
                main: 'Primary',
                business: 'Work',
                mobile: 'Mobile',
                home: 'Home',
                other: 'Other',
            },
            defaultValue: [],
        },
        {
            key: 'addresses',
            label: 'Address',
            type: 'addInputGroupsInput',
            inputs: [
                {
                    key: 'addressType',
                    label: 'Type',
                    type: 'select',
                    options: [
                        {
                            value: 'main',
                            label: 'Main',
                        },
                        {
                            value: 'shipping',
                            label: 'Shipping',
                        },
                        {
                            value: 'billing',
                            label: 'Billing',
                        },
                        {
                            value: 'work',
                            label: 'Work',
                        },
                        {
                            value: 'school',
                            label: 'School',
                        },
                        {
                            value: '',
                            label: 'Other',
                        },
                    ],
                },
                {
                    key: 'country',
                    label: 'Country',
                    type: 'select',
                    options: COUNTRIES.sort((a, b) =>
                        a.text.localeCompare(b.text),
                    ).map(({ text }) => ({
                        value: text,
                        label: text,
                    })),
                },
                {
                    key: 'address',
                    label: 'Address',
                },
                {
                    key: 'address2',
                    label: 'Unit/apartment/suite',
                },
                {
                    key: 'city',
                    label: 'City',
                },
                {
                    key: 'state',
                    label: 'State',
                    type: 'select',
                    options: US_STATES.map(({ text }) => ({
                        value: text,
                        label: text,
                    })),
                    shouldBeText: (data) => data.country !== 'United States',
                },
                {
                    key: 'zip',
                    label: 'Zip',
                    transformValue: (v, data, itemIndex) => {
                        if (!v) return v
                        const isUS =
                            data.addresses[itemIndex].country ===
                            'United States'
                        return isUS ? formatZipCode(v) : v
                    },
                },
                {
                    key: 'id',
                    type: 'hidden',
                },
            ],
            defaultGroupData: {
                addressType: 'main',
                country: 'United States',
                address: '',
                city: '',
                state: 'Iowa',
                zip: '',
            },
        },

        ...(['user', 'staff'].includes(typeValue)
            ? [
                  {
                      key: 'defaultOrgInfo_ssn',
                      label: 'Social Security Number, ITIN, or ETIN',
                  },
                  {
                      key: 'defaultOrgInfo_birthday',
                      label: 'Birthday',
                      type: 'date',
                  },
              ]
            : []),
        ...(!['company', 'user', 'staff'].includes(typeValue)
            ? [
                  {
                      key: 'metafield_app.ssn',
                      label: 'Social Security Number, ITIN, or ETIN',
                  },
                  {
                      key: 'metafield_app.birthday',
                      label: 'Birthday',
                      type: 'date',
                  },
              ]
            : []),

        ...(typeValue == 'user' && !isOwnProfile
            ? [
                  {
                      disabled: true,
                      key: 'companyOrgInfo_id',
                      label: 'Company',
                      type: 'text',
                      shouldHide: (data) => !data.companyOrgInfo_id,
                  },
                  {
                      key: 'companyOrgInfo_name_id',
                      label: 'Company',
                      type: 'liveSearch',
                      url: '/v1/organizations?organizationType=company&fields=name',
                      showAll: false,
                      getItemsFromResults: (data) => data || [],
                      hasStrValueOnNull: true,
                      getResultValue: (item) => item.name || item,
                      shouldHide: (data) => data.companyOrgInfo_id,
                  },
                  {
                      key: 'companyOrgInfo_title',
                      type: 'text',
                      label: 'Job title',
                  },
                  {
                      key: 'companyOrgInfo_segment_1',
                      label: 'Sector',
                      type: 'resourceDropdown',
                      getOverride: (data) => ({
                          disabled: !/^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          ),
                          url: /^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          )
                              ? `/v1/organizations/${data.companyOrgInfo_name_id}?fields=segments`
                              : null,
                      }),
                      url: null,
                      getItems: (data) => data?.segments || [],
                      getItemLabel: (item) => item.title,
                      getItemValue: (item) => item.title,
                  },
                  {
                      key: 'companyOrgInfo_segment_2',
                      label: 'Division',
                      type: 'resourceDropdown',
                      getOverride: (data) => ({
                          disabled:
                              !/^[a-fA-F0-9]{24}$/.test(
                                  data.companyOrgInfo_name_id,
                              ) || !data.companyOrgInfo_segment_1,
                          url: /^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          )
                              ? `/v1/organizations/${data.companyOrgInfo_name_id}?fields=segments`
                              : null,
                          getItems: (fetchData) =>
                              (fetchData?.segments || []).find(
                                  (s) =>
                                      s.title === data.companyOrgInfo_segment_1,
                              )?.subSegments || [],
                      }),
                      getItems: () => [],
                      url: null,
                      getItemLabel: (item) => item.title,
                      getItemValue: (item) => item.title,
                  },
              ]
            : []),
        ...(!['company', 'user', 'staff'].includes(typeValue)
            ? [
                  {
                      key: 'companyName',
                      type: 'text',
                      label: 'Company',
                  },
                  {
                      key: 'metafield_app.jobTitle',
                      type: 'text',
                      label: 'Job title',
                  },
                  {
                      key: 'metafield_app.companySegment1',
                      type: 'text',
                      label: 'Sector',
                  },
                  {
                      key: 'metafield_app.companySegment2',
                      type: 'text',
                      label: 'Division',
                  },
              ]
            : []),
        ...(typeValue !== 'company' && !isOwnProfile
            ? [
                  {
                      //   defaultValue: [],
                      key: 'labels',
                      label: 'Tags',
                      type: 'liveSearchAddItems',
                      items: labels,
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                  },
              ]
            : []),
        ...(typeValue === 'company' && (auth.isAdmin || auth.isAssistant)
            ? [
                  {
                      defaultValue: [],
                      key: 'tags',
                      label: 'Tags',
                      type: 'liveSearchAddItems',
                      canAddNewItem: true,
                      url: '/v1/tags?resource=Organization',
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                  },
              ]
            : []),

        {
            key: 'venmo',
            type: 'text',
            label: 'Venmo',
            toggleGroup: 'Billing',
        },
        {
            key: 'paypalEmail',
            type: 'email',
            label: 'PayPal email',
            toggleGroup: 'Billing',
        },
        {
            key: 'zelle',
            type: 'text',
            label: 'Zelle',
            toggleGroup: 'Billing',
        },
        {
            key: 'cashApp',
            type: 'text',
            label: 'Cash.me',
            toggleGroup: 'Billing',
        },

        ...Object.entries(OTHER_CONTACT_DETAILS_FIELDS)
            .filter(([key]) => {
                if (!key.includes('facebook')) {
                    return true
                }
                if (typeValue === 'company' && key === 'facebook') {
                    return false
                }
                if (typeValue !== 'company' && key === 'facebookPage') {
                    return false
                }
                return true
            })
            .map(([key, { label }]) => ({
                key: `contactDetails_${key}`,
                type: 'text',
                label,
                toggleGroup: 'Websites and social media',
            })),
        ...(typeValue === 'staff' && !isOwnProfile
            ? [
                  {
                      disabled: true,
                      key: 'companyOrgInfo_id',
                      label: 'Company',
                      type: 'text',
                  },
                  {
                      key: 'warning',
                      type: 'jsx',
                      shouldHide: (data) =>
                          data.companyOrgInfo_id === 'aCo Digital',
                      render: () => (
                          <MessageSection type="warning">
                              Some fields are hidden.
                              <Break />
                              This user is not a member of aCo Digital
                              exclusively. Please contact Support.
                          </MessageSection>
                      ),
                  },
                  {
                      key: 'companyOrgMemberInfo_salary',
                      label: 'Salary',
                      type: 'number',
                      min: 0,
                      step: 0.01,
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_defaultHourlyRate',
                      label: 'Default hourly rate',
                      type: 'number',
                      min: 0,
                      step: 0.01,
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_startDate',
                      label: 'Start date',
                      type: 'date',
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_endDate',
                      label: 'End date',
                      type: 'date',
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_title',
                      label: 'Job title',
                      type: 'resourceDropdown',
                      url:
                          typeValue === 'staff'
                              ? '/v1/settings/jobTypes'
                              : null,
                      getItems: (data) => data?.settingValue?.split('\n'),
                      getItemLabel: (item) => item,
                      getItemValue: (item) => item,
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'teamRoles',
                      required: true,
                      label: 'Team roles',
                      getItemsFromResults: (data) => [
                          ...(data?.settingValue?.split('\n') || []),
                          ...DEFAULT_TEAM_ROLES,
                      ],
                      type: 'liveSearchAddItems',
                      //   canAddNewItem: false,
                      url: '/settings/teamRoles',
                      searchInExistingOnly: true,
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                      //   defaultValue: [],
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_supervisorId',
                      label: 'Supervisor',
                      type: 'liveSearch',
                      url: '/v1/users?role=staff&fields=firstName,lastName,email',
                      showAll: false,
                      getItemsFromResults: (data) => data?.results || [],
                      getResultValue: (item) => {
                          return getUserDisplayName(item)
                      },
                      shouldHide: (data) =>
                          data.companyOrgInfo_id !== 'aCo Digital',
                  },
              ]
            : []),
        ...(typeValue !== 'company'
            ? [
                  {
                      //
                      key: 'contactDetails_marketingOptIn',
                      label: 'Opted in for marketing',
                      type: 'switch',
                      defaultValue: 'false',
                      options: [
                          {
                              value: 'true',
                              label: 'Yes',
                          },
                          {
                              value: 'false',
                              label: 'No',
                          },
                      ],
                  },
              ]
            : []),

        ...(!['user', 'staff', 'company'].includes(typeValue)
            ? [
                  {
                      key: 'notes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),
        ...(['user', 'staff'].includes(typeValue) && !isOwnProfile
            ? [
                  {
                      key: 'internalNotes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
                  {
                      key: 'externalNotes',
                      label: 'Notes (user facing)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),
        ...(typeValue === 'company' && (auth.isAdmin || auth.isAssistant)
            ? [
                  {
                      key: 'internalNotes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),

        ...(typeValue === 'user' && !isOwnProfile
            ? [
                  {
                      infoBottom:
                          'This will be overwritten if the a new status is updated in Stripe.',
                      key: 'isPremiumUser',
                      label: 'Premium status',
                      type: 'switch',
                      options: [
                          {
                              value: 'false',
                              label: 'Free',
                          },
                          {
                              value: 'true',
                              label: 'Premium',
                          },
                      ],
                  },
              ]
            : []),

        // ...(definitions?.length
        //     ? definitions
        //           .filter((d) => {
        //               if (PRESERVER_CONTACT_METAFIELDS.includes(d.key)) {
        //                   return false
        //               }
        //               if (
        //                   ['user', 'staff'].includes(typeValue) &&
        //                   d.resource !== 'User'
        //               ) {
        //                   return false
        //               }
        //               if (
        //                   !['company', 'user', 'staff'].includes(typeValue) &&
        //                   d.resource !== 'Lead'
        //               ) {
        //                   return false
        //               }
        //               if (
        //                   typeValue === 'company' &&
        //                   d.resource !== 'Organization'
        //               ) {
        //                   return false
        //               }
        //               return true
        //           })
        //           .map((d) => ({
        //               key: `metafield_${d.key}`,
        //               label: d.title,
        //               type: d.valueType,
        //               toggleGroup: 'Metafields',
        //           }))
        //     : []),
    ]

    function mapItemToData(item) {
        const data = {}
        data.contactType = item.contactType
        data.firstName = item.firstName
        data.lastName = item.lastName
        data.avatarUrl = item.avatarUrl
        data.logoUrl = item.logoUrl
        data.name = item.name
        data.companyInfo_companyType = item.companyInfo?.companyType
        data.contactDetails_middleName = item.contactDetails?.middleName
        data.contactDetails_otherNames = item.contactDetails?.otherNames?.length
            ? item.contactDetails.otherNames.map((n) => ({ value: n }))
            : undefined
        data.contactDetails_preferredName = item.contactDetails?.preferredName
        data.contactDetails_alternativeFirstName =
            item.contactDetails?.alternativeFirstName
        data.contactDetails_alternativeLastName =
            item.contactDetails?.alternativeLastName
        data.contactDetails_emails = item.contactDetails?.emails?.length
            ? item.contactDetails.emails.map((e) => ({
                  value: e.email,
                  type: e.emailType,
                  id: e.id || e._id,
              }))
            : undefined
        data.contactDetails_phones = item.contactDetails?.phones?.length
            ? item.contactDetails.phones.map((p) => ({
                  value: p.phone,
                  type: p.phoneType,
                  id: p.id || p._id,
              }))
            : undefined
        data.addresses = item.addresses?.length
            ? item.addresses.map((a) => ({
                  addressType: a.addressTypes?.[0] || 'other',
                  country: a.country,
                  address: a.address,
                  address2: a.address2,
                  city: a.city,
                  state: a.state,
                  zip: a.zip,
                  id: a.id,
              }))
            : undefined
        data.defaultOrgInfo_ssn = item.defaultOrgDefaultInfo?.ssn || undefined
        data.defaultOrgInfo_birthday = item.defaultOrgDefaultInfo?.birthday
            ? item.defaultOrgDefaultInfo.birthday.split('T')[0]
            : undefined

        data['metafield_app.ssn'] = item.metafields?.find(
            (m) => m.key === 'app.ssn',
        )?.value

        data['metafield_app.birthday'] =
            item.metafields?.find((m) => m.key === 'app.birthday')?.value ||
            undefined

        data.companyOrgInfo_id = item.allCompanyOrgs?.[0]?.name
        data.companyOrgInfo_title = item.firstCompanyMemberInfo?.title
        data.companyOrgInfo_segment_1 = item.firstCompanyMemberInfo?.segment1
        data.companyOrgInfo_segment_2 = item.firstCompanyMemberInfo?.segment2

        data.companyName = item.companyName

        data['metafield_app.jobTitle'] = item.metafields?.find(
            (m) => m.key === 'app.jobtitle',
        )?.value

        data['metafield_app.companySegment1'] = item.metafields?.find(
            (m) => m.key === 'app.companysegment1',
        )?.value

        data['metafield_app.companySegment2'] = item.metafields?.find(
            (m) => m.key === 'app.companysegment2',
        )?.value

        data.labels = item.labels
        data.tags = item.tags

        data.venmo = item.billingMethods?.find(
            (m) => m.method === 'venmo',
        )?.username
        data.paypalEmail = item.billingMethods?.find(
            (m) => m.method === 'paypal',
        )?.email
        data.zelle = item.billingMethods?.find(
            (m) => m.method === 'zelle',
        )?.username
        data.cashApp = item.billingMethods?.find(
            (m) => m.method === 'cashapp',
        )?.username

        for (const key of Object.keys(OTHER_CONTACT_DETAILS_FIELDS)) {
            data[`contactDetails_${key}`] = item.contactDetails?.[key]
        }

        data.companyOrgMemberInfo_salary = item.firstCompanyMemberInfo?.salary

        data.companyOrgMemberInfo_defaultHourlyRate =
            item.firstCompanyMemberInfo?.defaultHourlyRate

        data.companyOrgMemberInfo_startDate = item.firstCompanyMemberInfo
            ?.startDate
            ? item.firstCompanyMemberInfo?.startDate.split('T')[0]
            : undefined

        data.companyOrgMemberInfo_endDate = item.firstCompanyMemberInfo?.endDate
            ? item.firstCompanyMemberInfo?.startDate.split('T')[0]
            : undefined

        data.companyOrgMemberInfo_title = item.firstCompanyMemberInfo?.title

        data.teamRoles = item.teamRoles

        data.companyOrgMemberInfo_supervisorId = item.firstCompanyMemberInfo
            ?.supervisor
            ? getUserDisplayName(item.firstCompanyMemberInfo?.supervisor)
            : undefined

        data.contactDetails_marketingOptIn =
            'marketingOptIn' in (item.contactDetails || {})
                ? item.contactDetails?.marketingOptIn
                    ? 'true'
                    : 'false'
                : undefined

        data.notes = item.notes
        data.internalNotes = item.internalNotes
        data.externalNotes = item.externalNotes
        data.isPremiumUser = item.isPremiumUser ? 'true' : 'false'

        deleteUndefinedValues(data)

        return data
    }

    function getBody(currData, item, initialData) {
        const data = { ...currData }

        if (['user', 'staff'].includes(typeValue)) {
            const newMainEmail = data.contactDetails_emails?.find(
                (item) => item.type === 'main',
            )?.value
            const prevMainEmail = initialData.contactDetails_emails?.find(
                (item) => item.type === 'main',
            )?.value

            if (newMainEmail !== undefined && newMainEmail !== prevMainEmail) {
                setToast(
                    "The main email cannot change. Emails won't be updated.",
                    'warning',
                )
                delete data.contactDetails_emails
            }
        }

        const body = {}

        const email = data.contactDetails_emails?.find(
            (item) => item.type === 'main',
        )?.value

        if (email && !['company', 'user', 'staff'].includes(typeValue)) {
            body.email = email
        }

        for (const key of Object.keys(data)) {
            body[key] = data[key]
            // if (key in data) {
            // }
        }

        if ('companyInfo_companyType' in data) {
            body.companyInfo = {
                companyType: data.companyInfo_companyType,
            }
            delete body.companyInfo_companyType
        }

        if ('contactDetails_middleName' in data) {
            body.contactDetails = {
                ...body.contactDetails,
                middleName: data.contactDetails_middleName,
            }
            delete body.contactDetails_middleName
        }

        if ('contactDetails_otherNames' in data) {
            body.contactDetails = {
                ...body.contactDetails,
                otherNames: data.contactDetails_otherNames
                    .map(({ value }) => value)
                    .filter(Boolean),
            }
            delete body.contactDetails_otherNames
        }

        if ('contactDetails_preferredName' in data) {
            body.contactDetails = {
                ...body.contactDetails,
                preferredName: data.contactDetails_preferredName,
            }
            delete body.contactDetails_preferredName
        }

        if ('contactDetails_alternativeFirstName' in data) {
            body.contactDetails = {
                ...body.contactDetails,
                alternativeFirstName: data.contactDetails_alternativeFirstName,
            }
            delete body.contactDetails_alternativeFirstName
        }

        if ('contactDetails_alternativeLastName' in data) {
            body.contactDetails = {
                ...body.contactDetails,
                alternativeLastName: data.contactDetails_alternativeLastName,
            }
            delete body.contactDetails_alternativeLastName
        }

        if ('contactDetails_emails' in data) {
            body.contactDetails = {
                ...(body.contactDetails || {}),
                emails: data.contactDetails_emails
                    .filter((item) => item.value)
                    .map(({ value, type, id }) => ({
                        email: value,
                        emailType: type,
                        id,
                    })),
            }
            delete body.contactDetails_emails
        }

        if ('contactDetails_phones' in data) {
            body.contactDetails = {
                ...(body.contactDetails || {}),
                phones: data.contactDetails_phones
                    .filter((item) => item.value)
                    .map(({ value, type, id }) => ({
                        phone: value,
                        phoneType: type,
                        id,
                    })),
            }
            delete body.contactDetails_phones
        }

        if ('contactDetails_marketingOptIn' in data) {
            body.contactDetails = {
                ...(body.contactDetails || {}),
                marketingOptIn: data.contactDetails_marketingOptIn === 'true',
            }
            delete body.contactDetails_marketingOptIn
        }

        for (const key of Object.keys(OTHER_CONTACT_DETAILS_FIELDS)) {
            if (`contactDetails_${key}` in data) {
                body.contactDetails = {
                    ...(body.contactDetails || {}),
                    [key]: data[`contactDetails_${key}`],
                }
                delete body[`contactDetails_${key}`]
            }
        }

        if ('addresses' in data) {
            body.addresses = data.addresses.map((item) => ({
                addressTypes: [item.addressType] || undefined,
                country: item.country || undefined,
                address: item.address || undefined,
                address2: item.address2 || undefined,
                city: item.city || undefined,
                state: item.state || undefined,
                zip: item.zip || undefined,
                id: item.id || undefined,
            }))
        }

        if ('venmo' in data) {
            body.billingMethods = [
                ...(body.billingMethods || []),
                {
                    method: 'venmo',
                    username: data.venmo,
                    id: item.billingMethods?.find((m) => m.method === 'venmo')
                        ?.id,
                },
            ]
            delete body.venmo
        }

        if ('paypalEmail' in data) {
            body.billingMethods = [
                ...(body.billingMethods || []),
                {
                    method: 'paypal',
                    email: data.paypalEmail,
                    id: item.billingMethods?.find((m) => m.method === 'paypal')
                        ?.id,
                },
            ]
            delete body.paypalEmail
        }

        if ('zelle' in data) {
            body.billingMethods = [
                ...(body.billingMethods || []),
                {
                    method: 'zelle',
                    username: data.zelle,
                    id: item.billingMethods?.find((m) => m.method === 'zelle')
                        ?.id,
                },
            ]
            delete body.zelle
        }

        if ('cashApp' in data) {
            body.billingMethods = [
                ...(body.billingMethods || []),
                {
                    method: 'cashapp',
                    username: data.cashApp,
                    id: item.billingMethods?.find((m) => m.method === 'cashapp')
                        ?.id,
                },
            ]
            delete body.cashApp
        }

        if ('defaultOrgInfo_ssn' in data) {
            body.defaultOrgInfo = {
                ...(body.defaultOrgInfo || {}),
                ssn: data.defaultOrgInfo_ssn,
            }
            delete body.defaultOrgInfo_ssn
        }

        if ('defaultOrgInfo_birthday' in data) {
            body.defaultOrgInfo = {
                ...(body.defaultOrgInfo || {}),
                birthday: data.defaultOrgInfo_birthday,
            }
            delete body.defaultOrgInfo_birthday
        }

        if ('companyOrgInfo_name_id' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                nameId: data.companyOrgInfo_name_id,
            }
            delete body.companyOrgInfo_name_id
        }

        if (
            'companyOrgInfo_title' in data &&
            (item.allCompanyOrgs?.length || 'companyOrgInfo_name_id' in data)
        ) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    title: data.companyOrgInfo_title,
                },
            }
            delete body.companyOrgInfo_title
        }

        if (
            'companyOrgInfo_segment_1' in data &&
            (item.allCompanyOrgs?.length || 'companyOrgInfo_name_id' in data)
        ) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                member: {
                    ...(body.companyOrgInfo?.member || {}),
                    segments: [
                        data.companyOrgInfo_segment_1,
                        ...(data.companyOrgInfo_segment_2
                            ? [data.companyOrgInfo_segment_2]
                            : []),
                    ],
                },
            }
            delete body.companyOrgInfo_segment_1
            delete body.companyOrgInfo_segment_2
        }

        if ('companyOrgMemberInfo_salary' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    salary: data.companyOrgMemberInfo_salary,
                },
            }
            delete body.companyOrgMemberInfo_salary
        }

        if ('companyOrgMemberInfo_defaultHourlyRate' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    defaultHourlyRate:
                        data.companyOrgMemberInfo_defaultHourlyRate,
                },
            }
            delete body.companyOrgMemberInfo_defaultHourlyRate
        }

        if ('companyOrgMemberInfo_startDate' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    startDate: data.companyOrgMemberInfo_startDate,
                },
            }
            delete body.companyOrgMemberInfo_startDate
        }

        if ('companyOrgMemberInfo_endDate' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    endDate: data.companyOrgMemberInfo_endDate,
                },
            }
            delete body.companyOrgMemberInfo_endDate
        }

        if ('companyOrgMemberInfo_title' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    title: data.companyOrgMemberInfo_title,
                },
            }
            delete body.companyOrgMemberInfo_title
        }

        if ('companyOrgMemberInfo_supervisorId' in data) {
            body.companyOrgInfo = {
                ...(body.companyOrgInfo || {}),
                memberInfo: {
                    ...(body.companyOrgInfo?.memberInfo || {}),
                    supervisorId: data.companyOrgMemberInfo_supervisorId,
                },
            }
            delete body.companyOrgMemberInfo_supervisorId
        }

        body.metafields = []

        for (const key of Object.keys(data)) {
            if (!key.startsWith('metafield_')) {
                continue
            }

            if (data[key] === undefined) {
                continue
            }

            const metafieldKey = key.replace('metafield_', '')
            body.metafields.push({
                key: metafieldKey,
                value: data[key],
            })
        }

        if (!body.metafields.length) {
            delete body.metafields
        }

        return body
    }

    let url
    let submitUrl
    switch (typeValue) {
        case 'contact':
        case 'client':
        case 'lead':
        case 'family':
        case 'friend': {
            const fields = [
                'firstName',
                'lastName',
                'avatarUrl',
                'companyName',
                'labels',
                'notes',
                'contactType',
            ].join('&fields[]=')
            const populate = [
                'contactDetails',
                'addresses',
                'metafields',
                'billingMethods',
            ].join('&populate[]=')
            url = `/v1/contacts/${id}?fields[]=${fields}&populate[]=${populate}`
            submitUrl = `/contacts/${id}`
            break
        }
        case 'user': {
            const fields = [
                'firstName',
                'lastName',
                'avatarUrl',
                ...(isOwnProfile ? [] : ['labels']),
                ...(isOwnProfile ? [] : ['internalNotes']),
                'externalNotes',
                ...(auth.isAdmin ? ['isPremiumUser'] : []),
            ].join('&fields[]=')
            const populate = [
                'contactDetails',
                'addresses',
                // 'metafields',
                'defaultOrgDefaultInfo',
                'allCompanyOrgs.$name',
                'firstCompanyMemberInfo',
                'billingMethods',
            ].join('&populate[]=')
            url = `/v1/users/${id}?fields[]=${fields}&populate[]=${populate}`
            submitUrl = isOwnProfile ? '/profile' : `/users/${id}`
            break
        }
        case 'staff': {
            const fields = [
                'firstName',
                'lastName',
                'avatarUrl',
                ...(isOwnProfile ? [] : ['labels']),
                ...(isOwnProfile ? [] : ['internalNotes']),
                'teamRoles',
                'externalNotes',
            ].join('&fields[]=')
            const populate = [
                'contactDetails',
                'addresses',
                // 'metafields',
                'defaultOrgDefaultInfo',
                'allCompanyOrgs.$name',
                'firstCompanyMemberInfo',
                'billingMethods',
            ].join('&populate[]=')
            url = `/v1/users/${id}?fields[]=${fields}&populate[]=${populate}`
            submitUrl = `/users/${id}`
            break
        }
        case 'company': {
            const fields = [
                'name',
                'acronym',
                'logoUrl',
                ...(auth.isAdmin || auth.isAssistant
                    ? ['internalNotes', 'tags']
                    : []),
            ].join('&fields[]=')
            const populate = [
                'companyInfo.$companyType',
                'contactDetails',
                'addresses',
                'billingMethods',
            ].join('&populate[]=')
            url = `/v1/organizations/${id}?fields[]=${fields}&populate[]=${populate}`
            submitUrl = `/companies/${id}`
            break
        }
        default:
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={url}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={submitUrl}
                inputs={allInputs}
                getBody={getBody}
                mapItemToData={mapItemToData}
                mutationRegexes={[
                    /\/v1\/contacts/,
                    /\/v1\/users/,
                    /\/v1\/organizations/,
                ]}
                mainButton
                onlySaveChanges
                beforeCompareChanges={(data) => {
                    const result = { ...data }

                    if (result.contactDetails_emails) {
                        result.contactDetails_emails =
                            result.contactDetails_emails.filter(
                                ({ value }) => value,
                            )
                    }

                    if (result.contactDetails_phones) {
                        result.contactDetails_phones =
                            result.contactDetails_phones.filter(
                                ({ value }) => value,
                            )
                    }

                    if (result.contactDetails_otherNames) {
                        result.contactDetails_otherNames =
                            result.contactDetails_otherNames.filter(
                                ({ value }) => value,
                            )
                    }

                    if (result.addresses) {
                        result.addresses = result.addresses.filter(
                            ({ address }) => address,
                        )
                    }

                    return result
                }}
            />
        </SectionContainer>
    )
}

export default EditContact
