import useData from '../../../common/hooks/useData'
import DataFormNew from '../../../components/common/DataFormNew'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useAuth from '../../../common/hooks/useAuth'
import SectionContainer from '../../../components/common/SectionContainer'

const CLOTHING_SIZES = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL']
const CLOTHING_COLORS = [
    'White',
    'Black',
    'Gray ',
    'Red',
    'Pink',
    'Orange',
    'Yellow',
    'Green',
    'Teal',
    'Blue ',
    'Purple',
    'Brown',
    'Tan',
    'Silver',
    'Gold',
]

async function validateFile(file) {
    const MAX_SIZE = 20 * 1024 * 1024
    const MAX_DIMENSION = 4472
    if (!file) {
        throw new Error('Product photo is missing')
    }
    if (file.size > MAX_SIZE) {
        throw new Error(`Max image size is ${MAX_SIZE}MB.`)
    }
    const img = new Image()
    img.src = URL.createObjectURL(file)
    await new Promise((resolve) => {
        img.onload = resolve
    })
    if (img.naturalWidth > MAX_DIMENSION || img.naturalHeight > MAX_DIMENSION) {
        throw new Error(`Max image size is ${MAX_DIMENSION}x${MAX_DIMENSION}.`)
    }
}

export default function CreateProduct() {
    const auth = useAuth()
    const { tags, tagsLoading, tagsError } = useData(
        '/v1/settings/availableShopifyProductTags',
        'tags',
        (data) => data?.settingValue?.split('\n') || [],
    )

    const { shops, shopsLoading, shopsError } = useData(
        '/v1/shop/shops?fields=fullName&syncStrategies=all_created_by_app&&appShops=true',
        'shops',
        (data) => data || [],
    )

    const inputs = [
        ...[
            auth.user.orgsData?.length === 1
                ? {
                      key: 'organizationId',
                      type: 'hidden',
                      value: auth.user.orgsData[0].id,
                      defaultValue: auth.user.orgsData[0].id,
                  }
                : {
                      key: 'organizationId',
                      required: true,
                      label: 'Company',
                      type: 'select',
                      options: [
                          { value: '', label: '' },
                          ...auth.user.orgsData.map((org) => ({
                              value: org.id,
                              label: org.name,
                          })),
                      ],
                  },
        ],
        {
            key: 'info',
            type: 'jsx',
            render: () =>
                `Will be synced to shops: ${shops
                    .map((s) => s.fullName)
                    .join(', ')}`,
        },
        {
            key: 'title',
            label: 'Title',
            required: true,
            autoComplete: 'new-password',
            infoTop:
                "3-6 words under 75 characters, be descriptive. If you're stuck, Google what other people call similar items.",
        },
        {
            key: 'categoryTag',
            label: 'Category',
            type: 'select',
            options: [
                { value: '', label: 'No category' },
                ...tags.map((tag) => ({ value: tag, label: tag })),
            ],
        },
        {
            key: 'colors',
            label: 'Colors',
            type: 'resourceList',
            items: CLOTHING_COLORS.map((color) => ({ title: color })),
            selectedIdField: 'title',
            getItemText: (item) => item.title,
            getSelectedItemText: (item) => item.title,
            fields: [
                {
                    column: 1,
                    getValue: (item) => item.title,
                },
            ],
            shouldHide: (data) =>
                data.categoryTag?.toLowerCase() !== 'clothing',
        },
        // {
        //     multiplyFor: (data) => data.colors || [],
        //     key: 'variantPhotos',
        //     label: 'Photo',
        //     type: 'file',
        //     accept: 'image/jpeg',
        //     required: true,
        //     infoTop: 'Square, at least 1000px wide.',
        //     shouldHide: (data) =>
        //         data.categoryTag?.toLowerCase() !== 'clothing',
        // },

        {
            key: 'photo',
            label: 'Photo',
            type: 'file',
            accept: 'image/jpeg',
            required: true,
            infoTop: 'Square, at least 1000px wide.',
            shouldHide: (data) =>
                data.categoryTag?.toLowerCase() === 'clothing',
        },

        {
            key: `variantsWholesalePrice`,
            label: `Wholesale Price`,
            type: 'number',
            min: 0.3,
            step: 0.01,
            required: true,
            defaultValue: '0',
        },
        {
            key: `variantsRecommendedPrice`,
            label: `Recommended Price`,
            type: 'number',
            min: 0.3,
            step: 0.01,
        },
        {
            key: `variantsRetailPrice`,
            label: `Retail Price`,
            type: 'number',
            min: 0.3,
            step: 0.01,
        },
        {
            key: `variantsMinimumOrderType`,
            label: `Minimum order type`,
            type: 'select',
            options: [
                { value: '', label: '' },
                { value: 'quantity', label: 'Quantity' },
                { value: 'price', label: 'Price' },
            ],
        },
        {
            key: `variantsMinimumOrderValue`,
            label: `Minimum order value`,
            type: 'number',
        },
        {
            key: 'description',
            label: 'Description',
            type: 'textarea',
            rows: 8,
            autoComplete: 'new-password',
            required: true,
            infoTop: 'Plain text (HTML is not allowed).',
        },

        {
            key: 'weight',
            label: 'Weight (LBS)',
            type: 'number',
            min: 0,
            step: 0.01,
            required: true,
            defaultValue: '0',
        },
        {
            key: 'seasonTag',
            label: 'Season',
            type: 'select',
            options: [
                { value: '', label: 'No season' },
                { value: 'Spring', label: 'Spring' },
                { value: 'Summer', label: 'Summer' },
                { value: 'Fall', label: 'Fall' },
                { value: 'Winter', label: 'Winter' },
            ],
        },
    ]

    async function getBody(data) {
        const isClothing = data.categoryTag?.toLowerCase() === 'clothing'

        if (isClothing && !data.colors?.length) {
            throw new Error('You need to select at least one color')
        }

        if (isClothing) {
            const photoKeys = data.colors.map(
                (color) => `variantPhotos_${color}`,
            )
            for (const key of photoKeys) {
                const file = data[key][0]
                await validateFile(file)
            }
        } else {
            const file = data.photo[0]
            await validateFile(file)
        }

        const formData = new FormData()

        const bodyObj = {
            title: data.title,
            tags: data.categoryTag ? [data.categoryTag] : [],
            descriptionHtml: data.description,
            weight: data.weight,
            variantsWholesalePrice: data.variantsWholesalePrice,
            variantsRecommendedPrice: data.variantsRecommendedPrice,
            variantsRetailPrice: data.variantsRetailPrice,
            variantsMinimumOrderType: data.variantsMinimumOrderType || null,
            variantsMinimumOrderValue: data.variantsMinimumOrderValue || null,
            organizationId: data.organizationId,
        }
        if (data.seasonTag) {
            bodyObj.tags.push(data.seasonTag)
        }

        if (data.categoryTag?.toLowerCase() === 'clothing') {
            bodyObj.option1 = 'Size'
            bodyObj.option1Values = CLOTHING_SIZES
            bodyObj.option2 = 'Color'
            bodyObj.option2Values = data.colors
        }

        formData.append('data', JSON.stringify(bodyObj))

        if (isClothing) {
            for (const color of data.colors) {
                const file = data[`variantPhotos_${color}`][0]
                formData.append(`photoFiles[${color}]`, file)
            }
        } else {
            formData.append('photoFiles[main]', data.photo[0])
        }

        return formData
    }

    if (tagsLoading || shopsLoading) {
        return <Spinner />
    }
    if (tagsError || shopsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{tagsError || shopsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/shop/products`}
                    method="POST"
                    submitText="Create product"
                    submitToast=""
                    getSubmitMessage={() => 'Product created'}
                    getInitializeAfterSaveFields={() => ({ title: '' })}
                    mutationRegexes={[
                        /\/v1\/wholesale\/products-graphql/,
                        /\/v1\/shop\/products/,
                    ]}
                    inputs={inputs}
                    mainButton
                    getBody={getBody}
                />
            </SectionContainer>
            {/* <section>
                <small>
                    To determine the price for a product, take into account the
                    desired profit margin and production costs. Consider the
                    commission rate, which can range from 15% to 50% based on
                    the type of product and whether it is sold in-store. Also,
                    be aware of payment processing fees, typically around 3%. To
                    cover expenses like selling on Amazon, add an extra markup
                    to the wholesale price. It&apos;s important to note that the
                    price should be set at a wholesale rate, rather than a
                    retail rate, in order to ensure that the product is priced
                    appropriately for the market.
                </small>
                <br />
            </section> */}
        </>
    )
}
