import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import BlockStack from '../BlockStack'
import TextInput from './TextInput'
import InlineStack from '../InlineStack'
import Button from '../Button'
import { ICONS } from '../../../common/constants'
import useModal from '../../../common/hooks/useModal'
import FormModalContent from '../FormModalContent'
import BorderWrapper from '../BorderWrapper'
import DragArea from '../DragArea'

// TODO: Creating nested creates it in other places too

function addItemToValue(item, value, path) {
    let newValue = [...value]
    let currentLevel = newValue
    for (const i of path) {
        currentLevel = currentLevel[i].items
    }
    currentLevel.push(item)
    return newValue
}

function removeItemFromValue(itemIndex, value, path) {
    let newValue = [...value]
    let currentLevel = newValue
    for (const i of path) {
        currentLevel = currentLevel[i].items
    }
    currentLevel.splice(itemIndex, 1)
    return newValue
}

function Item({
    value,
    onChange,
    idsTouple,
    item,
    openAddModal,
    handleRemove,
    openEditModal,
    segmentTypes,
    handleNewIdPosition,
    level,
    additionalFields = [],
    id,
    parentId,
}) {
    const hasAdditionalFields = Object.keys(item).some(
        (key) => !['title', 'itemType', 'items'].includes(key),
    )

    const items = item.items || []

    return (
        <BorderWrapper>
            <InlineStack gap={'sm'}>
                <div className="drag-handle">
                    <img
                        src={ICONS.DRAG_WHITE}
                        alt="drag"
                        width={16}
                        height={16}
                    />
                </div>
                <BlockStack gap={'tiny'}>
                    <InlineStack gap={'tiny'}>
                        <div>
                            {item.title}
                            {/* <br /> */}
                            {hasAdditionalFields ? (
                                Object.entries(item)
                                    .filter(
                                        ([key]) =>
                                            ![
                                                'title',
                                                'itemType',
                                                'items',
                                            ].includes(key) &&
                                            item[key] &&
                                            key !== 'dragId',
                                    )

                                    .map(([key, value]) => {
                                        let text = value
                                        const field = additionalFields.find(
                                            (field) => field.key === key,
                                        )
                                        if (field?.getResultValue) {
                                            text = field.getResultValue(value)
                                        }
                                        return (
                                            <span
                                                key={key}
                                                className="text-subdued"
                                            >
                                                <br />
                                                {
                                                    additionalFields.find(
                                                        (field) =>
                                                            field.key === key,
                                                    )?.label
                                                }
                                                : {text}
                                            </span>
                                        )
                                    })
                            ) : (
                                <span className="text-subdued">
                                    <br />
                                    {item.itemType}
                                </span>
                            )}
                        </div>
                        <InlineStack gap={'tiny'}>
                            <Button
                                title={'Edit'}
                                icon={ICONS.EDIT_ACTIVE}
                                tiny
                                outline
                                onClick={() => openEditModal(id, level)}
                            />
                            <Button
                                destructive
                                title={'Delete'}
                                icon={ICONS.TRASH_RED}
                                tiny
                                outline
                                onClick={() => handleRemove(id, parentId)}
                            />
                        </InlineStack>
                    </InlineStack>

                    {!!items?.length && (
                        <DragArea
                            id={`tree-input-drag-${id}`}
                            onNewIdPosition={handleNewIdPosition}
                        >
                            {items.map((nestedItem) => {
                                const nestedItemId = getIdByItem(
                                    nestedItem,
                                    idsTouple,
                                )
                                return (
                                    <div
                                        className="added-item"
                                        // eslint-disable-next-line react/no-unknown-property
                                        dragid={nestedItemId}
                                        key={nestedItemId}
                                    >
                                        <Item
                                            value={value}
                                            onChange={onChange}
                                            id={nestedItemId}
                                            parentId={id}
                                            idsTouple={idsTouple}
                                            additionalFields={additionalFields}
                                            item={nestedItem}
                                            openAddModal={openAddModal}
                                            segmentTypes={segmentTypes}
                                            level={level + 1}
                                            handleRemove={handleRemove}
                                            openEditModal={openEditModal}
                                            handleNewIdPosition={
                                                handleNewIdPosition
                                            }
                                        />
                                    </div>
                                )
                            })}
                        </DragArea>
                    )}

                    {segmentTypes.filter(Boolean).length > level + 1 && (
                        <InlineStack>
                            <Button
                                text={`Add ${segmentTypes[level + 1]}`}
                                icon={ICONS.PLUS_ACTIVE}
                                outline
                                tiny
                                onClick={() => openAddModal(id, level + 1)}
                            />
                        </InlineStack>
                    )}
                </BlockStack>
            </InlineStack>
        </BorderWrapper>
    )
}

function getTypesFromItems(items) {
    function collectTypes(items) {
        const types = items.reduce((acc, item) => {
            if (!acc.includes(item.itemType)) {
                acc.push(item.itemType)
            }
            return [...acc, ...collectTypes(item.items || [])]
        }, [])
        return [...new Set(types)]
    }

    const allTypes = collectTypes(items)

    const limitedTypes =
        allTypes.length > 3
            ? allTypes.slice(0, 3)
            : allTypes.concat(Array(3 - allTypes.length).fill(''))

    return limitedTypes
}

function getItemById(id, touple) {
    return touple.find((i) => i[0] === id)?.[1]
}

function getIdByItem(item, touple) {
    return touple.find((i) => i[1] === item)?.[0]
}

export default function TreeInput({
    value = [],
    onChange,
    errors,
    additionalFields = [],
    titlessLevels,
}) {
    const idsToupleRef = useRef([])

    useLayoutEffect(
        function () {
            function setIds(items) {
                for (const item of items) {
                    const id = getIdByItem(item, idsToupleRef.current)
                    if (!id) {
                        const newId = idsToupleRef.current.length + 1
                        idsToupleRef.current.push([newId, item])
                    }
                    if (item.items) {
                        setIds(item.items)
                    }
                }
            }
            setIds(value)
        },
        [value],
    )

    const [segmentTypes, setSegmentTypes] = useState(
        titlessLevels
            ? ['Level 1', 'Level 2', 'Level 3']
            : getTypesFromItems(value),
    )

    const { setModal } = useModal()

    function handleTypeChange(v, i) {
        if (i > 0 && !segmentTypes[i - 1]) {
            return
        }

        const newTypes = [...segmentTypes]
        newTypes[i] = v
        setSegmentTypes(newTypes)

        function updateSegmentType(itemsList, currentLevel) {
            return itemsList.map((item) => ({
                ...item,
                items: updateSegmentType(item.items, currentLevel + 1),
                itemType: currentLevel === i ? v : item.itemType,
            }))
        }

        onChange(updateSegmentType(value, 0))
    }

    function openAddModal(parentItemId, level) {
        setModal(
            <FormModalContent
                onConfirm={async (inputs) => {
                    if (!inputs.title) {
                        setModal(null)
                        return
                    }

                    const newId = idsToupleRef.current.length + 1

                    const newItem = {
                        title: inputs.title,
                        itemType: segmentTypes[level],
                        items: [],
                        ...additionalFields.reduce((acc, field) => {
                            acc[field.key] = inputs[field.key] || ''
                            return acc
                        }, {}),
                    }
                    idsToupleRef.current.push([newId, newItem])

                    if (!parentItemId) {
                        const newValue = [...value, newItem]
                        onChange(newValue)
                    } else {
                        const parent = getItemById(
                            parentItemId,
                            idsToupleRef.current,
                        )
                        if (!parent.items) {
                            parent.items = []
                        }
                        parent.items.push(newItem)
                        const newValue = [...value]
                        onChange(newValue)
                    }

                    setModal(null)
                }}
                inputs={[
                    {
                        key: 'title',
                        label: 'Title',
                        type: 'text',
                        required: true,
                    },
                    ...additionalFields,
                ]}
            />,
            `Add ${segmentTypes[level]}`,
        )
    }

    function handleRemove(id, parentItemId) {
        const item = getItemById(id, idsToupleRef.current)
        if (!parentItemId) {
            const index = value.indexOf(item)
            const newValue = [...value]
            newValue.splice(index, 1)
            onChange(newValue)
        } else {
            const parent = getItemById(parentItemId, idsToupleRef.current)
            const index = parent.items.indexOf(item)
            parent.items.splice(index, 1)
            const newValue = [...value]
            onChange(newValue)
        }
    }

    function openEditModal(id, level) {
        const item = getItemById(id, idsToupleRef.current)

        setModal(
            <FormModalContent
                onConfirm={async (inputs) => {
                    if (!inputs.title) {
                        setModal(null)
                        return
                    }

                    const data = {
                        title: inputs.title,
                        ...additionalFields.reduce((acc, field) => {
                            acc[field.key] = inputs[field.key] || ''
                            return acc
                        }, {}),
                    }

                    Object.assign(item, data)
                    const newValue = [...value]

                    onChange(newValue)
                    setModal(null)
                }}
                inputs={[
                    {
                        key: 'title',
                        label: 'Title',
                        type: 'text',
                        required: true,
                        defaultValue: item.title,
                    },
                    ...additionalFields.map((field) => ({
                        ...field,
                        defaultValue: item[field.key] || '',
                    })),
                ]}
            />,
            `Edit ${segmentTypes[level]}`,
        )
    }

    function handleNewIdPosition(id, newIndex) {
        const item = getItemById(id, idsToupleRef.current)
        const parentId = idsToupleRef.current.find((i) =>
            i[1].items?.find((ii) => ii === item),
        )?.[0]
        if (!parentId) {
            const newValue = [...value]
            const index = newValue.indexOf(item)
            newValue.splice(index, 1)
            newValue.splice(newIndex, 0, item)
            onChange(newValue)
        } else {
            const parent = getItemById(parentId, idsToupleRef.current)
            const index = parent.items.indexOf(item)
            parent.items.splice(index, 1)
            parent.items.splice(newIndex, 0, item)
            const newValue = [...value]
            onChange(newValue)
        }
    }

    return (
        <div className={`tree-input${errors?.length ? ' has-error' : ''}`}>
            {!titlessLevels &&
                segmentTypes.map((t, i) => (
                    <div key={i}>
                        <label htmlFor={`ttt-level-${i + 1}`}>
                            Level {i + 1} title
                        </label>
                        <TextInput
                            id={`ttt-level-${i + 1}`}
                            value={t}
                            onChange={(v) => handleTypeChange(v, i)}
                        />
                    </div>
                ))}

            <BlockStack gap={'sm'}>
                <DragArea
                    id={`tree-input-drag-root`}
                    onNewIdPosition={handleNewIdPosition}
                >
                    {value.map((item) => {
                        const id = getIdByItem(item, idsToupleRef.current)
                        return (
                            <div
                                className="added-item"
                                // eslint-disable-next-line react/no-unknown-property
                                dragid={id}
                                key={id}
                            >
                                <Item
                                    value={value}
                                    onChange={onChange}
                                    additionalFields={additionalFields}
                                    item={item}
                                    id={id}
                                    openAddModal={openAddModal}
                                    handleNewIdPosition={handleNewIdPosition}
                                    openEditModal={openEditModal}
                                    segmentTypes={segmentTypes}
                                    level={0}
                                    handleRemove={handleRemove}
                                    idsTouple={idsToupleRef.current}
                                />
                            </div>
                        )
                    })}
                </DragArea>
                {segmentTypes.filter(Boolean).length > 0 && (
                    <Button
                        text={`Add ${segmentTypes[0]}`}
                        icon={ICONS.PLUS_ACTIVE}
                        outline
                        tiny
                        onClick={() => openAddModal(null, 0)}
                    />
                )}
            </BlockStack>
        </div>
    )
}
