import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import PrivateFile from '../../../components/common/PrivateFile'
import {
    copyToClipboard,
    formatDateTime,
    getHoursAndMinutesFromMinutes,
    getNextStatuses,
    getTaskPriorityClass,
    getUserDisplayName,
} from '../../../common/helpers'
import Button from '../../../components/common/Button'
import InputModalContent from '../../../components/common/InputModalContent'
import useData from '../../../common/hooks/useData'
import useAuth from '../../../common/hooks/useAuth'
import useModal from '../../../common/hooks/useModal'
import {
    ICONS,
    TASK_ACTION_TEXT,
    TASK_STATUSES,
    TASK_STATUS_LABELS,
} from '../../../common/constants'
import AcceptCostRequestTaskDetails from './AcceptCostRequestTaskDetails'
import MainButton from '../../../components/admin/MainButton'
import Toggle from '../../../components/common/Toggle'
import Tag from '../../../components/common/Tag'
import Comments from '../../../components/common/Comments'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Timeline from '../../../components/common/Timeline'
import useNotifs from '../../../common/hooks/useNotifs'
import MessageSection from '../../../components/common/MessageSection'
import InlineStack from '../../../components/common/InlineStack'
import EditTaskAttachments from './EditTaskAttachments'
import TextInput from '../../../components/common/data-form/TextInput'
import FileInput from '../../../components/common/data-form/FileInput'
import Preformatted from '../../../components/common/Preformatted'
import BlockStack from '../../../components/common/BlockStack'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import useError from '../../../common/hooks/useError'
import ContactCard from '../clients/ContactCard'
import ImageModalContent from '../../../components/common/ImageModalContent'
import Image from '../../../components/common/Image'

function TaskPage() {
    const { taskId, userId } = useParams()

    const navigate = useNavigate()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const [error, setError, errorFields] = useError('')

    const [acceptLoading, setAcceptLoading] = useState(false)
    const [taskActionLoading, setTaskActionLoading] = useState(false)
    const [_clientReviewLoading, setClientReviewLoading] = useState(false)
    const [approveAssistantLoading, setApproveAssistantLoading] =
        useState(false)

    const [isQuoteOpen, setIsQuoteOpen] = useState(false)
    const [reviewFieldsOpen, setReviewFieldsOpen] = useState(false)
    const [requirementData, setRequirementData] = useState({})
    const [submitForReviewLoading, setSubmitForReviewLoading] = useState(false)
    const [timeQuoteData, setTimeQuoteData] = useState({
        hours: 0,
        minutes: 0,
    })
    const [saveTimeQuoteLoading, setSaveTimeQuoteLoading] = useState(false)
    const [resubmitCreationLoading, setResubmitCreationLoading] =
        useState(false)

    const { task, taskError, taskLoading, taskMutate } = useData(
        `/v1/tasks/${taskId}`,
        'task',
        (data) => data,
        false,
        false,
        (data) => {
            setTimeQuoteData({
                hours: getHoursAndMinutesFromMinutes(
                    data?.timeQuoteMinutes || 0,
                ).hours,
                minutes: getHoursAndMinutesFromMinutes(
                    data?.timeQuoteMinutes || 0,
                ).minutes,
            })
            setRequirementData({
                text: data?.submissionFields?.text,
                link: data?.submissionFields?.link,
                // imageFileId: data?.submissionFields?.imageFileId,
                // otherFileId: data?.submissionFields?.otherFileId,
                imageFileIds: [
                    ...(data?.submissionFields?.imageFileIds || []),
                    ...(data?.submissionFields?.imageFileId
                        ? [data.submissionFields.imageFileId]
                        : []),
                ],
                otherFileIds: [
                    ...(data?.submissionFields?.otherFileIds || []),
                    ...(data?.submissionFields?.otherFileId
                        ? [data.submissionFields.otherFileId]
                        : []),
                ],
                requirementImages: [],
                requirementFiles: [],
            })
        },
    )

    const notifs = useNotifs(false, {
        notifType: 'commentAdded',
        field1: 'task',
        field2: taskId,
    })

    const taskUserId =
        userId ||
        (task?.target === 'client'
            ? task?.clientId
            : task?.assignedTeamMemberId)

    const hasBugTag = task?.tags?.map((t) => t.toLowerCase()).includes('bug')
    // const hasClientTag = task?.tags
    //     ?.map((t) => t.toLowerCase())
    //     .includes('client')

    async function toggleBug(e) {
        e.preventDefault()
        e.stopPropagation()
        const url = `/v1/tasks/${task.id}`
        const body = {
            tags: hasBugTag
                ? task.tags.filter((t) => t.toLowerCase() !== 'bug')
                : [...task.tags, 'Bug'],
        }
        const { error } = await fetchAPI(url, body, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        taskMutate()
    }

    // async function toggleClient(e) {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     const url = `/v1/tasks/${task.id}`
    //     const body = {
    //         tags: hasClientTag
    //             ? task.tags.filter((t) => t.toLowerCase() !== 'client')
    //             : [...task.tags, 'Client'],
    //     }
    //     const { error } = await fetchAPI(url, body, 'PATCH')
    //     if (error) {
    //         setToast(error, 'alert')
    //         return
    //     }
    //     setToast('Saved')
    //     taskMutate()
    // }

    async function setTaskStatus(status) {
        const url = `/v1/tasks/${taskId}`
        const { error } = await fetchAPI(url, { status }, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        taskMutate()
    }

    async function onToggleComplete() {
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                status:
                    task.status === TASK_STATUSES.COMPLETE
                        ? TASK_STATUSES.OPEN
                        : TASK_STATUSES.COMPLETE,
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        taskMutate({ ...task, ...responseData })
    }

    async function onChangeClientReviewStatus(v) {
        setClientReviewLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            { clientReviewStatus: v },
            'PATCH',
        )
        setClientReviewLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        taskMutate({ ...task, ...responseData })
    }

    async function onChangePriority(priority) {
        setClientReviewLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            { priority },
            'PATCH',
        )
        setClientReviewLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Saved')
        taskMutate({ ...task, ...responseData })
    }

    function onDeny() {
        setModal(
            <InputModalContent
                label="Rejection message"
                confirmText="Submit"
                onConfirm={async ({ text }, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/tasks/${taskId}`
                    const { responseData, error, meta } = await fetchAPI(
                        url,
                        {
                            status: TASK_STATUSES.DENIED,
                            denialMessage: text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error, meta?.fields?.length ? [true] : [])
                        return
                    }
                    setModal(null)
                    setToast('Rejected')
                    taskMutate({ ...task, ...responseData })
                }}
            />,
            'Rejection message',
        )
    }

    async function onAccept() {
        setAcceptLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                status: TASK_STATUSES.ACCEPTED,
            },
            'PATCH',
        )
        setAcceptLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Accepted')
        taskMutate({ ...task, ...responseData })
    }

    async function onClientApprove() {
        setAcceptLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                clientReviewStatus: 'approved',
            },
            'PATCH',
        )
        setAcceptLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Approved')
        taskMutate({ ...task, ...responseData })
        navigate('/')
    }

    async function onClientReject() {
        setAcceptLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                clientReviewStatus: 'rejected',
            },
            'PATCH',
        )
        setAcceptLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Rejected')
        taskMutate({ ...task, ...responseData })
        navigate('/')
    }

    async function onApproveAssistant() {
        setApproveAssistantLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                status: TASK_STATUSES.OPEN,
            },
            'PATCH',
        )
        setApproveAssistantLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Approved new task')
        taskMutate({ ...task, ...responseData })
    }

    function onRejectAssistant() {
        setModal(
            <InputModalContent
                label="Rejection message"
                confirmText="Submit"
                onConfirm={async ({ text }, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/tasks/${taskId}`
                    const { responseData, error, meta } = await fetchAPI(
                        url,
                        {
                            status: TASK_STATUSES.RECREATION,
                            recreationMessage: text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error, meta?.fields?.length ? [true] : [])
                        return
                    }
                    setModal(null)
                    setToast('Rejected')
                    taskMutate({ ...task, ...responseData })
                }}
            />,
            'Rejection message',
        )
    }

    async function onResubmitCreation() {
        setResubmitCreationLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                status: TASK_STATUSES.APPROVAL,
            },
            'PATCH',
        )
        setResubmitCreationLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Resubmitted')
        taskMutate({ ...task, ...responseData })
    }

    function onDelete() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/tasks/${taskId}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    let redirectUrl = '/'
                    if (task.target === 'client') {
                        redirectUrl = `/users/${task.client.id}`
                    } else if (task.target === 'team') {
                        redirectUrl = `/users/${task.assignedTeamMember.id}`
                    }
                    navigate(redirectUrl)
                }}
            />,
            'Are you sure you want to delete this task?',
        )
    }

    async function onTaskAction(action) {
        setTaskActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/tasks/${taskId}/actions/${action}`,
            {},
            'POST',
        )
        setTaskActionLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Done')
        taskMutate()
        navigate(-1)
    }

    async function onSubmitForReview(e) {
        e.preventDefault()

        const MAX_FILE_SIZE = 10 * 1024 * 1024

        for (const file of requirementData.requirementImages || []) {
            if (file.size > MAX_FILE_SIZE) {
                setError(`Max file size is 3MB.`, ['requirementImagesFiles'])
                return
            }
        }
        for (const file of requirementData.requirementFiles || []) {
            if (file.size > MAX_FILE_SIZE) {
                setError(`Max file size is 3MB.`, ['requirementImagesFiles'])
                return
            }
        }

        const formData = new FormData()

        for (
            let i = 0;
            i < (requirementData.requirementImages?.length || 0);
            i++
        ) {
            formData.append(
                `files[requirement_image_${i}]`,
                requirementData.requirementImages[i],
            )
        }

        for (
            let i = 0;
            i < (requirementData.requirementFiles?.length || 0);
            i++
        ) {
            formData.append(
                `files[requirement_file_${i}]`,
                requirementData.requirementFiles[i],
            )
        }

        const bodyData = { submissionFields: requirementData }
        delete bodyData.submissionFields.requirementImages
        delete bodyData.submissionFields.requirementFiles

        bodyData.status = TASK_STATUSES.REVIEW

        formData.append('data', JSON.stringify(bodyData))

        setSubmitForReviewLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error, meta } = await fetchAPI(
            url,
            formData,
            'PATCH',
            {},
        )
        setSubmitForReviewLoading(false)

        if (error) {
            setError(error, meta?.fields)
            return
        }

        setToast('Saved')
        taskMutate({ ...task, ...responseData })

        setRequirementData({
            //
            ...requirementData,
            requirementImages: [],
            requirementFiles: [],
        })
    }

    async function onSaveTimeQuote(e) {
        e.preventDefault()

        if (
            timeQuoteData.hours < 0 ||
            timeQuoteData.minutes < 0 ||
            timeQuoteData.minutes > 59
        ) {
            setToast('Invalid time quote', 'alert')
            return
        }

        setSaveTimeQuoteLoading(true)
        const url = `/v1/tasks/${taskId}`
        const { responseData, error } = await fetchAPI(
            url,
            {
                timeQuoteMinutes:
                    timeQuoteData.hours * 60 + timeQuoteData.minutes,
            },
            'PATCH',
        )
        setSaveTimeQuoteLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Saved')
        taskMutate({ ...task, ...responseData })
        setIsQuoteOpen(false)
    }

    if (taskLoading) return <Spinner marginTop />
    if (taskError) {
        return <ErrorMessage section>{taskError}</ErrorMessage>
    }

    const isAppTask = task.taskType !== 'USER_GENERATED'
    let actionDetailsHtmlParts = []
    if (isAppTask && task.taskActions.includes('ACCEPT_COST_REQUEST')) {
        actionDetailsHtmlParts.push(
            <AcceptCostRequestTaskDetails taskMeta={task.taskMeta} />,
        )
    }

    const nextStatuses = getNextStatuses(task.status, auth.user.role)

    let clientReviewStatusColor = null
    let clientReviewStatusText = null
    switch (task.clientReviewStatus) {
        case 'submitted':
            clientReviewStatusColor = 'warning'
            clientReviewStatusText = 'Client reviewing'
            break
        case 'rejected':
            clientReviewStatusColor = 'alert'
            clientReviewStatusText = 'Client rejected'
            break
        case 'approved':
            clientReviewStatusColor = 'success'
            clientReviewStatusText = 'Client approved'
            break
        default:
            clientReviewStatusColor = 'outline'
            clientReviewStatusText = 'None'
            break
    }

    let title = task.title
    if (hasBugTag && !task.title.toLowerCase().startsWith('bug:')) {
        title = (
            <>
                <span className="text-subdued">Bug:&nbsp;</span>
                {task.title}
            </>
        )
    }
    // if (hasClientTag && !task.title.toLowerCase().startsWith('client:')) {
    //     title = (
    //         <>
    //             <span className="text-subdued">Client task:&nbsp;</span>
    //             {task.title}
    //         </>
    //     )
    // }

    const assignedHtml =
        (auth.isAdmin || auth.isStaff) &&
        ['team', 'role'].includes(task.target) &&
        task.assignedTeamMember ? (
            <BlockStack gap={'tiny'}>
                <div className="small text-subdued">{`Assigned team member`}</div>
                <ContactCard withDetails user={task.assignedTeamMember} />
            </BlockStack>
        ) : null

    const creatorHtml =
        !auth.isClient && task.creator ? (
            <small className="text-subdued">
                {`Created by: ${getUserDisplayName(
                    task.creator,
                )} on ${formatDateTime(task.createdAt, true)}`}
            </small>
        ) : null

    const linkedClientHtml =
        !auth.isClient && task.clientId && task.target !== 'client' ? (
            <BlockStack gap={'tiny'}>
                <div className="small text-subdued">{`Client`}</div>
                <ContactCard withDetails user={task.client} />
            </BlockStack>
        ) : null

    return (
        <>
            <SectionContainer>
                <header className="info-header">
                    <BlockStack gap="sm">
                        {isAppTask ? (
                            <Tag square outline>
                                APP
                            </Tag>
                        ) : null}

                        <InlineStack spaceBetween gap="sm">
                            <h2>{title}</h2>
                            <ActionsDropdown
                                actions={[
                                    {
                                        title: 'Complete',
                                        onClick: () => onToggleComplete(),
                                        hide:
                                            !auth.isAdmin ||
                                            task.status ===
                                                TASK_STATUSES.COMPLETE ||
                                            isAppTask,
                                        icon: ICONS.CHECK_GRAY,
                                    },

                                    {
                                        title: !task.clientReviewStatus
                                            ? 'Send to client for review'
                                            : 'Retract client review',
                                        onClick: () =>
                                            onChangeClientReviewStatus(
                                                !task.clientReviewStatus
                                                    ? 'submitted'
                                                    : '',
                                            ),
                                        hide:
                                            (!auth.isAdmin &&
                                                !auth.isAssistant) ||
                                            !task.clientId ||
                                            task.target === 'client' ||
                                            task.status !==
                                                TASK_STATUSES.REVIEW ||
                                            isAppTask,
                                        icon: !task.clientReviewStatus
                                            ? ICONS.EYE_GRAY
                                            : ICONS.EYE_OFF_GRAY,
                                    },
                                    {
                                        title: 'Edit',
                                        onClick: () =>
                                            navigate(
                                                task.target === 'role'
                                                    ? `/tasks/${taskId}/edit`
                                                    : `/users/${taskUserId}/tasks/${taskId}/edit`,
                                            ),
                                        hide:
                                            !(
                                                auth.isAdmin ||
                                                auth.isAssistant ||
                                                (auth.isStaff &&
                                                    auth.user.id ===
                                                        task.creatorId &&
                                                    [
                                                        TASK_STATUSES.APPROVAL,
                                                        TASK_STATUSES.RECREATION,
                                                    ].includes(task.status))
                                            ) || isAppTask,
                                        icon: ICONS.EDIT_GRAY,
                                    },
                                    {
                                        title: 'Delete',
                                        onClick: () => onDelete(),
                                        hide:
                                            !auth.isAdmin && !auth.isAssistant,
                                        type: 'alert',
                                        icon: ICONS.TRASH_RED,
                                    },
                                ]}
                            />
                        </InlineStack>
                    </BlockStack>
                </header>
            </SectionContainer>

            <SectionContainer>
                <BlockStack gap={'sm'}>
                    {Boolean(notifs?.notifs?.length) && (
                        <div>
                            <a href="#comments" className="alert">
                                <strong>
                                    {`See ${notifs.notifs.length} new ${
                                        notifs.notifs.length === 1
                                            ? 'comment'
                                            : 'comments'
                                    }
                                `}
                                </strong>
                            </a>
                            <br />
                        </div>
                    )}

                    <BlockStack gap={'tiny'} itemsStart>
                        <div className="text-subdued small">Priority</div>
                        <div>
                            <Tag outline>{task.order}</Tag>{' '}
                            {task.assignedTeamMemberId &&
                                (auth.isAdmin ||
                                    auth.isStaff ||
                                    auth.user.id ===
                                        task.assignedTeamMemberId) && (
                                    <Tag outline>User: {task.userOrder}</Tag>
                                )}
                        </div>
                    </BlockStack>

                    {!isAppTask && (
                        <BlockStack gap={'sm'}>
                            {task.target === 'client' ? (
                                <BlockStack gap={'tiny'}>
                                    <div className="small text-subdued">{`Homework for client`}</div>
                                    {task.client ? (
                                        <ContactCard
                                            withDetails
                                            user={task.client}
                                        />
                                    ) : null}
                                </BlockStack>
                            ) : (
                                <BlockStack gap={'tiny'}>
                                    <div className="">{assignedHtml}</div>
                                    <div className="">{linkedClientHtml}</div>
                                    <div className="">{creatorHtml}</div>
                                </BlockStack>
                            )}

                            <div>
                                {(auth.isAdmin || auth.isAssistant) &&
                                    task.target === 'role' &&
                                    task.teamRoles.map((role) => (
                                        <Tag key={role} outline>
                                            {role} team
                                        </Tag>
                                    ))}
                                {(auth.isAdmin || auth.isAssistant) &&
                                    task.target === 'team' && (
                                        <Tag outline>Team Member Task</Tag>
                                    )}

                                <Tag
                                    {...(nextStatuses?.length
                                        ? {
                                              onChange: (v) => setTaskStatus(v),
                                              options: Object.entries(
                                                  TASK_STATUS_LABELS,
                                              )
                                                  .filter(([k]) =>
                                                      nextStatuses.includes(k),
                                                  )
                                                  .map(([k, v]) => ({
                                                      label: v.label,
                                                      color: v.tagColor,
                                                      value: k,
                                                  })),
                                          }
                                        : {})}
                                    color={
                                        TASK_STATUS_LABELS[task.status].tagColor
                                    }
                                    initValue={task.status}
                                >
                                    {TASK_STATUS_LABELS[task.status].label}
                                </Tag>

                                {auth.isAdmin || auth.isAssistant ? (
                                    <Tag
                                        onChange={(v) =>
                                            onChangeClientReviewStatus(v)
                                        }
                                        initValue={
                                            task.clientReviewStatus || ''
                                        }
                                        options={[
                                            {
                                                label: 'Client reviewing',
                                                color: 'warning',
                                                value: 'submitted',
                                            },
                                            {
                                                label: 'Client rejected',
                                                color: 'alert',
                                                value: 'rejected',
                                            },
                                            {
                                                label: 'Client approved',
                                                color: 'success',
                                                value: 'approved',
                                            },
                                        ]}
                                        color={clientReviewStatusColor}
                                    >
                                        {clientReviewStatusText}
                                    </Tag>
                                ) : (
                                    task.status === TASK_STATUSES.REVIEW ||
                                    (!auth.isClient &&
                                        [
                                            TASK_STATUSES.COMPLETE,
                                            TASK_STATUSES.REVIEW,
                                        ].includes(task.status) && (
                                            <Tag
                                                color={clientReviewStatusColor}
                                            >
                                                {clientReviewStatusText}
                                            </Tag>
                                        ))
                                )}

                                {
                                    <Tag
                                        outline
                                        onChange={(v) => onChangePriority(v)}
                                        initValue={task.priority}
                                        options={
                                            !auth.isAdmin
                                                ? []
                                                : ['high', 'medium', 'low']
                                                      .filter(
                                                          (p) =>
                                                              p !==
                                                              task.priority,
                                                      )
                                                      .map((p) => ({
                                                          label: p,
                                                          value: p,
                                                          color: `${getTaskPriorityClass(
                                                              p,
                                                          )}`,
                                                      }))
                                        }
                                        color={`${getTaskPriorityClass(task.priority)}`}
                                    >
                                        {task.priority}
                                    </Tag>
                                }
                            </div>

                            <InlineStack gap={'sm'}>
                                {Array.isArray(task.tags) &&
                                    (auth.isAdmin ? (
                                        <img
                                            onClick={toggleBug}
                                            role="button"
                                            src={
                                                hasBugTag
                                                    ? ICONS.BUG_ACTIVE
                                                    : ICONS.BUG_GRAY
                                            }
                                            width={24}
                                            height={24}
                                            alt={
                                                hasBugTag
                                                    ? 'Disable bug'
                                                    : 'Enable bug'
                                            }
                                        />
                                    ) : hasBugTag ? (
                                        <img
                                            src={ICONS.BUG_ACTIVE}
                                            width={24}
                                            height={24}
                                            alt={'Bug'}
                                        />
                                    ) : null)}

                                {task.tags?.length ? (
                                    <div>
                                        {task.tags
                                            .filter(
                                                (t) =>
                                                    t.toLowerCase() !== 'bug' &&
                                                    t.toLowerCase() !==
                                                        'client',
                                            )
                                            .map((tag, i) => (
                                                <Tag
                                                    key={i}
                                                    square
                                                    outline
                                                    href={
                                                        task.assignedTeamMemberId &&
                                                        !auth.isClient
                                                            ? `/users/${task.assignedTeamMemberId}?tag_task_list=${tag}#tag-tasks`
                                                            : undefined
                                                    }
                                                >
                                                    {tag}
                                                </Tag>
                                            ))}
                                    </div>
                                ) : null}
                            </InlineStack>

                            <div>
                                <Button
                                    outline
                                    tiny
                                    alignRight
                                    text={'Copy URL'}
                                    icon={ICONS.COPY_ACTIVE}
                                    onClick={() => {
                                        copyToClipboard(
                                            `${process.env.REACT_APP_PUBLIC_URL}/tasks/${taskId}`,
                                        )
                                        setToast('Copied to clipboard')
                                    }}
                                />
                            </div>
                        </BlockStack>
                    )}
                </BlockStack>
            </SectionContainer>

            {error && (
                <ErrorMessage section onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            {auth.isAdmin && (
                <SectionContainer card>
                    <h3>Attachments</h3>
                    <div>
                        <EditTaskAttachments />
                    </div>
                </SectionContainer>
            )}

            {['team', 'role'].includes(task.target) &&
                auth.isClient &&
                auth.user.id === task.clientId &&
                task.clientReviewStatus === 'submitted' &&
                Object.values(task.submissionRequirements || {}).some(
                    (v) => v,
                ) && (
                    <SectionContainer card>
                        <h3>Submission</h3>
                        <div>
                            <div>
                                {task.submissionRequirements?.text ? (
                                    <>
                                        <h4>Text</h4>
                                        <pre>
                                            {task.submissionFields?.text?.replace(
                                                /%0A/g,
                                                '\n',
                                            ) || 'No text'}
                                        </pre>
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.link ? (
                                    <>
                                        <h4>Link</h4>
                                        <pre>
                                            {task.submissionFields?.link ||
                                                'No link'}
                                        </pre>
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.otherFileId ? (
                                    <>
                                        <h4>File</h4>
                                        {task.submissionFields?.otherFileId
                                            ?.length ? (
                                            <div className="img-grid">
                                                {task.submissionFields.otherFileIds.map(
                                                    (id) => (
                                                        <PrivateFile
                                                            key={id}
                                                            openFullOnClick
                                                            driveFileId={id}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        ) : (
                                            'No file'
                                        )}
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.imageFileId ? (
                                    <>
                                        <h4>Image</h4>
                                        {task.submissionFields?.imageFileIds
                                            ?.length ? (
                                            <div className="img-grid">
                                                {task.submissionFields.imageFileIds.map(
                                                    (id) => (
                                                        <PrivateFile
                                                            key={id}
                                                            openFullOnClick
                                                            driveFileId={id}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        ) : (
                                            'No file'
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </SectionContainer>
                )}

            <SectionContainer>
                {isAppTask && task.taskActions?.length && (
                    <Button
                        text={TASK_ACTION_TEXT[task.taskActions[0]]}
                        onClick={() => onTaskAction(task.taskActions[0])}
                        isLoading={taskActionLoading}
                    />
                )}

                {[TASK_STATUSES.REJECTED, TASK_STATUSES.REVIEW].includes(
                    task.status,
                ) &&
                    !!task.rejectionMessage && (
                        <MessageSection type="warning">
                            Review notes:
                            <Break />
                            {task.rejectionMessage}
                        </MessageSection>
                    )}
                {task.status === TASK_STATUSES.DENIED &&
                    !!task.denialMessage && (
                        <MessageSection type="warning">
                            Rejection message:
                            <Break />
                            {task.denialMessage}
                        </MessageSection>
                    )}
                {(task.status === TASK_STATUSES.RECREATION ||
                    task.status === TASK_STATUSES.APPROVAL) &&
                    !!task.recreationMessage && (
                        <MessageSection type="warning">
                            Admin message:
                            <Break />
                            {task.recreationMessage}
                        </MessageSection>
                    )}

                {(auth.isAdmin || auth.isAssistant) &&
                    task.status === TASK_STATUSES.REVIEW && (
                        <>
                            <Break />
                            <Button
                                text="Complete"
                                onClick={() => onToggleComplete()}
                                isLoading={acceptLoading}
                            />
                            <Button
                                destructive
                                text="Reject"
                                onClick={() =>
                                    navigate(`/tasks/${taskId}/reject`)
                                }
                            />
                        </>
                    )}
                {auth.isAdmin && task.status === TASK_STATUSES.APPROVAL && (
                    <>
                        <Break />
                        <Button
                            text="Approve and create"
                            onClick={() => onApproveAssistant()}
                            isLoading={approveAssistantLoading}
                        />
                        <Button
                            destructive
                            text="Reject creation"
                            onClick={() => onRejectAssistant()}
                        />
                    </>
                )}
                {auth.isStaff && task.status === TASK_STATUSES.RECREATION && (
                    <>
                        <Break />
                        <Button
                            text="Re-submit to admin"
                            onClick={() => onResubmitCreation()}
                            isLoading={resubmitCreationLoading}
                        />
                    </>
                )}
                {((['team', 'role'].includes(task.target) &&
                    auth.isStaff &&
                    task.assignedTeamMemberId === auth.user.id) ||
                    (task.target === 'client' && auth.isClient)) &&
                    task.status === TASK_STATUSES.OPEN && (
                        <>
                            <Break />
                            <Button
                                text="Accept"
                                onClick={() => onAccept()}
                                isLoading={acceptLoading}
                            />
                            <Button
                                destructive
                                text="Reject"
                                onClick={() => onDeny()}
                            />
                        </>
                    )}
                {['team', 'role'].includes(task.target) &&
                    auth.isClient &&
                    task.status === TASK_STATUSES.REVIEW &&
                    task.clientReviewStatus === 'submitted' &&
                    task.clientId === auth.user.id && (
                        <>
                            <Break />
                            <Button
                                text="Approve"
                                onClick={() => onClientApprove()}
                                isLoading={acceptLoading}
                            />
                            <Button
                                destructive
                                text="Reject"
                                onClick={() => onClientReject()}
                                isLoading={acceptLoading}
                            />
                        </>
                    )}
                {((['team', 'role'].includes(task.target) &&
                    auth.isStaff &&
                    !reviewFieldsOpen &&
                    task.assignedTeamMemberId === auth.user.id) ||
                    (task.target === 'client' && auth.isClient)) &&
                    [TASK_STATUSES.ACCEPTED, TASK_STATUSES.REJECTED].includes(
                        task.status,
                    ) && (
                        <>
                            <Break />
                            <Button
                                text="Ready for review"
                                onClick={() => setReviewFieldsOpen(true)}
                                // onClick={() => onToggleReview()}
                            />
                        </>
                    )}
            </SectionContainer>

            {isAppTask && actionDetailsHtmlParts.length ? (
                <SectionContainer card>
                    <h3>Action details</h3>
                    <div>
                        {actionDetailsHtmlParts.map((htmlPart, i) => (
                            <div key={i}>{htmlPart}</div>
                        ))}
                    </div>
                </SectionContainer>
            ) : null}

            {!isAppTask &&
                reviewFieldsOpen &&
                ['team', 'role'].includes(task.target) &&
                task.assignedTeamMemberId === auth.user.id &&
                [TASK_STATUSES.ACCEPTED, TASK_STATUSES.REJECTED].includes(
                    task.status,
                ) && (
                    <SectionContainer card>
                        {Object.values(task.submissionRequirements || {}).some(
                            (v) => v,
                        ) ? (
                            <>
                                <h3>Completion requirements</h3>
                                <div>
                                    <small>
                                        The following information is required to
                                        mark this task as ready for review.
                                    </small>
                                    <br />
                                    <br />
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        {task.submissionRequirements.text && (
                                            <>
                                                <label>
                                                    What did you do to complete
                                                    the Task?
                                                </label>
                                                <TextInput
                                                    type="textarea"
                                                    errors={errorFields.filter(
                                                        (e) =>
                                                            e ===
                                                            'submissionFields_text',
                                                    )}
                                                    onChange={(v) =>
                                                        setRequirementData({
                                                            ...requirementData,
                                                            text: v.replace(
                                                                /\n/g,
                                                                '%0A',
                                                            ),
                                                        })
                                                    }
                                                    value={
                                                        requirementData.text?.replace(
                                                            /%0A/g,
                                                            '\n',
                                                        ) || ''
                                                    }
                                                />
                                            </>
                                        )}
                                        {task.submissionRequirements.link && (
                                            <>
                                                <label>Required link</label>
                                                <TextInput
                                                    errors={errorFields.filter(
                                                        (e) =>
                                                            e ===
                                                            'submissionFields_text',
                                                    )}
                                                    type="text"
                                                    onChange={(v) =>
                                                        setRequirementData({
                                                            ...requirementData,
                                                            link: v,
                                                        })
                                                    }
                                                    value={
                                                        requirementData.link ||
                                                        ''
                                                    }
                                                />
                                            </>
                                        )}
                                        {task.submissionRequirements
                                            .imageFileId && (
                                            <>
                                                <label htmlFor="form-requirement-images">
                                                    Upload at least one
                                                    screenshot of the work you
                                                    did
                                                </label>
                                                {task.submissionFields
                                                    ?.imageFileIds.length ? (
                                                    <>
                                                        <Toggle
                                                            open
                                                            small
                                                            title="Submission 1"
                                                        >
                                                            <div className="img-grid">
                                                                {task.submissionFields.imageFileIds.map(
                                                                    (id) => (
                                                                        <PrivateFile
                                                                            key={
                                                                                id
                                                                            }
                                                                            openFullOnClick
                                                                            driveFileId={
                                                                                id
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                                {/* <PrivateFile
                                                                openFullOnClick
                                                                driveFileId={
                                                                    task
                                                                        .submissionFields
                                                                        .imageFileId
                                                                }
                                                            /> */}
                                                            </div>
                                                        </Toggle>
                                                        <br />
                                                    </>
                                                ) : null}
                                                <FileInput
                                                    onChange={(files) =>
                                                        setRequirementData({
                                                            ...requirementData,
                                                            requirementImages:
                                                                files,
                                                        })
                                                    }
                                                    id="form-requirement-images"
                                                    multiple
                                                    errors={errorFields.filter(
                                                        (e) =>
                                                            e ===
                                                            'requirementImagesFiles',
                                                    )}
                                                />
                                            </>
                                        )}
                                        {task.submissionRequirements
                                            .otherFileId && (
                                            <>
                                                <label htmlFor="form-requirement-files">
                                                    Required file
                                                </label>
                                                {task.submissionFields
                                                    ?.otherFileIds?.length ? (
                                                    <>
                                                        <div className="img-grid">
                                                            {task.submissionFields.otherFileIds.map(
                                                                (id) => (
                                                                    <PrivateFile
                                                                        key={id}
                                                                        openFullOnClick
                                                                        driveFileId={
                                                                            id
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                        </div>
                                                        <br />
                                                    </>
                                                ) : null}

                                                <FileInput
                                                    setFiles={(files) =>
                                                        setRequirementData({
                                                            ...requirementData,
                                                            requirementFiles:
                                                                files,
                                                        })
                                                    }
                                                    id="form-requirement-files"
                                                    multiple
                                                    errors={errorFields.filter(
                                                        (e) =>
                                                            e ===
                                                            'requirementImagesFiles',
                                                    )}
                                                />
                                            </>
                                        )}

                                        <Button
                                            type="submit"
                                            text="Submit for review"
                                            isLoading={submitForReviewLoading}
                                            onClick={onSubmitForReview}
                                        />
                                    </form>
                                </div>
                            </>
                        ) : (
                            <div>
                                <Button
                                    type="submit"
                                    text="Submit for review"
                                    isLoading={submitForReviewLoading}
                                    onClick={onSubmitForReview}
                                />
                            </div>
                        )}
                    </SectionContainer>
                )}

            {['team', 'role'].includes(task.target) &&
                (auth.isAdmin || auth.user.id === task.assignedTeamMemberId) &&
                Object.values(task.submissionRequirements || {}).some(
                    (v) => v,
                ) && (
                    <SectionContainer card>
                        <h3>Completion requirements</h3>
                        <div>
                            <div>
                                {task.submissionRequirements?.text ? (
                                    <>
                                        <h4>Text</h4>
                                        <pre>
                                            {task.submissionFields?.text?.replace(
                                                /%0A/g,
                                                '\n',
                                            ) || 'No text'}
                                        </pre>
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.link ? (
                                    <>
                                        <h4>Link</h4>
                                        <pre>
                                            {task.submissionFields?.link ||
                                                'No link'}
                                        </pre>
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.otherFileId ? (
                                    <>
                                        <h4>File</h4>
                                        {task.submissionFields?.otherFileId
                                            ?.length ? (
                                            <div className="img-grid">
                                                {task.submissionFields.otherFileIds.map(
                                                    (id) => (
                                                        <PrivateFile
                                                            key={id}
                                                            openFullOnClick
                                                            driveFileId={id}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        ) : (
                                            'No file'
                                        )}
                                    </>
                                ) : null}
                            </div>
                            <div>
                                {task.submissionRequirements?.imageFileId ? (
                                    <>
                                        <h4>Image</h4>
                                        {task.submissionFields?.imageFileIds
                                            ?.length ? (
                                            <div className="img-grid">
                                                {task.submissionFields.imageFileIds.map(
                                                    (id) => (
                                                        <PrivateFile
                                                            key={id}
                                                            openFullOnClick
                                                            driveFileId={id}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        ) : (
                                            'No file'
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </SectionContainer>
                )}

            <SectionContainer card>
                <h3>Description</h3>
                <div className="images-container">
                    {Boolean(task.descriptionHtml) && (
                        <>
                            {!!task.descriptionSassCode && (
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: task.cssCode
                                            .replace(/&lt;/g, '<')
                                            .replace(/&gt;/g, '>')
                                            .replace(/“/g, '"')
                                            .replace(/”/g, '"'),
                                    }}
                                ></style>
                            )}
                            <Preformatted>
                                <div
                                    className={`task-description-${task.id}`}
                                    dangerouslySetInnerHTML={{
                                        __html: task.descriptionHtml,
                                    }}
                                ></div>
                            </Preformatted>
                        </>
                    )}
                    {!task.description &&
                        !task.descriptionHtml &&
                        'No description'}
                </div>
            </SectionContainer>

            {!auth.isAdmin && (
                <SectionContainer card>
                    <h3>Attachments</h3>
                    {[...task.attachmentFileIds, ...task.attachmentFileUrls]
                        .length ? (
                        <div className="img-grid">
                            {task.attachmentFileIds.map((id) => (
                                <PrivateFile
                                    key={id}
                                    openFullOnClick
                                    driveFileId={id}
                                />
                            ))}
                            {task.attachmentFileUrls.map((url) =>
                                url?.endsWith('.jpg') ||
                                url?.endsWith('.jpeg') ||
                                url?.endsWith('.png') ? (
                                    <Image
                                        key={url}
                                        className=""
                                        crossOrigin="Anonymous"
                                        src={url}
                                        onClick={() => {
                                            setModal(
                                                <ImageModalContent src={url} />,
                                            )
                                        }}
                                    />
                                ) : (
                                    <a
                                        href="#"
                                        key={url}
                                        className=""
                                        onClick={() => {
                                            const a =
                                                document.createElement('a')
                                            a.style = 'display: none'
                                            a.href = url
                                            a.download = url.split('/').pop()
                                            a.click()
                                        }}
                                    >
                                        {url.split('/').pop()}
                                    </a>
                                ),
                            )}
                        </div>
                    ) : (
                        <div>No attachments</div>
                    )}
                </SectionContainer>
            )}

            {task.status !== TASK_STATUSES.COMPLETE && (
                <SectionContainer>
                    <Toggle open small title="Time quote">
                        {isQuoteOpen ? (
                            <div>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <label htmlFor="form-quote-hours">
                                        Estimated hours
                                    </label>
                                    <TextInput
                                        type="number"
                                        id="form-quote-hours"
                                        step={1}
                                        min={0}
                                        onChange={(v) =>
                                            setTimeQuoteData({
                                                ...timeQuoteData,
                                                hours: Number(v),
                                            })
                                        }
                                        value={timeQuoteData.hours}
                                    />
                                    <label htmlFor="form-quote-minutes">
                                        Estimated minutes
                                    </label>
                                    <TextInput
                                        type="number"
                                        step={1}
                                        min={0}
                                        max={59}
                                        id="form-quote-minutes"
                                        onChange={(v) =>
                                            setTimeQuoteData({
                                                ...timeQuoteData,
                                                minutes: Number(v),
                                            })
                                        }
                                        value={timeQuoteData.minutes}
                                    />

                                    <Button
                                        type="submit"
                                        text="Save"
                                        isLoading={saveTimeQuoteLoading}
                                        onClick={onSaveTimeQuote}
                                    />
                                </form>
                            </div>
                        ) : (
                            <InlineStack itemsCenter spaceBetween>
                                <p>
                                    {
                                        getHoursAndMinutesFromMinutes(
                                            task.timeQuoteMinutes,
                                        ).hours
                                    }{' '}
                                    hours{' '}
                                    {
                                        getHoursAndMinutesFromMinutes(
                                            task.timeQuoteMinutes,
                                        ).minutes
                                    }{' '}
                                    minutes
                                </p>

                                <Button
                                    onClick={() => setIsQuoteOpen(true)}
                                    small
                                    outline
                                    icon={ICONS.EDIT_ACTIVE}
                                    text="Edit"
                                />
                            </InlineStack>
                        )}
                    </Toggle>
                </SectionContainer>
            )}

            <SectionContainer>
                <Comments
                    notifs={notifs?.notifs || []}
                    url={`/tasks/${taskId}/comments`}
                />
            </SectionContainer>

            <SectionContainer>
                <Timeline url={`/tasks/${taskId}/timeline`} />
            </SectionContainer>

            {(auth.isAdmin ||
                auth.isAssistant ||
                (auth.isStaff &&
                    auth.user.id === task.creatorId &&
                    [TASK_STATUSES.APPROVAL, TASK_STATUSES.RECREATION].includes(
                        task.status,
                    ))) &&
                !isAppTask && (
                    <MainButton
                        href={
                            task.target === 'role'
                                ? `/tasks/${taskId}/edit`
                                : `/users/${taskUserId}/tasks/${taskId}/edit`
                        }
                        functionality="EDIT"
                    />
                )}
        </>
    )
}

export default TaskPage
