import { useParams } from 'react-router-dom'
import {
    comparePrimitiveArrays,
    comparePrimitiveObjects,
    getUserDisplayName,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import DataFormNew from '../../../components/common/DataFormNew'
import { useUploads } from '../../../common/UploadContext'
import { API_URL } from '../../../common/constants'
import useToast from '../../../common/hooks/useToast'
import SectionContainer from '../../../components/common/SectionContainer'

const permissions = {
    user: {
        admin: {
            fields: 'firstName,lastName,email,onBlackList,isBlocked,teamRoles,role',
        },
        assistant: {
            fields: 'firstName,lastName,email,onBlackList,isBlocked,teamRoles,role',
        },
        sales: {
            fields: 'firstName,lastName,email,role',
        },
        collections: {
            fields: 'firstName,lastName,email,role',
        },
        staff: {
            fields: 'firstName,lastName,email,role',
        },
    },
}

function getExtraHasChanged(data, item) {
    return (
        data.attachmentFiles?.length > 0 ||
        !comparePrimitiveObjects(
            data?.requirements || {},
            item?.submissionRequirements || {},
        )
    )
}

function EditTask() {
    const { taskId } = useParams()
    const auth = useAuth()
    const { uploadFiles } = useUploads()
    const setToast = useToast()

    let fields = {}
    if (auth.isAdmin) {
        fields = permissions.user.admin.fields
    } else if (auth.isAssistant) {
        fields = permissions.user.assistant.fields
    } else if (auth.isSales) {
        fields = permissions.user.sales.fields
    } else if (auth.isCollections) {
        fields = permissions.user.collections.fields
    } else if (auth.isStaff) {
        fields = permissions.user.staff.fields
    }

    const inputs = [
        {
            key: 'order',
            type: 'number',
            label: 'Priority #',
            id: 'form-order',
            autoComplete: 'new-password',
        },

        {
            key: 'userOrder',
            type: 'number',
            label: 'User priority #',
            id: 'form-user-order',
            autoComplete: 'new-password',
        },
        {
            key: 'title',
            label: 'Title',
            id: 'form-title',
            autoComplete: 'new-password',
        },
        {
            key: 'descriptionHtml',
            label: 'Description',
            type: 'html',
            id: 'form-description',
            onEditorChange: (v, setOptsState) => {
                setOptsState((prev) => ({ ...prev, descriptionEditor: v }))
            },
        },
        {
            key: 'descriptionSassCode',
            label: 'Sass',
            type: 'textarea',
            rows: 16,
            shouldHide: (_data, _item, optsState) => {
                return optsState.descriptionEditor !== 'code'
            },
        },
        {
            key: 'assignedTeamMemberId',
            label: 'Assigned team member',
            type: 'liveSearch',
            shouldHide: (data) => !['team', 'role'].includes(data.target),
            getResultValue: (item) => getUserDisplayName(item),
            getItemsFromResults: (data) => data?.results || [],
            limit: 20,
            url: `/v1/users?isBlocked=false&role=staff&fields=${fields}`,
        },
        {
            key: 'clientId',
            label: 'Client',
            type: 'liveSearch',
            shouldHide: (data) => ['client'].includes(data.target),
            getResultValue: (item) => getUserDisplayName(item),
            getItemsFromResults: (data) => data?.results || [],
            limit: 20,
            url: `/v1/users?isBlocked=false&role=user&fields=firstName,lastName,email,role`,
        },
        {
            key: 'tags',
            type: 'liveSearchAddItems',
            label: 'Tags (beta)',
            canAddNewItem: true,
            url: '/v1/tags?resource=Task',
            getItemText: (item) => item,
            fields: [
                {
                    column: 1,
                    getValue: (item) => item,
                },
            ],
            infoBottom: 'Tags are visible to all users.',
        },
        {
            key: 'priority',
            type: 'select',
            label: 'Priority',
            id: 'form-priority',
            options: [
                {
                    value: 'low',
                    label: 'Low',
                },
                {
                    value: 'medium',
                    label: 'Medium',
                },
                {
                    value: 'high',
                    label: 'High',
                },
            ],
        },
        {
            shouldHide: (data) => !['role', 'team'].includes(data.target),
            key: 'requirements',
            type: 'select',
            label: 'Requirements',
            id: 'form-requirements',
            options: [
                {
                    value: 'none',
                    label: 'None',
                },
                {
                    value: 'text_link',
                    label: 'Text + Link',
                },
                {
                    value: 'text_image',
                    label: 'Text + Image',
                },
                {
                    value: 'text_file',
                    label: 'Text + File',
                },
            ],
        },
        // {
        //     shouldHide: (data) => !data.attachmentFileIds?.length,
        //     key: 'attachmentFileIds',
        //     type: 'driveFiles',
        //     label: 'Remove attachments',
        //     id: 'form-attachment-file-ids',
        // },
        {
            shouldHide: (data) =>
                !['role', 'team', 'client'].includes(data.target),
            key: 'attachmentFiles',
            type: 'file',
            label: 'Upload attachments',
            id: 'form-attachment-browser',
            multiple: true,
            infoBottom: 'Add attachments from device.',
        },
        {
            key: 'target',
            type: 'hidden',
        },
    ]

    function mapItemToData(item) {
        const newData = {
            title: item.title,
            descriptionHtml: item.descriptionHtml,
            descriptionSassCode: item.descriptionSassCode,
            priority: item.priority,
            tags: item.tags || [],
            attachmentFileIds: item.attachmentFileIds,
            attachmentFileUrls: item.attachmentFileUrls,
            order: String(item.order),
            userOrder: String(item.userOrder),
            target: item.target,
        }
        if (item.submissionRequirements) {
            const { text, link, imageFileId, otherFileId } =
                item.submissionRequirements
            if (!text && !link && !imageFileId && !otherFileId) {
                newData.requirements = 'none'
            } else if (text && link) {
                newData.requirements = 'text_link'
            } else if (text && imageFileId) {
                newData.requirements = 'text_image'
            } else if (text && otherFileId) {
                newData.requirements = 'text_file'
            }
        } else {
            newData.requirements = 'none'
        }

        if (
            ['team', 'role'].includes(item.target) &&
            item.assignedTeamMemberId
        ) {
            newData.assignedTeamMemberId = getUserDisplayName(
                item.assignedTeamMember,
            )
        }

        if (item.clientId) {
            newData.clientId = getUserDisplayName(item.client)
        }
        return newData
    }

    async function getBody(data, item) {
        const MAX_FILE_SIZE = 50 * 1024 * 1024

        for (const file of data.attachmentFiles || []) {
            if (file.size > MAX_FILE_SIZE) {
                throw new Error(`Max file size is 50MB.`)
            }
        }

        const body = {
            title: item.title !== data.title ? data.title : undefined,
            descriptionHtml:
                item.descriptionHtml !== data.descriptionHtml
                    ? data.descriptionHtml
                    : undefined,
            descriptionSassCode:
                item.descriptionSassCode !== data.descriptionSassCode
                    ? data.descriptionSassCode
                    : undefined,
            priority:
                item.priority !== data.priority ? data.priority : undefined,
            order:
                Number(data.order) !== Number(item.order)
                    ? Number(data.order)
                    : undefined,
            userOrder:
                Number(data.userOrder) !== Number(item.userOrder)
                    ? Number(data.userOrder)
                    : undefined,
            tags: !comparePrimitiveArrays(item.tags, data.tags)
                ? data.tags
                : undefined,
            attachmentFileIds: !comparePrimitiveArrays(
                item.attachmentFileIds,
                data.attachmentFileIds,
            )
                ? data.attachmentFileIds
                : undefined,
        }

        let newSubmissionRequirements = {}
        if (data.requirements === 'text_link') {
            newSubmissionRequirements = {
                text: true,
                link: true,
                imageFileId: false,
                otherFileId: false,
            }
        } else if (data.requirements === 'text_image') {
            newSubmissionRequirements = {
                text: true,
                imageFileId: true,
                otherFileId: false,
                link: false,
            }
        } else if (data.requirements === 'text_file') {
            newSubmissionRequirements = {
                text: true,
                otherFileId: true,
                imageFileId: false,
                link: false,
            }
        }

        if (
            !comparePrimitiveObjects(
                item.submissionRequirements,
                newSubmissionRequirements,
            )
        ) {
            body.submissionRequirements = newSubmissionRequirements
        }

        if (data.assignedTeamMemberId !== item.assignedTeamMemberId) {
            body.assignedTeamMemberId = data.assignedTeamMemberId
        }

        // TODO could be duplicates
        if (
            data.assignedTeamMemberId ===
            getUserDisplayName(item.assignedTeamMember)
        ) {
            delete body.assignedTeamMemberId
        }

        if (data.clientId !== item.clientId) {
            body.clientId = data.clientId || null
        }

        // TODO could be duplicates
        if (data.clientId === getUserDisplayName(item.client)) {
            delete body.clientId
        }

        const formData = new FormData()
        const bodyData = { ...body }

        formData.append('data', JSON.stringify(bodyData))

        if (data.attachmentFiles?.length) {
            const { error } = uploadFiles(data.attachmentFiles, {
                patchUrl: `${API_URL}/v1/tasks/${taskId}`,
                patchField: 'attachmentFileUrlsAdd',
            })
            if (error) {
                setToast(error, 'alert')
            } else {
                setToast('Files upload in progress, it may take a few minutes.')
            }
        }

        return formData
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/tasks/${taskId}`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/tasks/${taskId}`}
                inputs={inputs}
                getBody={getBody}
                mapItemToData={mapItemToData}
                mainButton
                mutationRegexes={[/\/v1\/tasks/]}
                getExtraHasChanged={getExtraHasChanged}
                hasHistory
            />
        </SectionContainer>
    )
}

export default EditTask
