import Tag from '../../../components/common/Tag'
import BlockStack from '../../../components/common/BlockStack'

export default function ContactCardTags({ user: data }) {
    return (
        <BlockStack itemsEnd gap={'tiny'}>
            {/* {data.role === 'user' &&
                data.labels &&
                (data.labels?.includes('active') ? (
                    <Tag noMargin color={'success'}>
                        Active
                    </Tag>
                ) : (
                    <Tag noMargin color={'alert'}>
                        Inactive
                    </Tag>
                ))} */}
            {data.isPaid ? (
                <Tag noMargin color={'success'}>
                    Paid
                </Tag>
            ) : null}
            {!data.isPaid && ['user', 'staff'].includes(data.role) ? (
                <Tag noMargin outline>
                    Free
                </Tag>
            ) : null}
            {data.isBlocked ? (
                <Tag noMargin color="alert">
                    Blocked
                </Tag>
            ) : null}
            {data.onHold ? (
                <Tag noMargin color="alert">
                    Hold
                </Tag>
            ) : null}
            {data.onBlackList ? (
                <Tag noMargin color="alert">
                    Blacklist
                </Tag>
            ) : null}
            {data.onCollections ? (
                <Tag noMargin color="alert">
                    Collections
                </Tag>
            ) : null}
        </BlockStack>
    )
}
