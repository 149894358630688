import { formatDate, formatMoney } from '../../common/helpers'
import ResourceList from '../../components/admin/ResourceList'
import BlockStack from '../../components/common/BlockStack'
import Grid from '../../components/common/Grid'
import Tag from '../../components/common/Tag'

function isPastDue(invoice) {
    return (
        invoice.dueDate &&
        invoice.dueDate * 1000 < new Date().getTime() &&
        invoice.status === 'open'
    )
}

export default function ProfileBillingData() {
    return (
        <>
            <ResourceList
                smallTitle
                apiUrl={'/payment/clients/me/subscriptions'}
                getItemsFromResponse={(data) => data?.subscriptions}
                mapItems={(sub, response) => ({
                    ...sub,
                    email: response.email,
                })}
                title={'Subscriptions'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <Grid cols={2}>
                                <BlockStack gap={'tiny'}>
                                    <h3
                                        className={`m-0 ${`${item.status === 'active' ? 'success' : ''} ${
                                            item.status === 'canceled'
                                                ? 'alert'
                                                : ''
                                        }`}`}
                                    >
                                        {item.items
                                            .map(
                                                (subItem) =>
                                                    subItem.servicePrice
                                                        .marketingService.title,
                                            )
                                            .join(', ')}
                                    </h3>

                                    <div>
                                        <Tag outline>
                                            {item.source === 'manual'
                                                ? 'Manual billing'
                                                : item.source}
                                        </Tag>
                                    </div>
                                </BlockStack>
                                {!!item.canceledAt && (
                                    <BlockStack gap={'tiny'}>
                                        <div>{`Canceled ${formatDate(item.canceledAt)}`}</div>
                                    </BlockStack>
                                )}
                            </Grid>
                        ),
                    },
                ]}
            />
            <br />
            <ResourceList
                smallTitle
                apiUrl={`/payment/clients/me/invoices`}
                getItemsFromResponse={(data) => data?.invoices}
                mapItems={(invoice, response) => ({
                    ...invoice,
                    email: response.email,
                })}
                title={'Invoices'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <Grid cols={2}>
                                <BlockStack gap={'tiny'}>
                                    <h3
                                        className={`m-0 ${item.status === 'paid' ? 'success' : ''} ${
                                            isPastDue(item) ? 'alert' : ''
                                        }  ${item.status === 'draft' ? 'warning' : ''} ${
                                            item.status === 'void'
                                                ? 'disabled'
                                                : ''
                                        }`}
                                    >
                                        {formatMoney(item.total)}
                                    </h3>

                                    {item.createdDate ? (
                                        <div>
                                            {`Created on ${formatDate(item.createdDate)}`}
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {item.dueDate && item.status !== 'paid' && (
                                        <div
                                            className={`${isPastDue(item) ? 'alert' : ''}`}
                                        >
                                            {`Due ${formatDate(item.dueDate)}`}
                                        </div>
                                    )}
                                </BlockStack>
                                <BlockStack gap={'tiny'}>
                                    <div className="text-caps">
                                        {isPastDue(item)
                                            ? 'Past due'
                                            : item.status}
                                    </div>

                                    <div>
                                        <Tag outline>
                                            {item.source === 'manual'
                                                ? 'Manual billing'
                                                : item.source}
                                        </Tag>
                                    </div>
                                </BlockStack>
                            </Grid>
                        ),
                    },
                ]}
            />
        </>
    )
}
