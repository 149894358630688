import ResourceList from '../../../../components/admin/ResourceList'
import useAuth from '../../../../common/hooks/useAuth'
import { ICONS, TASK_STATUSES } from '../../../../common/constants'
import TaskItem from '../TaskItem'
import useBulkActions from './bulk-actions'
import useActions from './actions'
import getFilters from './filters'
import { useMemo } from 'react'

const fields =
    'creatorId,clientId,assignedTeamMemberId,title,description,descriptionHtml,priority,order,userOrder,target,teamRoles,status,clientReviewStatus,timeQuoteMinutes,taskType,tags,createdAt,commentsCount'
const populate =
    'client.firstName,client.lastName,client.role,assignedTeamMember.firstName,assignedTeamMember.lastName,assignedTeamMember.role,creator.firstName,creator.lastName,creator.role'

export default function TasksList({
    title,
    onResponseLoaded,
    searchOnly,
    hideFilterTags,
    statusTabs,
    clientId,
    assignedTeamMemberId,
    newItemPath,
    targetTeam,
    targetRole,
    targetClient,
    paidOnly,
    activeStatusKeys,
    roleWithStaffAssigned,
    clientReviewStatus,
    newItemLabel,
    tag,
    renderMainButton,
}) {
    const auth = useAuth()

    const { onDelete } = useActions()
    const {
        onAddTags,
        onRemoveTags,
        onSetPriority,
        onSetClient,
        onSetTeamMember,
        onAttachPage,
        onSetAllToOpen,
    } = useBulkActions()

    const targets = []
    if (targetTeam) {
        targets.push('team')
    }
    if (targetRole) {
        targets.push('role')
    }
    if (targetClient) {
        targets.push('client')
    }
    const targetStr = targets.join(',')

    const filters = useMemo(
        () =>
            getFilters({
                searchOnly,
                statusTabs,
                targets,
                paidOnly,
            }),
        [searchOnly, statusTabs, targets, paidOnly],
    )

    if (activeStatusKeys?.length) {
        for (const key of activeStatusKeys) {
            filters.filters[key].default = 'true'
        }
    }

    if (roleWithStaffAssigned) {
        filters.filters.roleWithStaffAssigned.default = roleWithStaffAssigned
    }

    if (clientReviewStatus && !targets.includes('client')) {
        filters.filters.clientReviewStatus.default = clientReviewStatus
    }

    if (title) {
        filters.urlKey = title
    }

    return (
        <ResourceList
            getSelectedItemText={(item) => item.title}
            togglesSelectable={auth.isAdmin && !searchOnly}
            selectLimit={10}
            allBulkSelectActions={
                auth.isAdmin &&
                (activeStatusKeys?.includes('statusAccepted') ||
                    !activeStatusKeys?.length)
                    ? [
                          {
                              title: 'Set all in-progress to open',
                              onClick: onSetAllToOpen,
                          },
                      ]
                    : undefined
            }
            bulkSelectActions={[
                {
                    title: 'Add tags',
                    onClick: onAddTags,
                    icon: ICONS.LABEL_GRAY,
                },
                {
                    title: 'Remove tags',
                    onClick: onRemoveTags,
                    icon: ICONS.LABEL_GRAY,
                },
                {
                    title: 'Set priority',
                    onClick: onSetPriority,
                },
                {
                    title: 'Set client',
                    onClick: onSetClient,
                },
                {
                    title: 'Set team member',
                    onClick: onSetTeamMember,
                },
                {
                    title: 'Attach requirements page',
                    onClick: onAttachPage,
                },
            ]}
            //
            searchPlaceholder={'Search tasks'}
            searchOnly={searchOnly}
            itemsNav={
                (auth.isAdmin || auth.isAssistant) &&
                !clientId &&
                !assignedTeamMemberId
            }
            renderMainButton={
                (renderMainButton !== false && auth.isAdmin) || auth.isStaff
            }
            newItemPath={auth.isAdmin || auth.isStaff ? newItemPath : undefined}
            newItemLabel={newItemLabel || 'New task'}
            apiUrl={'/tasks'}
            getItemsFromResponse={(data) => data?.results}
            itemClickPath={`/tasks/:item.id`}
            initialQuery={{
                target: targetStr || undefined,
                sortBy: searchOnly ? 'order:asc' : undefined,
                q: searchOnly ? '@@@@@@@@@@@' : undefined,
                statusComplete: searchOnly ? 'false' : undefined,
                assignedTeamMemberId,
                clientId,
                fields,
                populate,
                tags: tag || undefined,
            }}
            paginated
            paginationLimit={10}
            hideFilterTags={hideFilterTags}
            hideCount={searchOnly}
            loadMoreButton
            filters={filters}
            onResponseLoaded={onResponseLoaded}
            emptyText={searchOnly ? '' : undefined}
            fields={[
                {
                    column: 1,
                    getValue: (item) => <TaskItem task={item} />,
                },
            ]}
            actions={[
                {
                    title: 'Edit',
                    icon: ICONS.EDIT_GRAY,
                    link: '/tasks/:item.id/edit',
                    getHide: (item) =>
                        item.isAppTask ||
                        (!auth.isAdmin &&
                            !auth.isAssistant &&
                            !(
                                auth.isStaff &&
                                auth.user.id === item.creatorId &&
                                [
                                    (TASK_STATUSES.APPROVAL,
                                    TASK_STATUSES.RECREATION),
                                ].includes(item.status)
                            )),
                },
                {
                    title: 'Delete',
                    onClick: (item) => onDelete(item),
                    hide: !auth.isAdmin && !auth.isAssistant,
                    type: 'alert',
                    icon: ICONS.TRASH_RED,
                },
            ]}
        />
    )
}
