import Button from '../../../components/common/Button'
import StripeCardForm from '../../../common/StripeCardForm'
import useData from '../../../common/hooks/useData'
import useAuth from '../../../common/hooks/useAuth'
import fetchAPI from '../../../common/fetchAPI'
import { COUNTRIES, US_STATES } from '../../../common/constants'
import MessageSection from '../../../components/common/MessageSection'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import { formatZipCode } from '../../../common/helpers'
import { useState } from 'react'

const addressInputs = [
    {
        key: 'country',
        label: 'Country',
        type: 'select',
        options: COUNTRIES.sort((a, b) => a.text.localeCompare(b.text)).map(
            ({ text }) => ({
                value: text,
                label: text,
            }),
        ),
        required: true,
        defaultValue: 'United States',
    },
    {
        key: 'address',
        label: 'Address',
        required: true,
    },
    {
        key: 'address2',
        label: 'Unit/apartment/suite',
    },
    {
        key: 'city',
        label: 'City',
        required: true,
    },
    {
        key: 'state',
        label: 'State',
        type: 'select',
        options: US_STATES.map(({ text }) => ({
            value: text,
            label: text,
        })),
        shouldBeText: (data) => data.country !== 'United States',
        required: true,
        defaultValue: 'Iowa',
    },
    {
        key: 'zip',
        label: 'Zip',
        required: true,
        transformValue: (v, data, itemIndex) => {
            if (!v) return v
            const isUS =
                data.addresses?.[itemIndex]?.country === 'United States'
            return isUS ? formatZipCode(v) : v
        },
    },
]

const idProofInputs = [
    {
        key: 'files',
        label: 'Photo of your ID (JPG format)',
        type: 'file',

        required: true,
        accept: 'image/jpeg',
    },
]

function PurchasePremium() {
    const auth = useAuth()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const requestFeature = searchParams.get('requestFeature')
    const [showAddressMessage, setShowAddressMessage] = useState(false)
    const [showPhotoMessage, setShowPhotoMessage] = useState(false)

    const {
        idProofFileId,
        idProofFileIdError,
        idProofFileIdLoading,
        idProofFileIdMutate,
        idProofFileIdValidating,
    } = useData(
        `/v1/users/${auth.user.id}?fields=idProofFileId`,
        'idProofFileId',
        (data) => data?.idProofFileId,
    )

    const {
        address,
        addressError,
        addressLoading,
        addressMutate,
        addressValidating,
    } = useData(
        `/v1/addresses/${auth.user.id}?addressTypes=billing&ownerId=${auth.user.id}&ownerType=User`,
        'address',
        (data) => data?.results?.[0],
    )

    const { subError, subLoading } = useData(
        `/v1/app-billing/me/premium-subscription`,
        'sub',
        (data) => data,
        false,
        false,
        (data) => {
            if (
                data?.subscription &&
                data.subscription?.status !== 'incomplete'
            ) {
                navigate('/profile/billing/premium')
            }
        },
    )

    async function onSubmitPaymentForm(stripe, elements) {
        const { responseData, error } = await fetchAPI(
            `/v1/app-billing/me/create-premium-subscription`,
            { requestFeature },
            'POST',
        )

        if (error) return { error: { message: error } }

        const { error: stripeError } = await stripe.confirmPayment({
            elements,
            clientSecret: responseData.clientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/callbacks/stripe-premium-complete`,
            },
        })

        if (stripeError) return { error: stripeError }

        return {}
    }

    if (addressLoading || subLoading || idProofFileIdLoading) {
        return <Spinner />
    }

    if (
        (addressError && !addressValidating) ||
        subError ||
        (idProofFileIdError && !idProofFileIdValidating)
    ) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {addressError || subError || idProofFileIdError}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (auth.isPremiumUser) {
        return (
            <SectionContainer>
                <MessageSection type="warning">
                    You already have a premium subscription.
                    <Break />
                    <Button
                        small
                        outline
                        white
                        href={`/profile`}
                        text="Back to profile"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {showAddressMessage && (
                <MessageSection
                    type="success"
                    onDismiss={() => setShowAddressMessage('')}
                >
                    <div>Your billing address was saved.</div>
                </MessageSection>
            )}
            {!address ? (
                <>
                    <h2>Billing address</h2>
                    <small className="text-subdued">
                        Billing address must match the credit card on file.
                    </small>
                    <br />
                    <br />

                    <DataFormNew
                        url={`/v1/addresses/${auth.user.id}`}
                        submitText="Save address"
                        submitToast=""
                        inputs={addressInputs}
                        getBody={(data) => ({
                            ...data,
                            addressTypes: ['billing'],
                            ownerId: auth.user.id,
                            ownerType: 'User',
                        })}
                        mutationRegexes={[
                            /\/v1\/users\/me\/profile-completion/,
                            /\/v1\/addresses*/,
                            /\/v1\/user*/,
                            /\/v1\/me*/,
                        ]}
                        onSuccess={() => {
                            addressMutate()
                            auth.refetchAuth()
                            setShowAddressMessage(true)
                        }}
                    />
                </>
            ) : null}

            {showPhotoMessage && (
                <>
                    <MessageSection
                        type="success"
                        onDismiss={() => setShowPhotoMessage('')}
                    >
                        <div>Your ID photo was saved.</div>
                    </MessageSection>
                    <Break />
                </>
            )}

            {!idProofFileId ? (
                <>
                    <h2>Proof of ID</h2>
                    <h3>Verify your identity</h3>
                    <small className="text-subdued">
                        Please upload a picture of a government issued ID. We
                        will need front and back.
                    </small>
                    <br />
                    <br />

                    <DataFormNew
                        url={`/v1/users/me/id-proof`}
                        submitText="Upload photo"
                        submitToast=""
                        inputs={idProofInputs}
                        getBody={(data) => {
                            const MAX_FILE_SIZE = 10 * 1024 * 1024
                            if (data.files[0].size > MAX_FILE_SIZE) {
                                throw new Error(`Max file size is 10MB.`)
                            }
                            const formData = new FormData()
                            formData.append(`file`, data.files[0])
                            return formData
                        }}
                        mutationRegexes={[
                            /\/v1\/users\/me\/profile-completion/,
                            /\/v1\/user*/,
                            /\/v1\/me*/,
                        ]}
                        onSuccess={() => {
                            // auth.refetchAuth()
                            setShowPhotoMessage(true)
                            idProofFileIdMutate()
                        }}
                    />
                </>
            ) : null}

            {address && idProofFileId ? (
                <StripeCardForm
                    mode={'subscription'}
                    amount={7000}
                    currency={'usd'}
                    onSubmit={onSubmitPaymentForm}
                />
            ) : null}
        </SectionContainer>
    )
}

export default PurchasePremium
