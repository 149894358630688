import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useData from '../../../common/hooks/useData'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import { copyToClipboard, formatDateTime } from '../../../common/helpers'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { ICONS } from '../../../common/constants'
import Button from '../../../components/common/Button'
import ResourceList from '../../../components/admin/ResourceList'
import Toggle from '../../../components/common/Toggle'
import useToast from '../../../common/hooks/useToast'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'
import Break from '../../../components/common/Break'
import { useState } from 'react'

function getSubmitterName(fields) {
    const keys = Object.keys(fields)
    let email
    let firstName
    let lastName
    for (const k of keys) {
        if (k.toLowerCase().includes('first name')) {
            firstName = fields[k]
        } else if (k.toLowerCase().includes('last name')) {
            lastName = fields[k]
        } else if (k.toLowerCase().includes('email')) {
            email = fields[k]
        }
    }

    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    } else if (firstName) {
        return firstName
    } else if (lastName) {
        return lastName
    } else if (email) {
        return email
    } else {
        return '(unknown)'
    }
}

export default function ContactFormPage() {
    const { contactFormId } = useParams()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const navigate = useNavigate()
    const [exportLoading, setExportLoading] = useState(false)
    const [searchParams] = useSearchParams()

    const { setModal, isModalLoading, setModalLoading } = useModal()
    const { form, formLoading, formError } = useData(
        `/v1/contact-forms/${contactFormId}`,
        'form',
    )

    async function onDelete(item) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/contact-forms/${contactFormId}/submissions/${item.id}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    matchMutate(/\/v1\/contact-forms/)
                    setToast('Deleted message')
                }}
            />,
            'Are you sure you want to delete this message?',
        )
    }

    async function onExport() {
        setExportLoading(true)

        // ?f_sortKey=creationDate&f_sortIsAsc=true&f_url_key=submissions&f_creationDateFrom=2025-01-30

        const sort = searchParams.get('f_sortKey')
        const isAsc = searchParams.get('f_sortIsAsc') === 'true'
        const sortStr = sort
            ? `${sort}:${isAsc ? 'asc' : 'desc'}`
            : 'creationDate:desc'
        const creationDateFrom = searchParams.get('f_creationDateFrom') || ''
        const creationDateTo = searchParams.get('f_creationDateTo') || ''

        const queryStr = `?sortBy=${sortStr}${
            creationDateFrom ? `&creationDateFrom=${creationDateFrom}` : ''
        }${creationDateTo ? `&creationDateTo=${creationDateTo}` : ''}`

        const { responseData, error } = await fetchAPI(
            `/v1/contact-forms/${contactFormId}/submissions/export${queryStr}`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { csvStr } = responseData

        const blob = new Blob([csvStr], { type: 'text/csv' })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${form.title} - messages.csv`
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function addToContacts(items) {
        setModal(
            <ConfirmModalContent
                confirmText="Add to contacts"
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0
                    for (const item of items) {
                        const { error } = await fetchAPI(
                            `/v1/contact-forms/${contactFormId}/submissions/${item.id}/add-to-contacts`,
                            {},
                            'POST',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setToast(
                            `${errors.length} submissions failed to be added to contacts`,
                            'alert',
                        )
                    }

                    setToast(`${successCount} submissions added to contacts`)
                }}
            />,
            `Add ${items.length} messages to contacts?`,
        )
    }

    async function addAllToContacts(queryStr) {
        setModal(
            <ConfirmModalContent
                confirmText="Add to contacts"
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    let finalQueryStr = ''
                    if (queryStr) {
                        const query = new URLSearchParams(queryStr)
                        query.delete('sortBy')
                        finalQueryStr = query.toString()
                    }

                    const url = `/v1/contact-forms/${contactFormId}/submissions/bulk/add-to-contacts${
                        finalQueryStr ? `?${finalQueryStr}` : ''
                    }`

                    const { error } = await fetchAPI(url, {}, 'POST')
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setToast(
                        'Contact creation initiated, it might take up to a minute.',
                    )
                }}
            />,
            `Add all selected messages to contacts?`,
        )
    }

    if (formLoading) {
        return <Spinner />
    }

    if (formError) {
        return (
            <SectionContainer>
                <ErrorMessage>{formError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>{form.title}</h2>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Copy embed code',
                                    onClick: () => {
                                        copyToClipboard(form.embedHtml)
                                        setToast('Copied to clipboard')
                                    },
                                    icon: ICONS.COPY_GRAY,
                                },
                                {
                                    title: 'Edit',
                                    icon: ICONS.EDIT_GRAY,
                                    onClick: () =>
                                        navigate(
                                            `/contact-forms/${contactFormId}/edit`,
                                        ),
                                },
                                {
                                    title: 'Export messages',
                                    onClick: onExport,
                                    icon: ICONS.DOWNLOAD_GRAY,
                                    loading: exportLoading,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer>
                <Button
                    text="Copy embed code"
                    icon={ICONS.COPY_ACTIVE}
                    onClick={() => {
                        copyToClipboard(form.embedHtml)
                        setToast('Copied to clipboard')
                    }}
                    small
                    outline
                />
            </SectionContainer>
            <Break />
            <ResourceList
                apiRequestSelectedItems={false}
                getSelectedItemText={(item) => item.id}
                togglesSelectable
                allBulkSelectActions={[
                    {
                        title: 'Add all to contacts',
                        onClick: addAllToContacts,
                    },
                ]}
                bulkSelectActions={[
                    {
                        title: 'Add to contacts',
                        onClick: addToContacts,
                    },
                ]}
                //
                smallTitle
                title="Messages"
                apiUrl={`/contact-forms/${contactFormId}/submissions`}
                initialQuery={{
                    sortBy: 'creationDate:desc',
                }}
                paginated
                filters={{
                    urlKey: 'submissions',

                    sort: {
                        creationDate: {
                            label: 'Date',
                            dbFields: ['creationDate'],
                            asc: false,
                        },
                    },
                    search: true,
                    filters: {
                        creationDateFrom: {
                            type: 'date',
                            label: 'From',
                            dbField: 'creationDateFrom',
                        },
                        creationDateTo: {
                            type: 'date',
                            label: 'To',
                            dbField: 'creationDateTo',
                        },
                    },
                }}
                emptyText={`No messages yet`}
                getItemsFromResponse={(data) => data?.results}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `From ${getSubmitterName(item.fields)}`,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) => formatDateTime(item.creationDate),
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) => (
                            <Toggle small title="Info">
                                {Object.entries(item.fields).map(
                                    ([k, v], i) => (
                                        <div key={i}>
                                            {k}: {v || '-'}
                                        </div>
                                    ),
                                )}
                            </Toggle>
                        ),
                    },
                ]}
                actions={[
                    {
                        title: 'Delete',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: (item) => onDelete(item),
                    },
                ]}
            />
            <br />

            <SectionContainer>
                <div>
                    <h2 className="h3">Whitelisted domains</h2>
                    <ul>
                        {form.whitelistedDomains.map((domain) => (
                            <li key={domain}>{domain}</li>
                        ))}
                    </ul>
                </div>
            </SectionContainer>
        </>
    )
}
