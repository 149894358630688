import { useEffect, useState } from 'react'
import Button from '../Button'
import DragArea from '../DragArea'
import ButtonGroup from '../ButtonGroup'
import { ICONS } from '../../../common/constants'
import TextInput from './TextInput'
import SelectInput from './SelectInput'
import BlockStack from '../BlockStack'
import InlineStack from '../InlineStack'

function getInitItemData(innerInputs) {
    return innerInputs.reduce((a, b) => {
        let v
        switch (b.type) {
            case 'select':
                v = b.options[0].value
                break
            case 'hidden':
                v = b.value
                break
            default:
                v = ''
        }
        return {
            ...a,
            [b.key]: v,
        }
    }, {})
}

export default function AddItemsInput({
    isCollapsible,
    value,
    onChange,
    innerInputs,
    defaultValue,
    presets,
    errors,
}) {
    const [isExpanded, setIsExpanded] = useState(!isCollapsible)
    const [newItemData, setNewItemData] = useState(getInitItemData(innerInputs))
    const [expandedItemIndices, setExpandedItemIndices] = useState([])

    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue)
        }
    }, [])

    function handleExpand(e) {
        e.preventDefault()
        setIsExpanded(true)
    }

    function handleNewItemAdd() {
        onChange([...value, newItemData])
        setNewItemData(getInitItemData(innerInputs))
    }

    function handleExistingChange(v, key, index) {
        onChange(
            value.map((item, i) =>
                i === index ? { ...item, [key]: v } : item,
            ),
        )
    }

    function handleDelete(index) {
        onChange(value.filter((_item, i) => i !== index))
    }

    const firstInput = innerInputs[0]

    const items = (value || []).map((item, i) => ({
        ...item,
        dragId: `inputs-item-${i}`,
    }))

    function handleNewIdPosition(dragId, newIndex) {
        const newItems = [...items]
        const draggedItemIndex = newItems.findIndex(
            (item) => item.dragId === dragId,
        )

        if (
            draggedItemIndex === -1 ||
            newIndex < 0 ||
            newIndex >= newItems.length
        ) {
            return
        }

        const [draggedItem] = newItems.splice(draggedItemIndex, 1)

        const adjustedIndex =
            draggedItemIndex < newIndex ? newIndex - 1 : newIndex
        newItems.splice(adjustedIndex, 0, draggedItem)

        onChange(newItems.map((item) => ({ ...item, dragId: undefined })))
    }

    if (!defaultValue?.length && !value?.length) {
        return null
    }

    const collapseInput = innerInputs.find(
        (input) =>
            input.collapseInput && !['hidden', 'select'].includes(input.type),
    )

    const errorItems = (errors || []).map((e) => ({
        index: Number(e.split('_')[1]),
        key: e.split('_')[2],
    }))

    return (
        <div className="add-items-input">
            {isCollapsible && !isExpanded && (
                <div>
                    {[
                        'text',
                        'number',
                        'email',
                        'tel',
                        'url',
                        'password',
                    ].includes(firstInput.type) || !firstInput.type ? (
                        <TextInput
                            regex={firstInput.regex}
                            type={firstInput.type || 'text'}
                            id={firstInput.key}
                            value={items?.[0]?.[firstInput.key] || ''}
                            onChange={(v) =>
                                handleExistingChange(v, firstInput.key, 0)
                            }
                            autoComplete={firstInput.autoComplete}
                            placeholder={firstInput.placeholder}
                            errors={
                                errorItems.some(
                                    (e) =>
                                        e.index === 0 &&
                                        e.key === firstInput.key,
                                ) && [true]
                            }
                        />
                    ) : // TODO
                    null}
                    {innerInputs.slice(1).map((input, i) => (
                        <input
                            type={'hidden'}
                            key={i}
                            id={input.key}
                            value={items?.[0]?.[input.key] || ''}
                            onChange={(e) =>
                                handleExistingChange(
                                    e.target.value,
                                    input.key,
                                    0,
                                )
                            }
                        />
                    ))}
                    {items?.[0]?.[firstInput.key]?.trim() !== '' && (
                        <button
                            onClick={handleExpand}
                            type="button"
                            className="add-more-btn"
                        >
                            {isExpanded ? 'Hide' : '+ More'}
                        </button>
                    )}
                </div>
            )}
            {(isExpanded || !isCollapsible) && (
                <div className="extra-form">
                    <div className="added-items">
                        <small className="text-subdued">
                            Long press to drag and reorder.
                            <br />
                            <br />
                        </small>
                        {/* Added items */}
                        <DragArea
                            id="form-inputs"
                            onNewIdPosition={handleNewIdPosition}
                        >
                            {items.map((item, i) => (
                                <div
                                    className="added-item"
                                    // eslint-disable-next-line react/no-unknown-property
                                    dragid={item.dragId}
                                    key={`form-input-${i}`}
                                >
                                    {innerInputs.map((input, j) => {
                                        if (
                                            collapseInput &&
                                            input !== collapseInput &&
                                            !expandedItemIndices.includes(i)
                                        ) {
                                            return null
                                        }
                                        return (
                                            <BlockStack gap="tiny" key={j}>
                                                {input.type !== 'hidden' && (
                                                    <InlineStack
                                                        spaceBetween
                                                        itemsEnd
                                                    >
                                                        <label
                                                            className="m-0"
                                                            htmlFor={`${input.key}-${
                                                                i + 1
                                                            }`}
                                                        >
                                                            {input.label}
                                                        </label>
                                                        {input ===
                                                            collapseInput && (
                                                            <Button
                                                                outline
                                                                type="button"
                                                                tiny
                                                                onClick={() => {
                                                                    expandedItemIndices.includes(
                                                                        i,
                                                                    )
                                                                        ? setExpandedItemIndices(
                                                                              expandedItemIndices.filter(
                                                                                  (
                                                                                      index,
                                                                                  ) =>
                                                                                      index !==
                                                                                      i,
                                                                              ),
                                                                          )
                                                                        : setExpandedItemIndices(
                                                                              Array.from(
                                                                                  new Set(
                                                                                      [
                                                                                          ...expandedItemIndices,
                                                                                          i,
                                                                                      ],
                                                                                  ),
                                                                              ),
                                                                          )
                                                                }}
                                                                text={
                                                                    expandedItemIndices.includes(
                                                                        i,
                                                                    )
                                                                        ? 'Hide'
                                                                        : 'Edit'
                                                                }
                                                            />
                                                        )}
                                                    </InlineStack>
                                                )}
                                                {input.type === 'select' && (
                                                    <SelectInput
                                                        key={j}
                                                        value={item[input.key]}
                                                        id={`${input.key}-${
                                                            i + 1
                                                        }`}
                                                        onChange={(v) =>
                                                            handleExistingChange(
                                                                v,
                                                                input.key,
                                                                i,
                                                            )
                                                        }
                                                        options={input.options}
                                                        errors={
                                                            errorItems.some(
                                                                (e) =>
                                                                    e.index ===
                                                                        i &&
                                                                    e.key ===
                                                                        input.key,
                                                            ) && [true]
                                                        }
                                                    />
                                                )}
                                                {input.type !== 'select' &&
                                                    ([
                                                        'text',
                                                        'number',
                                                        'email',
                                                        'tel',
                                                        'url',
                                                        'password',
                                                    ].includes(input.type) ||
                                                    !input.type ? (
                                                        <TextInput
                                                            regex={input.regex}
                                                            required={
                                                                input.required
                                                            }
                                                            key={j}
                                                            value={
                                                                item[input.key]
                                                            }
                                                            type={
                                                                input.type ||
                                                                'text'
                                                            }
                                                            id={`${input.key}-${
                                                                i + 1
                                                            }`}
                                                            onChange={(v) =>
                                                                handleExistingChange(
                                                                    v,
                                                                    input.key,
                                                                    i,
                                                                )
                                                            }
                                                            disabled={
                                                                input?.getDisabled &&
                                                                input.getDisabled(
                                                                    item,
                                                                )
                                                            }
                                                            autoComplete={
                                                                input.autoComplete
                                                            }
                                                            placeholder={
                                                                input.placeholder
                                                            }
                                                            errors={
                                                                errorItems.some(
                                                                    (e) =>
                                                                        e.index ===
                                                                            i &&
                                                                        e.key ===
                                                                            input.key,
                                                                ) && [true]
                                                            }
                                                        />
                                                    ) : (
                                                        <input
                                                            type={'hidden'}
                                                            key={j}
                                                            value={
                                                                item[input.key]
                                                            }
                                                            id={`${input.key}-${
                                                                i + 1
                                                            }`}
                                                        />
                                                    ))}
                                            </BlockStack>
                                        )
                                    })}

                                    <div>
                                        {!item.hideDelete && (
                                            <Button
                                                text="Delete"
                                                type="button"
                                                small
                                                alignRight
                                                destructive
                                                outline
                                                onClick={() => handleDelete(i)}
                                                disabled={items.length === 1}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </DragArea>
                    </div>
                    <div>
                        {presets?.length > 0 && (
                            <>
                                <ButtonGroup col2 grid>
                                    {presets
                                        .filter(
                                            ({ label }) =>
                                                !value.some(
                                                    (item) =>
                                                        item.label === label,
                                                ),
                                        )
                                        .map((preset, i) => (
                                            <div key={i}>
                                                <Button
                                                    iconRight
                                                    icon={ICONS.PLUS_ACTIVE}
                                                    fullWidth
                                                    small
                                                    outline
                                                    text={preset.label}
                                                    onClick={() => {
                                                        onChange([
                                                            ...value,
                                                            { ...preset },
                                                        ])
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </ButtonGroup>
                                <br />
                            </>
                        )}
                        {/* Form for new item */}
                        {innerInputs.map((input, i) => (
                            <div key={i}>
                                <label htmlFor={`${input.key}-new`}>
                                    {input.label}
                                </label>

                                {input.type === 'select' && (
                                    <SelectInput
                                        value={newItemData[input.key]}
                                        id={`${input.key}-new`}
                                        onChange={(v) =>
                                            setNewItemData({
                                                ...newItemData,
                                                [input.key]: v,
                                            })
                                        }
                                        options={input.options}
                                    />
                                )}
                                {input.type !== 'select' && (
                                    <TextInput
                                        key={i}
                                        value={newItemData[input.key]}
                                        type={input.type || 'text'}
                                        id={`${input.key}-new`}
                                        onChange={(v) =>
                                            setNewItemData({
                                                ...newItemData,
                                                [input.key]: v,
                                            })
                                        }
                                        autoComplete={input.autoComplete}
                                        placeholder={input.placeholder}
                                    />
                                )}
                                {input.infoBottom ? (
                                    <small className="input-info">
                                        {input.infoBottom}
                                    </small>
                                ) : null}
                            </div>
                        ))}
                    </div>
                    <Button
                        type="button"
                        text="Add"
                        onClick={handleNewItemAdd}
                    />
                </div>
            )}
        </div>
    )
}
