import './App.scss'
import React from 'react'
import Menu from './pages/more/Menu'
import InvoicePage from './pages/admin/invoices/InvoicePage'
import EditInvoice from './pages/admin/invoices/EditInvoice'
import ServicePage from './pages/reports/services/ServicePage'
import Settings from './pages/settings/Settings'
import SubPage from './pages/admin/subscription/SubPage'
import EditSub from './pages/admin/subscription/EditSub'
import InvoicesList from './pages/admin/invoices/InvoicesList'
import CreateInvoice from './pages/admin/invoices/CreateInvoice'
import CreateExpense from './pages/reports/expenses/CreateExpense'
import ExpensePage from './pages/reports/expenses/ExpensePage'
import CreatePrice from './pages/reports/services/CreatePrice'
import CreateSub from './pages/admin/subscription/CreateSub'
import CreateService from './pages/reports/services/CreateService'
import ClientSettings from './pages/admin/clients/ClientSettings'
import CreateClientAssignment from './pages/admin/client-assignments/CreateClientAssignment'
import CreateServiceCategory from './pages/settings/service-categories/CreateServiceCategory'
import ServiceCategoriesList from './pages/settings/service-categories/ServiceCategoriesList'
import CreateTask from './pages/admin/tasks/CreateTask'
import TaskPage from './pages/admin/tasks/TaskPage'
import EditTask from './pages/admin/tasks/EditTask'
import RejectTask from './pages/admin/tasks/RejectTask'
import Login from './pages/auth/Login'
import Register from './pages/auth/register/Register'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import VerifyEmailCallback from './pages/auth/VerifyEmailCallback'
import Refresh from './pages/auth/Refresh'
import { Navigate } from 'react-router-dom'
import RegisterDetails from './pages/auth/register/RegisterDetails'
import RegisterVerifyEmail from './pages/auth/register/RegisterVerifyEmail'
import ProductPage from './pages/shop/products/ProductPage'
import AdminVendorPage from './pages/admin/wholesale-vendors/AdminVendorPage'
import OrderPage from './pages/shop/orders/OrderPage'
import CustomProductsList from './pages/admin/wholesale-vendors/CustomProductsList'
import CreateCustomProduct from './pages/admin/wholesale-vendors/CreateCustomProduct'
import TempPayoutValidationList from './pages/admin/wholesale-payouts/TempPayoutValidationList'
import PasswordsList from './pages/more/passwords/PasswordsList'
import CreatePassword from './pages/more/passwords/CreatePassword'
import EditPassword from './pages/more/passwords/EditPassword'
import PasswordHistory from './pages/more/passwords/PasswordHistory'
import EditProfileVendor from './pages/admin/clients/EditProfileVendor'
import NotifsList from './pages/admin/notifs/NotifsList'
import TranslationsList from './pages/admin/translations/TranslationsList'
import VendorSettings from './pages/admin/clients/VendorSettings'
import PdfContractsList from './pages/more/pdf-contracts/PdfContractsList'
import EditPdfContract from './pages/more/pdf-contracts/EditPdfContract'
import EditProfileRetailBuyer from './pages/admin/clients/EditProfileRetailBuyer'
import AdminCreateShopifyShop from './pages/shop/shops/AdminCreateShopifyShop'
import FacebookLoginCallback from './pages/admin/settings/FacebookLoginCallback'
import ContentOverview from './pages/content/ContentOverview'
import CreateShopifyShop from './pages/shop/shops/CreateShopifyShop'
import EditShopifyShop from './pages/shop/shops/EditShopifyShop'
import ShortLinkCallback from './pages/admin/shortlinks/ShortLinkCallback'
import TempIowaCalendarEmbed from './pages/admin/embeds/TempIowaCalendarEmbed'
import EditSocialPostPlanHashtags from './pages/content/social-plans/EditSocialPostPlanHashtags'
import SocialPostPlanLog from './pages/content/social-plans/SocialPostPlanLog'
import CreatePage from './pages/more/pages/CreatePage'
import PagesOverview from './pages/more/pages/PagesOverview'
import EditPage from './pages/more/pages/EditPage'
import Page from './pages/admin/pages/Page'
import PlainFormsList from './pages/more/plain-forms/PlainFormsList'
import PlainFormPage from './pages/more/plain-forms/PlainFormPage'
import UsersResourceList from './pages/contacts/ContactsOverview'
import Contact from './pages/more/contact-us/Contact'
import HtmlContractsList from './pages/more/html-contracts/HtmlContractsList'
import EditHtmlContract from './pages/more/html-contracts/EditHtmlContract'
import CreateHtmlContract from './pages/more/html-contracts/CreateHtmlContract'
import TaxForms from './pages/admin/clients/TaxForms'
import HtmlContractHistory from './pages/more/html-contracts/HtmlContractHistory'
import StripeIntentCompleteCallback from './pages/admin/app-billing/StripeIntentCompleteCallback'
import PurchasePremium from './pages/admin/app-billing/PurchasePremium'
import StripePremiumSubCompleteCallback from './pages/admin/app-billing/StripePremiumSubCompleteCallback'
import EditConfigObject from './pages/settings/misc/EditConfigObject'
import PremiumSubPage from './pages/admin/app-billing/PremiumSubPage'
import ResetPin from './pages/auth/ResetPin'
import EditTimesheet from './pages/admin/timesheet/EditTimesheet'
import TimesheetsList from './pages/admin/timesheet/TimesheetsList'
import AdminTimesheetsUserList from './pages/admin/timesheet/AdminTimesheetsUserList'
import AdminTimesheetPage from './pages/admin/timesheet/AdminTimesheetPage'
import EditContact from './pages/admin/contacts/EditContact'
import CreateContact from './pages/admin/clients/CreateContact'
import ProfilePage from './pages/user-profile/ProfilePage'
import ShopOverview from './pages/shop/ShopOverview'
import ResourcePermissions from './pages/admin/settings/ResourcePermissions'
import ResourceIndividualPermissions from './pages/admin/settings/ResourceIndividualPermissions'
import DonationCompleteCallback from './pages/admin/app-billing/DonationCompleteCallback'
import DonationsOverview from './pages/admin/donations/DonationsOverview'
import Dev from './pages/admin/Dev'
import Referrals from './pages/more/referrals/Referrals'
import Home from './pages/home/Home'
import BillingOverview from './pages/reports/BillingOverview'
import CreateBlog from './pages/content/blogs/CreateBlog'
import ArticlesList from './pages/content/blogs/ArticlesList'
import CreateArticle from './pages/content/blogs/CreateArticle'
import BulkCreateArticles from './pages/content/blogs/BulkCreateArticles'
import ArticlePage from './pages/content/blogs/ArticlePage'
import EditArticle from './pages/content/blogs/EditArticle'
import ScheduleArticle from './pages/content/blogs/ScheduleArticle'
import CreatePostPlan from './pages/content/social-plans/CreatePostPlan'
import EditSocialPostPlan from './pages/content/social-plans/EditSocialPostPlan'
import ShopifyProductTagsSetting from './pages/settings/wholesale/ShopifyProductTagsSetting'
import LogsList from './pages/more/logs/LogsList'
import ComponentsList from './pages/more/components/ComponentsList'
import ComponentPage from './pages/more/components/ComponentPage'
import ReposList from './pages/more/repos/ReposList'
import RepoPage from './pages/more/repos/RepoPage'
import ContactFormPage from './pages/more/contact-forms/ContactFormPage'
import ContactFormsList from './pages/more/contact-forms/ContactFormsList'
import CreateContactForm from './pages/more/contact-forms/CreateContactForm'
import EditContactForm from './pages/more/contact-forms/EditContactForm'
import CreateOrganization from './pages/more/organizations/CreateOrganization'
import OrganizationPage from './pages/more/organizations/OrganizationPage'
import AssignOrganizationMembers from './pages/more/organizations/AssignOrganizationMembers'
import EditCompanyMemberInfo from './pages/admin/clients/EditCompanyMemberInfo'
import EditProfileCompanyInfoEin from './pages/user-profile/EditProfileCompanyInfoEin'
import CreateAccounts from './pages/admin/clients/CreateAccounts'
import EditOrganization from './pages/more/organizations/EditOrganization'
import EditProduct from './pages/shop/products/EditProduct'
import CreateProduct from './pages/shop/products/CreateProduct'
import AppProductPage from './pages/shop/products/AppProductPage'
import ShopLocationsList from './pages/shop/shops/ShopLocationsList'
import CreateShopLocation from './pages/shop/shops/CreateShopLocation'
import EditProductQuantities from './pages/shop/products/EditProductQuantities'
import LocationInventoryItemsList from './pages/shop/shops/LocationInventoryItemsList'
import CustomLocationsList from './pages/settings/wholesale/CustomLocationsList'
import CreateCustomLocation from './pages/settings/wholesale/CreateCustomLocation'
import EditUserMetafields from './pages/user-profile/EditUserMetafields'
import ShopifyShopPage from './pages/shop/shops/ShopifyShopPage'
import ImportProductsOwn from './pages/shop/products/ImportProductsOwn'
import EditContactMetafields from './pages/user-profile/EditContactMetafields'
import InviteOrganizationMember from './pages/more/organizations/InviteOrganizationMember'
import AcceptOrgInviteCallback from './pages/more/organizations/AcceptOrgInviteCallback'
import UserPagesOverview from './pages/more/pages/UserPagesOverview'
import CreateAppMetafieldDefinition from './pages/settings/metafields/CreateAppMetafieldDefinition'
import AdminChat from './pages/more/chat/AdminChat'
import ChatsList from './pages/more/chat/ChatsList'
import MenuTiles from './pages/settings/misc/MenuTiles'
import RequestFeature from './pages/admin/app-billing/RequestFeature'
import EditSegments from './pages/more/organizations/EditSegments'
import { getUserDisplayName } from './common/helpers'
import fetchAPI from './common/fetchAPI'
import SidebarItems from './pages/settings/misc/SidebarItems'

export const publicRoutes = [
    {
        url: 'login',
        component: <Login />,
        public: true,
    },
    {
        url: 'forgot-password',
        component: <ForgotPassword />,
        public: true,
    },
    {
        url: 'reset-password',
        component: <ResetPassword />,
        public: true,
    },
    {
        url: 'register',
        component: <Register />,
        public: true,
        registrationFlow: true,
    },
    {
        url: 's/:shortCode',
        component: <ShortLinkCallback />,
        public: true,
    },
]

export const userRoutes = [
    {
        url: 'profile',
        component: <ProfilePage />,
        title: 'Profile',
        staff: true,
        client: true,
        backUrl: '/',
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Profile' }]
        },
    },
    {
        url: 'users/:userId/edit',
        component: <EditContact />,
        title: 'Edit Profile',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params, auth }) => {
            if (!params || !auth) {
                return []
            }
            if (auth.user?.id === params.userId) {
                return [
                    { title: 'Home', url: '/' },
                    { title: 'Profile', url: '/profile' },
                    { title: 'Edit' },
                ]
            }
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'contacts/:contactId/edit',
        component: <EditContact />,
        title: 'Edit Profile',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            if (!params) {
                return []
            }
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getContactName(params.contactId),
                    url: `/contacts/${params.contactId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'profile/accounts/new',
        component: <CreateAccounts />,
        title: 'Accounts',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Create accounts' }]
        },
    },
    {
        // TODO
        url: 'profile/company-ein/edit',
        component: <EditProfileCompanyInfoEin />,
        title: 'Company info',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'EIN' }]
        },
    },
    {
        url: 'profile/vendor/edit',
        component: <EditProfileVendor />,
        title: 'Vendor account request',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Vendor' }]
        },
    },

    {
        url: 'profile/retail-buyer/edit',
        component: <EditProfileRetailBuyer />,
        title: 'Retail buyer info',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Retail buyer' }]
        },
    },
    {
        url: 'request-feature',
        component: <RequestFeature />,
        title: 'Feature request',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Reports' }]
        },
    },
    {
        url: 'contacts-request-feature',
        component: <RequestFeature />,
        title: 'Feature request',
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Feature request' },
            ]
        },
    },
    {
        url: 'all-pages-request-feature',
        component: <RequestFeature />,
        title: 'Feature request',
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Pages', url: '/all-pages' },
                { title: 'Feature request' },
            ]
        },
    },
    {
        url: 'contacts/:contactId/contact-request-feature',
        component: <RequestFeature />,
        title: 'Feature request',
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getContactName(params.contactId),
                    url: `/contacts/${params.contactId}`,
                },
                { title: 'Feature request' },
            ]
        },
    },
    {
        url: 'profile/billing/purchase-premium',
        component: <PurchasePremium />,
        title: 'Premium',
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Premium' }]
        },
    },
    {
        url: 'profile/billing/premium',
        component: <PremiumSubPage />,
        title: 'Premium',
        client: true,
        backUrl: '/profile',
        getBreadcrumbs: () => {
            return [{ title: 'Profile', url: '/profile' }, { title: 'Premium' }]
        },
    },
    // {
    //     url: 'profile/products-questions/edit',
    //     component: <VendorQuestions />,
    //     title: 'Products & brand info',
    //     client: true,
    //     backUrl: '/profile',
    // },
    {
        url: 'profile/tax-forms/edit',
        component: <TaxForms />,
        title: 'Tax forms',
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Profile', url: '/profile' },
                { title: 'Tax forms' },
            ]
        },
    },
    {
        url: 'profile/company-member-info/edit',
        component: <EditCompanyMemberInfo />,
        title: 'Company member info',
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Profile', url: '/profile' },
                { title: 'Member info' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/new',
        component: <CreateShopifyShop />,
        title: 'New Shopify shop',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: 'New shop' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId',
        component: <ShopifyShopPage />,
        title: 'Shop',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: await getShopName(params.shopifyShopId) },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/edit',
        component: <EditShopifyShop />,
        title: 'Edit Shopify shop',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: await getShopName(params.shopifyShopId) },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations',
        component: <ShopLocationsList />,
        title: 'Locations',
        // staff: true,
        // client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: await getShopName(params.shopifyShopId) },
                { title: 'Locations' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations/new',
        component: <CreateShopLocation />,
        title: 'Locations',
        // staff: true,
        // client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: await getShopName(params.shopifyShopId) },
                {
                    title: 'Locations',
                    url: `/shop/shopify-shops/${params.shopifyShopId}/locations`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'settings/custom-locations/:locationId/inventory-items',
        component: <LocationInventoryItemsList />,
        title: 'Location inventory items',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Settings', url: '/settings' },
                {
                    title: 'Custom locations',
                    url: '/settings/custom-locations',
                },
                {
                    title: await getCustomLocationName(params.locationId),
                    url: '/settings/custom-locations',
                },
                { title: 'Inventory items' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/locations/:locationId/inventory-items',
        component: <LocationInventoryItemsList />,
        title: 'Location inventory items',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                { title: await getShopName(params.shopifyShopId) },
                {
                    title: await getCustomLocationName(params.locationId),
                    url: '/settings/custom-locations',
                },
                { title: 'Inventory items' },
            ]
        },
    },
    {
        url: 'shop/shopify-shops/:shopifyShopId/products/import',
        component: <ImportProductsOwn />,
        title: 'Import products',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Shopify shops', url: '/shop#setup__' },
                {
                    title: await getShopName(params.shopifyShopId),
                    url: `/shop/shopify-shops/${params.shopifyShopId}#products`,
                },
                {
                    title: 'Products',
                    url: `/shop/shopify-shops/${params.shopifyShopId}#products`,
                },
                {
                    title: 'Import',
                },
            ]
        },
    },
    // {
    //     url: 'shop/shopify-shops/:shopId/blogs/:blogId',
    //     component: <ArticlesList />,
    //     title: 'Blog posts',
    //     client: true,
    //     staff: true,
    // },
    {
        url: 'showroom/products/new',
        component: <CreateProduct />,
        title: 'New product',
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Shop', url: '/shop#shopify_inventory' },
                { title: 'Shopify inventory', url: '/shop#shopify_inventory' },
                {
                    title: 'New product',
                },
            ]
        },
    },
    {
        url: 'showroom/products/:shop/:productId',
        component: <ProductPage />,
        title: 'Product',
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#shopify_inventory' },
                { title: 'Shopify inventory', url: '/shop#shopify_inventory' },
                {
                    title: await getGraphQLProductName(
                        params.shop,
                        params.productId,
                    ),
                },
            ]
        },
    },
    // {
    //     url: 'showroom/products/:shop/:productId/edit',
    //     component: <EditShopifyGraphQLProduct />,
    //     title: 'Product',
    //     client: true,
    // },
    {
        url: 'register/verify-email',
        component: <RegisterVerifyEmail />,
        client: true,
        registrationFlow: true,
    },
    {
        url: 'register/details',
        component: <RegisterDetails />,
        client: true,
        registrationFlow: true,
    },
    {
        url: 'events',
        component: <TempIowaCalendarEmbed />,
        client: true,
        staff: true,
    },
    {
        url: 'donate',
        title: 'Donations',
        component: <DonationsOverview />,
        client: true,
        staff: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Profile', url: '/profile' },
                { title: 'Donations' },
            ]
        },
    },
    {
        url: 'contactus',
        title: 'Contact us',
        component: <Contact />,
        client: true,
        staff: true,
        mixedAuth: true,
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Contact' }]
        },
    },
]

export const adminRoutes = [
    {
        url: '/',
        component: <Home />,
        staff: true,
        client: true,
        title: 'Dashboard',
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }]
        },
    },
    // CONTACTS
    // Lists
    {
        url: 'contacts',
        component: <UsersResourceList />,
        title: 'Contacts',
        staff: true,
        client: true,
        backUrl: '/',
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Contacts' }]
        },
    },
    // Create
    {
        url: 'contacts/new',
        component: <CreateContact />,
        title: 'New contact',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Contacts', url: '/contacts' }, { title: 'New' }]
        },
    },
    // Page
    {
        url: 'users/:userId',
        component: <ProfilePage />,
        staff: true,
        title: 'Profile',
        getTitle: ({ params }) => {
            return getUserName(params.userId)
        },
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: await getUserName(params.userId) },
            ]
        },
    },
    {
        url: 'contacts/:contactId',
        component: <ProfilePage />,
        title: 'Contact',
        getTitle: ({ params }) => {
            return getContactName(params.contactId)
        },
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: await getContactName(params.contactId) },
            ]
        },
    },
    // Edit
    {
        url: 'contacts/:contactId/profile/edit',
        component: <EditContact />,
        title: 'Edit contact',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getContactName(params.contactId),
                    url: `/contacts/${params.contactId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'users/:userId/metafields',
        component: <EditUserMetafields />,
        title: 'Metafields',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}`,
                },
                { title: 'Metafields' },
            ]
        },
    },
    {
        url: 'contacts/:contactId/metafields',
        component: <EditContactMetafields />,
        title: 'Metafields',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getContactName(params.contactId),
                    url: `/contacts/${params.contactId}`,
                },
                { title: 'Metafields' },
            ]
        },
    },
    {
        url: 'contact-forms',
        component: <ContactFormsList />,
        title: 'Contact forms',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Contact forms' }]
        },
    },
    {
        url: 'contact-forms/new',
        component: <CreateContactForm />,
        title: 'Create contact form',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Contact forms', url: '/contact-forms' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'contact-forms/:contactFormId',
        component: <ContactFormPage />,
        title: 'Contact form',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Contact forms', url: '/contact-forms' },
                {
                    title: await getContactFormTitle(params.contactFormId),
                },
            ]
        },
    },
    {
        url: 'contact-forms/:contactFormId/edit',
        component: <EditContactForm />,
        title: 'Edit contact form',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Contact forms', url: '/contact-forms' },
                {
                    title: await getContactFormTitle(params.contactFormId),
                    url: `/contact-forms/${params.contactFormId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'repos',
        component: <ReposList />,
        title: 'Repos',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Repos' }]
        },
    },
    {
        url: 'repos/:repoName',
        component: <RepoPage />,
        title: 'Repo',
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Repos', url: '/repos' },
                { title: params.repoName },
            ]
        },
    },
    {
        url: 'storybook',
        component: <ComponentsList />,
        title: 'Components',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Components' }]
        },
    },
    {
        url: 'storybook/:componentName',
        component: <ComponentPage />,
        title: 'Component',
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Components', url: '/storybook' },
                { title: params.componentName },
            ]
        },
    },
    {
        url: 'referrals',
        component: <Referrals />,
        staff: true,
        client: true,
        title: 'Referrals Program',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Referrals' }]
        },
    },
    {
        url: 'settings',
        component: <Settings />,
        staff: true,
        client: true,
        title: 'Settings',
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Settings' }]
        },
    },
    {
        url: 'reset-pin',
        component: <ResetPin />,
        staff: true,
        client: true,
    },
    {
        url: 'settings/shopify-shops/new',
        component: <AdminCreateShopifyShop />,
        title: 'New Shop',
        getBreadcrumbs: () => {
            return [
                { title: 'Shop', url: '/shop#setup__' },
                { title: 'Setup', url: '/shop#setup__' },
                { title: 'New app shop' },
            ]
        },
    },
    {
        url: 'settings/shopify-product-tags',
        component: <ShopifyProductTagsSetting />,
        title: 'Available product tags',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Product tags' },
            ]
        },
    },
    {
        url: 'settings/custom-locations',
        component: <CustomLocationsList />,
        title: 'Custom locations',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Custom locations' },
            ]
        },
    },
    {
        url: 'settings/custom-locations/new',
        component: <CreateCustomLocation />,
        title: 'New custom location',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                {
                    title: 'Custom locations',
                    url: '/settings/custom-locations',
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'settings/menu-tiles',
        component: <MenuTiles />,
        title: 'Menu tiles',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Menu tiles' },
            ]
        },
    },
    {
        url: '/settings/sidebar-items',
        component: <SidebarItems />,
        title: 'Sidebar menu',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Sidebar menu' },
            ]
        },
    },
    {
        url: 'settings/sidebar-items',
        component: <SidebarItems />,
        title: 'Sidebar items',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Sidebar items' },
            ]
        },
    },
    {
        url: 'settings/config',
        component: <EditConfigObject />,
        title: 'Edit config',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Config object' },
            ]
        },
    },
    {
        url: 'metafields/app-definitions/new',
        component: <CreateAppMetafieldDefinition />,
        title: 'App metafield definition',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'New app metafield' },
            ]
        },
    },
    {
        url: 'logs',
        component: <LogsList />,
        title: 'Logs',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Logs' }]
        },
    },
    {
        url: 'notifications',
        component: <NotifsList />,
        title: 'Notifications',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Notifications' }]
        },
    },
    {
        url: 'timesheets',
        component: <TimesheetsList />,
        title: 'Timesheets',
        staff: true,
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Timesheets' }]
        },
    },
    {
        url: 'users/:userId/timesheets',
        component: <TimesheetsList />,
        title: 'Timesheets',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Timesheets', url: '/timesheets-users' },
                { title: await getUserName(params.userId) },
            ]
        },
    },
    {
        url: 'users/:userId/timesheets/:timesheetStartStr',
        component: <AdminTimesheetPage />,
        title: 'Timesheets',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Timesheets', url: '/timesheets-users' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}/timesheets`,
                },
                { title: params.timesheetStartStr },
            ]
        },
    },
    {
        url: 'timesheets-users',
        component: <AdminTimesheetsUserList />,
        title: 'Timesheets users',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Timesheets' }]
        },
    },
    {
        url: 'timesheets/:timesheetStartStr',
        component: <EditTimesheet />,
        title: 'Timesheet',
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Timesheets', url: '/timesheets' },
                { title: params.timesheetStartStr },
            ]
        },
    },
    {
        url: 'translations',
        component: <TranslationsList />,
        title: 'Translations',
        getBreadcrumbs: () => {
            return [
                { title: 'Settings', url: '/settings' },
                { title: 'Translations' },
            ]
        },
    },

    {
        url: 'more',
        title: 'More',
        component: <Menu />,
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'More' }]
        },
    },
    {
        url: 'content',
        component: <ContentOverview />,
        title: 'Content',
        client: true,
        staff: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Content' }]
        },
    },
    {
        url: 'social/plans/new',
        component: <CreatePostPlan />,
        title: 'Posting plan',
        client: true,
        socialEditor: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Home', url: '/' },
                { title: 'Content', url: '/content#shop_post_plans__' },
                { title: 'Plans', url: '/content#shop_post_plans__' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'social/plans/:socialPostPlanId/edit',
        component: <EditSocialPostPlan />,
        title: 'Posting plan',
        client: true,
        socialEditor: true,
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'Home', url: '/' },
                { title: 'Content', url: '/content#shop_post_plans__' },
                { title: 'Plans', url: '/content#shop_post_plans__' },
                {
                    title: `...${params.socialPostPlanId.slice(-5)}`,
                },
            ]
        },
    },
    {
        url: 'social/plans/:socialPostPlanId/hashtags',
        component: <EditSocialPostPlanHashtags />,
        title: 'Hashtags',
        client: true,
        socialEditor: true,
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'Home', url: '/' },
                { title: 'Content', url: '/content#shop_post_plans__' },
                { title: 'Plans', url: '/content#shop_post_plans__' },
                {
                    title: `...${params.socialPostPlanId.slice(-5)}`,
                },
                { title: 'Hashtags' },
            ]
        },
    },
    {
        url: 'social/plans/:socialPostPlanId/log',
        component: <SocialPostPlanLog />,
        title: 'Posting log',
        client: true,
        socialEditor: true,
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'Home', url: '/' },
                { title: 'Content', url: '/content#shop_post_plans__' },
                { title: 'Plans', url: '/content#shop_post_plans__' },
                {
                    title: `...${params.socialPostPlanId.slice(-5)}`,
                },
                { title: 'Log' },
            ]
        },
    },
    {
        url: '/content/blogs/new',
        component: <CreateBlog />,
        title: 'New blog',
        client: true,
        staff: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                { title: 'New' },
            ]
        },
    },
    {
        url: '/content/blogs/:shopId/:blogId',
        component: <ArticlesList />,
        title: 'Blog posts',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                { title: await getBlogName(params.shopId, params.blogId) },
            ]
        },
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/new',
        component: <CreateArticle />,
        title: 'New blog post',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                {
                    title: await getShortBlogName(params.shopId, params.blogId),
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: 'Posts',
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/bulk-create',
        component: <BulkCreateArticles />,
        title: 'Bulk blog posts',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                {
                    title: await getShortBlogName(params.shopId, params.blogId),
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: 'Posts',
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                { title: 'Bulk' },
            ]
        },
    },
    {
        url: '/settings/permissions/:resource/:id',
        component: <ResourcePermissions />,
        title: 'Permissions',
        client: true,
        staff: true,
    },
    {
        url: '/settings/permissions/:resource/:id/:permission/user',
        component: <ResourceIndividualPermissions />,
        title: 'User permissions',
        client: true,
        staff: true,
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId',
        component: <ArticlePage />,
        title: 'Blog post',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                {
                    title: await getShortBlogName(params.shopId, params.blogId),
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: 'Posts',
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: await getArticleTitle(
                        params.shopId,
                        params.blogId,
                        params.articleId,
                    ),
                },
            ]
        },
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId/edit',
        component: <EditArticle />,
        title: 'Edit post',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                {
                    title: await getShortBlogName(params.shopId, params.blogId),
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: 'Posts',
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: await getArticleTitle(
                        params.shopId,
                        params.blogId,
                        params.articleId,
                    ),
                    url: `/content/blogs/${params.shopId}/${params.blogId}/articles/${params.articleId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: '/content/blogs/:shopId/:blogId/articles/:articleId/schedule',
        component: <ScheduleArticle />,
        title: 'Schedule publishing',
        client: true,
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Content', url: '/content#blogs' },
                { title: 'Blogs', url: '/content#blogs' },
                {
                    title: await getShortBlogName(params.shopId, params.blogId),
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: 'Posts',
                    url: `/content/blogs/${params.shopId}/${params.blogId}`,
                },
                {
                    title: await getArticleTitle(
                        params.shopId,
                        params.blogId,
                        params.articleId,
                    ),
                    url: `/content/blogs/${params.shopId}/${params.blogId}/articles/${params.articleId}`,
                },
                { title: 'Schedule' },
            ]
        },
    },
    {
        url: 'billing',
        component: <BillingOverview />,
        title: 'Billing',
        collections: true,
        accountant: true,
        assistant: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Billing' }]
        },
    },
    {
        url: 'users/:userId/settings',
        component: <ClientSettings />,
        title: 'Client settings',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}`,
                },
                { title: 'Settings' },
            ]
        },
    },
    {
        url: 'users/:userId/vendor-settings',
        component: <VendorSettings />,
        title: 'Vendor settings',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#vendors' },
                { title: 'Vendors', url: '/contacts#vendors' },
                {
                    title: await getUserName(params.userId),
                    url: `/shop/db-vendors/${params.userId}`,
                },
                { title: 'Settings' },
            ]
        },
    },
    // {
    //     url: 'accounts/vendors/:accountId/settings',
    //     component: <VendorSettings />,
    //     title: 'Vendor settings',
    //     assistant: true,
    // },
    // {
    //     url: 'organizations',
    //     component: <OrganizationsList />,
    //     title: 'Organizations',
    //     assistant: true,
    // },
    {
        url: 'organizations/new',
        component: <CreateOrganization />,
        title: 'New organization',
        assistant: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Organizations' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'companies/:organizationId',
        component: <OrganizationPage />,
        title: 'Company',
        staff: true,
        client: true,
        getTitle: async ({ params }) => {
            return await getOrganizationName(params.organizationId)
        },
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#companies' },
                { title: 'Companies', url: '/contacts#companies' },
                { title: await getOrganizationName(params.organizationId) },
            ]
        },
    },
    {
        url: 'organizations/:organizationId',
        component: <OrganizationPage />,
        title: 'Organization',
        staff: true,
        client: true,
        getTitle: async ({ params }) => {
            return await getOrganizationName(params.organizationId)
        },
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Organizations' },
                { title: await getOrganizationName(params.organizationId) },
            ]
        },
    },
    {
        url: 'companies/:companyId/edit',
        component: <EditContact />,
        title: 'Edit group',
        staff: true,
        user: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#companies' },
                { title: 'Companies', url: '/contacts#companies' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/companies/${params.organizationId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'groups/:organizationId/edit',
        component: <EditOrganization />,
        title: 'Edit group',
        staff: true,
        user: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#groups' },
                { title: 'Groups', url: '/contacts#groups' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/organizations/${params.organizationId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'families/:organizationId/edit',
        component: <EditOrganization />,
        title: 'Edit family',
        staff: true,
        user: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#families' },
                { title: 'Families', url: '/contacts#families' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/organizations/${params.organizationId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'organizations/:organizationId/members',
        component: <AssignOrganizationMembers />,
        title: 'Organization members',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Organizations' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/organizations/${params.organizationId}`,
                },
                { title: 'Members' },
            ]
        },
    },
    {
        url: 'organizations/:organizationId/segments/edit',
        component: <EditSegments />,
        title: 'Segments',
        staff: true,
        user: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Organizations' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/organizations/${params.organizationId}`,
                },
                { title: 'Segments' },
            ]
        },
    },
    {
        url: 'organizations/:organizationId/members/invite',
        component: <InviteOrganizationMember />,
        title: 'Invite member',
        assistant: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                { title: 'Organizations' },
                {
                    title: await getOrganizationName(params.organizationId),
                    url: `/organizations/${params.organizationId}`,
                },
                { title: 'Invite' },
            ]
        },
    },
    {
        url: 'pdf-contracts',
        component: <PdfContractsList />,
        title: 'PDF Contracts',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'PDF Contracts' }]
        },
    },
    {
        url: 'pdf-contracts/edit-addendum/:fileName',
        component: <EditPdfContract />,
        title: 'Edit addendum',
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'PDF Contracts', url: '/pdf-contracts' },
                { title: params.fileName },
                { title: 'Addendum' },
            ]
        },
    },
    {
        url: 'html-contracts',
        component: <HtmlContractsList />,
        title: 'HTML contracts',
        getBreadcrumbs: () => {
            return [
                { title: 'More', url: '/more' },
                { title: 'HTML Contracts' },
            ]
        },
    },
    {
        url: 'html-contracts/new',
        component: <CreateHtmlContract />,
        title: 'Create contract',
        backUrl: '/html-contracts',
        getBreadcrumbs: () => {
            return [
                { title: 'More', url: '/more' },
                { title: 'HTML Contracts', url: '/html-contracts' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'html-contracts/:contractId/edit',
        component: <EditHtmlContract />,
        title: 'Edit contract',
        backUrl: '/html-contracts',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'HTML Contracts', url: '/html-contracts' },
                { title: await getHtmlContractTitle(params.contractId) },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'html-contracts/:contractId/history',
        component: <HtmlContractHistory />,
        title: 'Contract history',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'HTML Contracts', url: '/html-contracts' },
                {
                    title: await getHtmlContractTitle(params.contractId),
                    url: `/html-contracts/${params.contractId}/edit`,
                },
                { title: 'History' },
            ]
        },
    },
    {
        url: 'users/:userId/subscriptions/new',
        title: 'New subscription',
        component: <CreateSub />,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                {
                    title: 'Subscriptions',
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId',
        accountant: true,
        component: <SubPage />,
        title: 'Subscription',
        // TODO

        // getBreadcrumbs: async ({ params }) => {
        //     return [
        //         { title: 'Contacts', url: '/contacts' },
        //         {
        //             title: await getUserName(params.userId),
        //             url: `/users/${params.userId}?t_title=Billing`,
        //         },
        //         {
        //             title: 'Subscriptions',
        //             url: `/users/${params.userId}?t_title=Billing`,
        //         },
        //         {
        //             title: await getSubName(
        //                 params.userId,
        //                 params.source,
        //                 params.subId,
        //             ),
        //         },
        //     ]
        // },
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/edit',
        component: <EditSub />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices',
        accountant: true,
        component: <InvoicesList />,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices/:invoiceId',
        component: <InvoicePage />,
        accountant: true,
        lateFeeAssistant: true,
    },
    {
        url: 'users/:userId/subscriptions/:source/:subId/invoices/:invoiceId/edit',
        component: <EditInvoice />,
    },
    {
        url: 'services/:source/:serviceId',
        accountant: true,
        component: <ServicePage />,
    },
    {
        url: 'service-categories',
        component: <ServiceCategoriesList />,
        accountant: true,
        title: 'Service categories',
    },
    {
        url: 'service-categories/new',
        component: <CreateServiceCategory />,
        title: 'New service category',
    },
    {
        url: 'services/:serviceId/prices/new',
        component: <CreatePrice />,
    },
    {
        url: 'users/:userId/invoices',
        accountant: true,
        component: <InvoicesList />,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                {
                    title: 'Invoices',
                },
            ]
        },
    },
    {
        url: 'users/:userId/invoices/new',
        component: <CreateInvoice />,
        title: 'New invoice',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                {
                    title: 'Invoices',
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'users/:userId/invoices/:source/:invoiceId',
        component: <InvoicePage />,
        title: 'Invoice',
        accountant: true,
        lateFeeAssistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                {
                    title: 'Invoices',
                    url: `/users/${params.userId}?t_title=Billing`,
                },
                { title: params.invoiceId },
            ]
        },
    },
    {
        url: 'expenses/new',
        component: <CreateExpense />,
        title: 'New expense',
        getBreadcrumbs: () => {
            return [
                { title: 'Billing', url: '/billing#expenses' },
                { title: 'Expenses', url: '/billing#expenses' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'expenses/:expenseId',
        accountant: true,
        component: <ExpensePage />,
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'Billing', url: '/billing#expenses' },
                { title: 'Expenses', url: '/billing#expenses' },
                { title: `...${params.expenseId.slice(-5)}` },
            ]
        },
    },
    {
        url: 'services/new',
        component: <CreateService />,
        title: 'New service',
        getBreadcrumbs: () => {
            return [
                { title: 'Billing', url: '/billing#services' },
                { title: 'Services', url: '/billing#services' },
                { title: 'New' },
            ]
        },
    },

    {
        url: 'team-members/:teamMemberId/assign',
        component: <CreateClientAssignment />,
        title: 'Assign clients',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#team' },
                { title: 'Team', url: '/contacts#team' },
                {
                    title: await getUserName(params.teamMemberId),
                    url: `/users/${params.teamMemberId}?t_title=Assigned+clients`,
                },
                { title: 'Assign clients' },
            ]
        },
    },
    {
        url: 'tasks/new',
        component: <CreateTask />,
        title: 'New task',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'Tasks' }, { title: 'New' }]
        },
    },
    {
        url: 'tasks/:taskId',
        component: <TaskPage />,
        title: 'Task Details',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Tasks' },
                { title: await getTaskTitle(params.taskId) },
            ]
        },
    },
    {
        url: 'tasks/:taskId/edit',
        component: <EditTask />,
        title: 'Edit task',
        staff: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Tasks' },
                {
                    title: await getTaskTitle(params.taskId),
                    url: `/tasks/${params.taskId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'tasks/:taskId/reject',
        component: <RejectTask />,
        title: 'Reject task',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Tasks' },
                { title: await getTaskTitle(params.taskId) },
                { title: 'Reject' },
            ]
        },
    },
    {
        url: 'users/:userId/tasks/new',
        component: <CreateTask />,
        title: 'New task',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Tasks`,
                },
                {
                    title: 'Tasks',
                    url: `/users/${params.userId}?t_title=Tasks`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'users/:userId/tasks/:taskId',
        component: <TaskPage />,
        title: 'Task',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                {
                    title: 'Tasks',
                    url: `/users/${params.userId}?t_title=Tasks`,
                },
                { title: await getTaskTitle(params.taskId) },
            ]
        },
    },
    {
        url: 'users/:userId/tasks/:taskId/edit',
        component: <EditTask />,
        title: 'Edit task',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                {
                    title: 'Tasks',
                    url: `/users/${params.userId}?t_title=Tasks`,
                },
                {
                    title: await getTaskTitle(params.taskId),
                    url: `/tasks/${params.taskId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'shop/products/new',
        component: <CreateProduct />,
        title: 'Create product',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Shop', url: '/shop#inventory' },
                { title: 'Products', url: '/shop#inventory' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'shop/products/:shop/:productId',
        component: <ProductPage />,
        title: 'Inventory details',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#shopify_inventory' },
                { title: 'Products', url: '/shop#shopify_inventory' },
                {
                    title: params.shop,
                },
                {
                    title: await getGraphQLProductTitle(
                        params.productId,
                        params.shop,
                    ),
                },
            ]
        },
    },
    {
        url: 'shop/orders/:dbOrderId',
        component: <OrderPage />,
        title: 'Order',
        accountant: true,
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#orders' },
                { title: 'Orders', url: '/shop#orders' },
                {
                    title: await getOrderName(params.dbOrderId),
                },
            ]
        },
    },
    {
        url: 'shop',
        component: <ShopOverview />,
        title: 'Shop',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'Home', url: '/' },
                { title: 'Shop', url: '/shop' },
            ]
        },
    },
    {
        url: 'shop/products/:productId',
        component: <AppProductPage />,
        title: 'Product Details',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#inventory' },
                { title: 'Products', url: '/shop#inventory' },
                {
                    title: await getProductTitle(params.productId),
                },
            ]
        },
    },
    {
        url: 'shop/products/:productId/edit',
        component: <EditProduct />,
        title: 'Edit product',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#inventory' },
                { title: 'Products', url: '/shop#inventory' },
                {
                    title: await getProductTitle(params.productId),
                    url: `/shop/products/${params.productId}`,
                },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'shop/products/:productId/quantities/edit',
        component: <EditProductQuantities />,
        title: 'Product quantities',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Shop', url: '/shop#inventory' },
                { title: 'Products', url: '/shop#inventory' },
                {
                    title: await getProductTitle(params.productId),
                    url: `/shop/products/${params.productId}`,
                },
                { title: 'Quantities' },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'temp/payout-validation',
        component: <TempPayoutValidationList />,
        title: 'Orders (raw)',
        accountant: true,
    },
    {
        url: 'shop/db-vendors/:userId',
        component: <AdminVendorPage />,
        title: 'Vendor',
        accountant: true,
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Contacts', url: '/contacts#vendors' },
                { title: 'Vendors', url: '/contacts#vendors' },
                {
                    title: await getUserName(params.userId),
                    url: `/users/${params.userId}?t_title=Accounts`,
                },
                {
                    title: 'Accounts',
                    url: `/users/${params.userId}?t_title=Accounts`,
                },
                { title: 'Vendor' },
            ]
        },
    },
    {
        url: 'accounts/vendors/:accountId/custom-products',
        component: <CustomProductsList />,
        title: 'Custom products',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                {
                    title: 'Accounts',
                },
                { title: 'Vendors' },
                { title: await getAccountCompanyName(params.accountId) },
                {
                    title: 'Custom products',
                },
            ]
        },
    },
    {
        url: 'accounts/vendors/:accountId/custom-products/new',
        component: <CreateCustomProduct />,
        title: 'New custom product',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                {
                    title: 'Accounts',
                },
                { title: 'Vendors' },
                { title: await getAccountCompanyName(params.accountId) },
                {
                    title: 'Custom products',
                    url: `/accounts/vendors/${params.accountId}/custom-products`,
                },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'vault',
        component: <PasswordsList />,
        title: 'Passwords Vault',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Vault' }]
        },
    },
    {
        url: 'passwords/new',
        component: <CreatePassword />,
        title: 'New password',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Vault', url: '/vault' },
                { title: 'New' },
            ]
        },
    },
    {
        url: 'passwords/:passwordId/edit',
        component: <EditPassword />,
        title: 'Edit password',
        staff: true,
        client: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Vault', url: '/vault' },
                { title: params.passwordId },
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'passwords/:passwordId/history',
        component: <PasswordHistory />,
        title: 'Password history',
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Vault', url: '/vault' },
                { title: params.passwordId },
                { title: 'History' },
            ]
        },
    },
    {
        url: 'all-pages',
        component: <UserPagesOverview />,
        title: 'Pages',
        client: true,
        staff: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Pages' }]
        },
    },
    {
        url: 'edit-pages/new',
        component: <CreatePage />,
        title: 'New page',
        assistant: true,
        getBreadcrumbs: () => {
            return [{ title: 'Pages', url: '/edit-pages' }, { title: 'New' }]
        },
    },
    {
        url: 'edit-pages/:pageId',
        component: <EditPage />,
        title: 'Edit page',
        assistant: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Pages', url: '/edit-pages' },
                // { title: await getAdminPageTitle(params.pageId) },
                ...(await getPageBreadcrumbs(params.pageId, true)),
                { title: 'Edit' },
            ]
        },
    },
    {
        url: 'edit-pages',
        component: <PagesOverview />,
        title: 'Pages',
        assistant: true,
        getBreadcrumbs: () => {
            return [{ title: 'Home', url: '/' }, { title: 'Pages' }]
        },
    },
    {
        url: 'pages/:slug',
        component: <Page />,
        mixedAuth: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Pages', url: '/all-pages' },
                ...(await getPageBreadcrumbs(params.slug)),
            ]
        },
    },
    {
        url: 'pages/:categorySlug/:slug',
        component: <Page />,
        mixedAuth: true,
        getBreadcrumbs: async ({ params }) => {
            return [
                { title: 'Pages', url: '/all-pages' },
                ...(await getPageBreadcrumbs(params.slug)),
            ]
        },
    },
    {
        url: 'plain-forms',
        component: <PlainFormsList />,
        title: 'Forms',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Forms' }]
        },
    },
    {
        url: 'plain-forms/:formId',
        component: <PlainFormPage />,
        title: 'Form',
        getBreadcrumbs: ({ params }) => {
            return [
                { title: 'More', url: '/more' },
                { title: 'Forms', url: '/plain-forms' },
                { title: params.formId },
            ]
        },
    },
    {
        url: 'admin-chat',
        component: <AdminChat />,
        title: 'Admin chat',
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Admin chat' }]
        },
    },
    {
        url: 'chats',
        component: <ChatsList />,
        title: 'Chats',
        staff: true,
        client: true,
        getBreadcrumbs: () => {
            return [{ title: 'More', url: '/more' }, { title: 'Chats' }]
        },
    },
    {
        url: 'dev',
        component: <Dev />,
        title: 'Dev',
        staff: true,
        client: true,
        hideFooter: true,
    },
]

export const routesHandleMap = new Map([
    ...userRoutes
        .filter((r) => !r.url.includes('/:'))
        .map((route) => [
            route.title
                ? route.title.toLowerCase().replace(/\s+/g, '_')
                : undefined,
            { ...route },
        ])
        .filter(([key]) => key !== undefined),
    ...adminRoutes
        .filter((r) => !r.url.includes('/:'))
        .map((route) => [
            route.title
                ? route.title.toLowerCase().replace(/\s+/g, '_')
                : undefined,
            { ...route },
        ])
        .filter(([key]) => key !== undefined),
])

export const callbackRoutes = [
    {
        url: 'verify-email-callback',
        component: <VerifyEmailCallback />,
        public: true,
    },
    {
        url: 'refresh',
        component: <Refresh />,
        public: true,
    },
    {
        url: 'callbacks/connected-account', // TODO test FB redirect on Stage
        component: (
            <Navigate
                to={sessionStorage.getItem('redirect_connect_account_url')}
            />
        ),
        client: true,
        staff: true,
    },
    {
        url: 'callbacks/facebook-login',
        component: <FacebookLoginCallback />,
        client: true,
        staff: true,
        backUrl: '/content#connect__',
    },
    {
        url: 'callbacks/stripe-intent-complete',
        component: <StripeIntentCompleteCallback />,
        client: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/stripe-premium-complete',
        component: <StripePremiumSubCompleteCallback />,
        client: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/donation-complete',
        component: <DonationCompleteCallback />,
        client: true,
        staff: true,
        backUrl: '/',
    },
    {
        url: 'callbacks/org-invite-accept',
        component: <AcceptOrgInviteCallback />,
        client: true,
        staff: true,
    },
]

async function getUserName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/users/${id}?fields[]=firstName&fields[]=lastName`,
    )
    if (error) {
        return '-'
    }
    return getUserDisplayName(responseData)
}

async function getContactName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/contacts/${id}?fields[]=firstName&fields[]=lastName`,
    )
    if (error) {
        return null
    }
    return getUserDisplayName(responseData)
}

async function getShopName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/shop/shops/${id}?fields[]=fullName`,
    )
    if (error) {
        return '-'
    }
    return responseData?.fullName || '-'
}

async function getCustomLocationName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/shop/locations/${id}?fields[]=name`,
    )
    if (error) {
        return '-'
    }
    return responseData?.name || '-'
}

async function getGraphQLProductName(shop, productId) {
    const { responseData, error } = await fetchAPI(
        `/v1/wholesale/products-graphql/${productId}?shop=${shop}`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getContactFormTitle(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/contact-forms/${id}?fields[]=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getBlogName(shopId, blogId) {
    const { responseData, error } = await fetchAPI(
        `/v1/blogs/${shopId}/${blogId}?fields=shop.fullName,title`,
    )
    if (error) {
        return '-'
    }
    return `${responseData?.title} (${responseData?.shop?.fullName})`
}

async function getShortBlogName(shopId, blogId) {
    const { responseData, error } = await fetchAPI(
        `/v1/blogs/${shopId}/${blogId}?fields=shop.fullName,title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getArticleTitle(shopId, blogId, articleId) {
    const { responseData, error } = await fetchAPI(
        `/v1/blogs/${shopId}/${blogId}/articles/${articleId}?fields=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getOrganizationName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/organizations/${id}?fields[]=name`,
    )
    if (error) {
        return '-'
    }
    return responseData?.name || '-'
}

async function getHtmlContractTitle(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/html-contracts/${id}?fields[]=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getSubName(userId, source, subId) {
    const { responseData, error } = await fetchAPI(
        `/v1/payment/clients/${userId}/subscriptions/${source}/${subId}?fields[]=name&fields[]=itemNames`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getTaskTitle(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/tasks/${id}?fields[]=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getGraphQLProductTitle(id, shop) {
    const { responseData, error } = await fetchAPI(
        `/v1/wholesale/products-graphql/${id}?fields=title&shop=${shop}`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getOrderName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/wholesale/db-orders/${id}?fields=orderNumber`,
    )
    if (error) {
        return '-'
    }
    return responseData?.orderNumber || '-'
}

async function getProductTitle(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/shop/products/${id}?fields=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getAccountCompanyName(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/accounts/${id}?fields=ownerId&populate=organization.$name&accountType=vendor`,
    )
    if (error) {
        return '-'
    }
    return responseData?.organization?.name || '-'
}

async function getAdminPageTitle(id) {
    const { responseData, error } = await fetchAPI(
        `/v1/pages/admin/${id}?fields=title`,
    )
    if (error) {
        return '-'
    }
    return responseData?.title || '-'
}

async function getPageBreadcrumbs(slugOrId, isId) {
    const { responseData, error } = await fetchAPI(
        `/v1/pages${isId ? '/admin' : ''}/page-info/${slugOrId}?fields[]=title&parentPages=true`,
    )
    if (error) {
        return [{ title: '-' }]
    }

    return [
        ...(responseData?.parentPages || []).map((page) => ({
            title: page.title,
            url: `/pages/${page.slug}`,
        })),
        { title: responseData?.title || '-' },
    ]
}

const routes = [
    ...publicRoutes,
    ...callbackRoutes,
    ...userRoutes,
    ...adminRoutes,
]

export default routes
