import { useEffect } from 'react'
import InlineStack from '../common/InlineStack'
import Spinner from '../common/Spinner'
import useToast from '../../common/hooks/useToast'
import Button from '../common/Button'

function ResourceListSelectedItems({
    loading,
    error,
    items,
    removeItem,
    getItemText,
    totalResults,
    setSelectedAll,
    selectedAll,
}) {
    const setToast = useToast()

    useEffect(
        function () {
            if (error) {
                setToast(error, 'alert')
            }
        },
        [error],
    )

    return (
        <div className="resource-list-selected-items-container">
            {loading && <Spinner size="tiny" />}

            {!loading && items.length === 0 && (
                <InlineStack spaceBetween>
                    <strong>
                        {!selectedAll ? `0 items selected` : <>&nbsp;</>}
                    </strong>
                    {setSelectedAll && !selectedAll && (
                        <Button
                            tiny
                            text={`Select all ${totalResults}`}
                            link
                            onClick={() => setSelectedAll(true)}
                        />
                    )}
                </InlineStack>
            )}

            {!loading && items.length > 0 && (
                <strong>{items.length} items selected</strong>
            )}

            {!!selectedAll && setSelectedAll && (
                <button
                    className="resource-list-selected-item"
                    type="button"
                    title="Remove"
                    onClick={() => setSelectedAll(false)}
                >
                    <InlineStack itemsCenter>
                        <span>Selected all {totalResults}</span>
                        <img src="/img/icons/x-white.svg" alt="close" />
                    </InlineStack>
                </button>
            )}

            {items.map((item, i) => (
                <button
                    className="resource-list-selected-item"
                    key={i}
                    type="button"
                    title="Remove"
                    onClick={() => removeItem(item)}
                >
                    <InlineStack itemsCenter>
                        <span>{getItemText(item)}</span>
                        <img src="/img/icons/x-white.svg" alt="close" />
                    </InlineStack>
                </button>
            ))}
        </div>
    )
}

export default ResourceListSelectedItems
