import {
    Link,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import ResourceList from '../../../components/admin/ResourceList'
import ContactCard from '../../admin/clients/ContactCard'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useAuth from '../../../common/hooks/useAuth'
import Tag from '../../../components/common/Tag'
import InlineStack from '../../../components/common/InlineStack'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useModal from '../../../common/hooks/useModal'
import MainButton from '../../../components/admin/MainButton'
import InlineStackItem from '../../../components/common/InlineStackItem'
import FavoriteInfo from '../../user-profile/FavoriteInfo'
import SectionContainer from '../../../components/common/SectionContainer'
import BlockStack from '../../../components/common/BlockStack'
import ContactThumbnail from '../../admin/clients/ContactThumbnail'
import InfoList from '../../../components/common/InfoList'
import AddressesList from '../../admin/addresses/AddressesList'
import Social from '../../user-profile/Social'
import Notes from '../../user-profile/Notes'
import Button from '../../../components/common/Button'
import BillingMethodInfo from '../../user-profile/BillingMethodInfo'
import OrgCard from '../../contacts/OrgCard'
import useUrlMessage from '../../../common/hooks/useUrlMessage'

export default function OrganizationPage() {
    const { organizationId } = useParams()
    const [searchParams] = useSearchParams()
    const isOwnProfile = searchParams.get('isOwnProfile')
    const navigate = useNavigate()
    const auth = useAuth()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    // Get the pathmane
    const location = useLocation()

    const {
        organization,
        organizationError,
        organizationLoading,
        organizationMutate,
    } = useData(
        `/v1/organizations/${organizationId}?fields=logoUrl,name,acronym,organizationType,members${
            auth.isAdmin || auth.isAssistant ? ',internalNotes,tags' : ''
        }&populate=members.user.email,members.user.firstName,members.user.lastName,companyInfo.membersInfo,companyInfo.ein,companyInfo.companyType,contactDetails,addresses,billingMethods`,
        'organization',
    )

    let editUrl = `/organizations/${organizationId}/edit`
    if (organization?.organizationType === 'company') {
        editUrl = `/companies/${organizationId}/edit?type=company`
    } else if (organization?.organizationType === 'family') {
        editUrl = `/families/${organizationId}/edit`
    } else if (organization?.organizationType === 'group') {
        editUrl = `/groups/${organizationId}/edit`
    }

    const createdMessageHtml = useUrlMessage(
        'created_message',
        <>
            Created new&nbsp;
            {location.pathname.includes('companies')
                ? 'company'
                : 'organization'}
            .&nbsp;<Link to={editUrl}>Edit</Link>.&nbsp;
            <Link to={'/contacts/new?type=company'}>Create another.</Link>
        </>,
    )

    async function changeRole(role, item) {
        const url = `/v1/organizations/${organizationId}/members/${item.id}`
        const { error } = await fetchAPI(url, { role }, 'PATCH')
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Changed role')
        organizationMutate()
    }

    async function handleRoleChange(role, item) {
        const showWarning =
            role === 'member' &&
            organization.members.length !== 1 &&
            !organization.members.some(
                (m) => m.id !== item.id && m.role === 'admin',
            )
        if (!showWarning) {
            await changeRole(role, item)
            return
        }

        setModal(
            <ConfirmModalContent
                text="This will leave the organization with no admins."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    await changeRole(role, item)
                    setModalLoading(false)
                    setModal(null)
                }}
            />,
            'Are you sure?',
        )
    }

    async function setPhonePreferred(phoneId) {
        const isAlreadyPreferred = contactDetails.phones.some(
            (p) => p.isPreferred && (p.id || p._id) === phoneId,
        )
        const { error } = await fetchAPI(
            `/v1/organizations/${organizationId}`,
            {
                contactDetails: {
                    phones: contactDetails.phones.map((p) => ({
                        id: p.id || p._id,
                        phone: p.phone,
                        phoneType: p.phoneType,
                        isPreferred:
                            !isAlreadyPreferred && (p.id || p._id) === phoneId,
                    })),
                },
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }

        organizationMutate()
    }

    async function setEmailPreferred(emailId) {
        const isAlreadyPreferred = contactDetails.emails.some(
            (e) => e.isPreferred && (e.id || e._id) === emailId,
        )
        const { error } = await fetchAPI(
            `/v1/organizations/${organizationId}`,
            {
                contactDetails: {
                    emails: contactDetails.emails.map((e) => ({
                        id: e.id || e._id,
                        email: e.email,
                        emailType: e.emailType,
                        isPreferred:
                            !isAlreadyPreferred && (e.id || e._id) === emailId,
                    })),
                },
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }

        organizationMutate()
    }

    if (organizationLoading) {
        return <Spinner />
    }

    if (organizationError) {
        return <ErrorMessage section>{organizationError}</ErrorMessage>
    }

    let canEdit = false
    if (!isOwnProfile) {
        canEdit = true
    } else {
        const member = organization.members.find(
            (m) => m.user.id === auth.user.id,
        )
        if (member.role === 'admin' || organization.members.length === 1) {
            canEdit = true
        }
    }

    const contactDetails = organization.contactDetails || {}

    return (
        <>
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <BlockStack gap={'tiny'}>
                        <InlineStack spaceBetween>
                            <OrgCard org={organization} />

                            <InlineStack>
                                <FavoriteInfo
                                    type={'Organization'}
                                    id={organizationId}
                                />

                                <ActionsDropdown
                                    actions={[
                                        ...(canEdit
                                            ? [
                                                  {
                                                      title: 'Edit',
                                                      onClick: () =>
                                                          navigate(editUrl),
                                                      icon: ICONS.EDIT_GRAY,
                                                  },
                                                  {
                                                      title: 'Segments',
                                                      onClick: () =>
                                                          navigate(
                                                              `/organizations/${organizationId}/segments/edit`,
                                                          ),
                                                  },
                                              ]
                                            : []),
                                    ]}
                                />
                            </InlineStack>
                        </InlineStack>

                        {(auth.isAdmin || auth.isAssistant) && (
                            <InlineStack wrap gap={'tiny'}>
                                {(organization.tags || []).map((l, i) => (
                                    <Tag key={i} noMargin outline>
                                        {l}
                                    </Tag>
                                ))}
                            </InlineStack>
                        )}
                    </BlockStack>
                </header>
            </SectionContainer>

            {createdMessageHtml && (
                <SectionContainer>{createdMessageHtml}</SectionContainer>
            )}

            {organization?.organizationType === 'company' && (
                <SectionContainer>
                    <Toggle title={'Company information'} small heading={'h2'}>
                        <BlockStack gap={'tiny'}>
                            <InlineStack itemsStart gap="sm">
                                <InlineStackItem shrink0>
                                    <div className="text-subdued">Type:</div>
                                </InlineStackItem>
                                <div>
                                    {organization.companyInfo.companyType ||
                                        'Unknown'}
                                </div>
                            </InlineStack>
                            {!!organization?.companyInfo?.ein && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">EIN:</div>
                                    </InlineStackItem>
                                    <div>{organization.companyInfo.ein}</div>
                                </InlineStack>
                            )}
                        </BlockStack>
                    </Toggle>
                </SectionContainer>
            )}

            <SectionContainer>
                <Toggle
                    open={!auth.isAdmin}
                    title="Contact information"
                    small
                    heading={'h2'}
                >
                    <BlockStack gap={'md'}>
                        {!!contactDetails?.emails?.length && (
                            <BlockStack gap={'tiny'}>
                                <h3 className="h6 text-subdued m-0">Emails</h3>
                                <InfoList
                                    tight
                                    rows={contactDetails.emails.map((e) => ({
                                        icon: ICONS.MAIL_GRAY,
                                        content: (
                                            <InlineStack itemsCenter wrap>
                                                {e.email || '-'}&nbsp;&nbsp;
                                                <Tag
                                                    noMargin
                                                    outline
                                                    color={'gray'}
                                                >
                                                    {e.emailType}
                                                </Tag>
                                                <Button
                                                    className={
                                                        e.isPreferred
                                                            ? ''
                                                            : 'opacity-25'
                                                    }
                                                    icon={
                                                        e.isPreferred
                                                            ? ICONS.STAR_FILLED_WHITE
                                                            : ICONS.STAR_WHITE
                                                    }
                                                    inline
                                                    plain
                                                    tiny
                                                    onClick={() =>
                                                        setEmailPreferred(
                                                            e.id || e._id,
                                                        )
                                                    }
                                                />
                                            </InlineStack>
                                        ),
                                    }))}
                                />
                            </BlockStack>
                        )}

                        {!!contactDetails?.phones?.length && (
                            <BlockStack gap={'tiny'}>
                                <h3 className="h6 text-subdued m-0">Phones</h3>
                                <InfoList
                                    tight
                                    rows={contactDetails.phones.map((p) => ({
                                        icon: ICONS.PHONE_GRAY,
                                        content: (
                                            <InlineStack itemsCenter wrap>
                                                {p.phone || '-'}&nbsp;&nbsp;
                                                <Tag
                                                    noMargin
                                                    outline
                                                    color={'gray'}
                                                >
                                                    {p.phoneType}
                                                </Tag>
                                                <Button
                                                    className={
                                                        p.isPreferred
                                                            ? ''
                                                            : 'opacity-25'
                                                    }
                                                    icon={
                                                        p.isPreferred
                                                            ? ICONS.STAR_FILLED_WHITE
                                                            : ICONS.STAR_WHITE
                                                    }
                                                    inline
                                                    plain
                                                    tiny
                                                    onClick={() =>
                                                        setPhonePreferred(
                                                            p.id || p._id,
                                                        )
                                                    }
                                                />
                                            </InlineStack>
                                        ),
                                    }))}
                                />
                            </BlockStack>
                        )}

                        <BlockStack gap={'tiny'}>
                            <h3 className="h6 text-subdued m-0">Addresses</h3>
                            <AddressesList plain orgId={organizationId} />
                        </BlockStack>

                        <BlockStack gap={'tiny'}>
                            <h3 className="h6 text-subdued m-0">
                                Websites and social media
                            </h3>
                            <div>
                                <Social contactDetails={contactDetails} />
                            </div>
                        </BlockStack>
                    </BlockStack>
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title={'Members'} small heading={'h2'}>
                    {isOwnProfile ? (
                        <ResourceList
                            key={organization.members
                                .map((m) => `${m.id}_${m.role}`)
                                .join('-')}
                            newItemBtnText={'Invite member'}
                            newItemPath={
                                canEdit
                                    ? `/organizations/${organization.id}/members/invite`
                                    : undefined
                            }
                            items={organization.members || []}
                            fields={[
                                {
                                    column: 1,
                                    getValue: (item) => (
                                        <InlineStack spaceBetween gap={'sm'}>
                                            <ContactCard
                                                user={item.user}
                                                summary
                                            />
                                            <InlineStackItem shrink0>
                                                <Tag
                                                    outline
                                                    color={'primary'}
                                                    onChange={
                                                        canEdit &&
                                                        item.userId !==
                                                            auth.user.id
                                                            ? (v) =>
                                                                  handleRoleChange(
                                                                      v,
                                                                      item,
                                                                  )
                                                            : undefined
                                                    }
                                                    options={[
                                                        {
                                                            label: 'Admin',
                                                            value: 'admin',
                                                            color: 'primary',
                                                        },
                                                        {
                                                            label: 'Member',
                                                            value: 'member',
                                                            color: 'primary',
                                                        },
                                                    ]}
                                                    initValue={item.role}
                                                >
                                                    {item.role}
                                                </Tag>
                                            </InlineStackItem>
                                        </InlineStack>
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <ResourceList
                            key={organization.members
                                .map((m) => `${m.id}_${m.role}`)
                                .join('-')}
                            items={organization.members || []}
                            newItemPath={`/organizations/${organization.id}/members`}
                            newItemBtnText={'Edit'}
                            fields={[
                                {
                                    column: 1,
                                    getValue: (item) => (
                                        <InlineStack spaceBetween gap={'sm'}>
                                            <ContactCard
                                                user={item.user}
                                                summary
                                                withNameLink
                                                withUserLink
                                            />
                                            <InlineStackItem shrink0>
                                                <Tag
                                                    outline
                                                    color={'primary'}
                                                    onChange={(v) =>
                                                        handleRoleChange(
                                                            v,
                                                            item,
                                                        )
                                                    }
                                                    options={[
                                                        {
                                                            label: 'Admin',
                                                            value: 'admin',
                                                            color: 'primary',
                                                        },
                                                        {
                                                            label: 'Member',
                                                            value: 'member',
                                                            color: 'primary',
                                                        },
                                                    ]}
                                                    initValue={item.role}
                                                >
                                                    {item.role}
                                                </Tag>
                                            </InlineStackItem>
                                        </InlineStack>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Billing methods" small heading={'h2'}>
                    <BillingMethodInfo
                        billingMethods={organization.billingMethods}
                        orgId={organizationId}
                    />
                </Toggle>
            </SectionContainer>

            {(auth.isAdmin || auth.isAssistant) && (
                <SectionContainer>
                    <Toggle title="Notes" small heading={'h2'}>
                        <Notes
                            id={organization}
                            internalNotes={organization.internalNotes}
                        />
                    </Toggle>
                </SectionContainer>
            )}

            {!!canEdit && (
                <MainButton
                    label="Edit"
                    functionality="EDIT"
                    onClick={() => navigate(editUrl)}
                />
            )}
        </>
    )
}
