import { useState } from 'react'
import { useStore } from '../../common/Context'
import Button from './Button'
import Input from './data-form/Input'
import ErrorMessage from './ErrorMessage'
import Break from './Break'
import useError from '../../common/hooks/useError'

export default function FormModalContent({
    onCancel,
    onConfirm,
    inputs = [],
    confirmText,
    topHtml,
}) {
    const [state, dispatch] = useStore()
    const [data, setData] = useState(
        inputs.reduce((acc, input) => {
            acc[input.key] = input.defaultValue || ''
            return acc
        }, {}),
    )
    const [error, setError, fieldErrors] = useError('')

    function handleCancel() {
        dispatch({ type: 'CLOSE_MODAL' })
        onCancel && onCancel()
    }

    async function handleSubmit(e) {
        e.preventDefault()
        onConfirm(data, setError)
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    {topHtml && (
                        <>
                            {topHtml}
                            <Break />
                        </>
                    )}
                    {!!error && (
                        <ErrorMessage onDismiss={() => setError('')}>
                            {error}
                        </ErrorMessage>
                    )}
                    {inputs.map((input) => (
                        <Input
                            key={input.key}
                            {...input}
                            errors={fieldErrors}
                            value={data[input.key]}
                            onChange={(v, item) => {
                                // Might need to use the ID when used
                                return setData((prev) => ({
                                    ...prev,
                                    [input.key]:
                                        input.type === 'liveSearch' ? item : v,
                                }))
                            }}
                        />
                    ))}
                </div>
                <div className="modal-buttons">
                    <Button
                        small
                        destructive
                        link
                        onClick={handleCancel}
                        text={'Cancel'}
                        disabled={state.isModalLoading}
                    />
                    <Button
                        small
                        type="submit"
                        isLoading={state.isModalLoading}
                        text={confirmText || 'Submit'}
                    />
                </div>
            </form>
        </>
    )
}
