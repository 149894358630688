import NavBar from '../navigation/NavBar'
import Sidebar from './Sidebar'

export default function MainFooter() {
    return (
        <>
            <Sidebar />
            <footer className="main-footer">
                <NavBar />
            </footer>
        </>
    )
}
