import { forwardRef } from 'react'

const ScrollableContent = forwardRef(function ScrollableContentInner(
    { children, fullHeight },
    ref,
) {
    return (
        <div
            ref={ref}
            className={`scrollable-content ${fullHeight ? ' full-height' : ''}`}
        >
            {children}
        </div>
    )
})

export default ScrollableContent
