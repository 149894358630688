import { Link } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import { cleanSlugInput, copyToClipboard } from '../../../common/helpers'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import DataFormNew from '../../../components/common/DataFormNew'

const initData = {
    slug: '',
    title: '',
    content: '',
    isPublic: false,
    isPublished: 'false',
    isHtml: true,
    pageType: 'default',
    sassCode: '',
    tags: [],
    parentPageId: undefined,
}

const inputs = [
    {
        key: 'title',
        label: 'Title',
        required: true,
        autoComplete: 'new-password',
        setDataOnChange: (v, data) => {
            const slug = cleanSlugInput(v)
            return {
                ...data,
                slug,
            }
        },
    },
    {
        key: 'content',
        label: 'Content',
        type: 'html',
        id: 'form-description',
        onEditorChange: (v, setOptsState) => {
            setOptsState((prev) => ({ ...prev, contentEditor: v }))
        },
    },
    {
        key: 'sassCode',
        label: 'Sass',
        type: 'textarea',
        rows: 16,
        shouldHide: (data, _item, optsState) => {
            return !data.isHtml || optsState.contentEditor !== 'code'
        },
    },
    {
        key: 'tags',
        type: 'liveSearchAddItems',
        label: 'Tags',
        canAddNewItem: true,
        url: '/v1/tags?resource=Page',
        getItemText: (item) => item,
        fields: [
            {
                column: 1,
                getValue: (item) => item,
            },
        ],
        infoBottom: 'Tags are visible to all users.',
    },
    {
        key: 'slug',
        label: 'Slug',
        required: true,
        autoComplete: 'new-password',
        shouldHide: (data) => data.appScreen,
        transformValue: (v) => cleanSlugInput(v),
        pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$',
    },

    {
        key: 'isPublished',
        label: 'Publish page',
        type: 'switch',
        options: [
            {
                value: 'true',
                label: 'Publish',
            },
            {
                value: 'false',
                label: 'Draft',
            },
        ],
    },
    {
        key: 'isPublic',
        label: 'Public page',
        type: 'checkbox',
        info: 'Public pages are accessible to anyone, even if they are not logged in.',
    },
    {
        key: 'showOnClientMenu',
        label: 'Show on client Menu',
        type: 'checkbox',
    },
    {
        key: 'showOnTeamMenu',
        label: 'Show on team Menu',
        type: 'checkbox',
    },
    {
        key: 'isGlossary',
        label: 'Group with alphabet',
        type: 'checkbox',
        info: 'Assign pages to it, and they will appear in glossary format in it.',
        shouldHide: (data) => data.appScreen,
    },
    {
        key: 'parentPageId',
        label: 'Parent page',
        type: 'liveSearch',
        shouldHide: (data) => data.appScreen,
        getResultValue: (item) => item.title,
        url: '/v1/pages?pageType=glossary',
        getItemsFromResults: (data) => data?.results || [],
        limit: 20,
        infoBottom:
            'Slug will be under the parent page path. If parent is type glossary, this page will be shown in glossary format in it.',
    },
]

export default function CreatePage() {
    const setToast = useToast()

    async function getBody(data) {
        const body = { ...data }
        if (data.isGlossary) {
            body.pageType = 'glossary'
        } else {
            body.pageType = 'default'
        }
        delete body.isGlossary

        body.isPublished = data.isPublished === 'true'

        const finalContent = data.content
        body.content = finalContent

        return body
    }

    return (
        <SectionContainer>
            <DataFormNew
                initData={initData}
                getInitializeAfterSaveFields={(data) => ({
                    ...initData,
                    parentPageId: data.parentPageId,
                    tags: data.tags,
                    isPublished: data.isPublished,
                })}
                mutationRegexes={[/\/v1\/pages/]}
                url={'/v1/pages'}
                submitToast=""
                submitText="Save"
                inputs={inputs}
                mainButton
                getBody={getBody}
                getSubmitMessage={(responseData) => (
                    <>
                        <div>
                            Created{' '}
                            <Link to={`/pages/${responseData.slug}`}>
                                new page
                            </Link>
                            .{' '}
                            <Link to={`/edit-pages/${responseData.id}`}>
                                Edit page.
                            </Link>
                        </div>
                        <Break />
                        {responseData.slug ? (
                            <Button
                                icon={ICONS.COPY_WHITE}
                                text="Copy URL"
                                outline
                                tiny
                                white
                                onClick={() => {
                                    copyToClipboard(
                                        `${process.env.REACT_APP_PUBLIC_URL}/pages/${responseData.slug}`,
                                    )
                                    setToast('Copied to clipboard')
                                }}
                            />
                        ) : null}
                    </>
                )}
            />
        </SectionContainer>
    )
}
