import useModal from '../../../common/hooks/useModal'
import Button from '../Button'
import Grid from '../Grid'
import Image from '../Image'
import ImageModalContent from '../ImageModalContent'
import PrivateFile from '../PrivateFile'

export default function FilesRemoveInput({ value, onChange, errors }) {
    const { setModal } = useModal()

    function handleChange(e, url) {
        e.preventDefault()
        onChange(value.filter((v) => v !== url))
    }
    return (
        <div
            className={`files-remove-input${errors?.length ? ' has-error' : ''}`}
        >
            {value?.length ? (
                <Grid cols={3}>
                    {value.map((idOrUrl) => (
                        <div key={idOrUrl}>
                            {idOrUrl.includes('http') && (
                                <Button
                                    text={<>&#10005;</>}
                                    small
                                    plain
                                    title="Remove"
                                    onClick={(e) => handleChange(e, idOrUrl)}
                                />
                            )}
                            {idOrUrl.includes('http') ? (
                                idOrUrl?.endsWith('.jpg') ||
                                idOrUrl?.endsWith('.jpeg') ||
                                idOrUrl?.endsWith('.png') ? (
                                    <Image
                                        key={idOrUrl}
                                        className=""
                                        crossOrigin="Anonymous"
                                        src={idOrUrl}
                                        onClick={() => {
                                            setModal(
                                                <ImageModalContent
                                                    src={idOrUrl}
                                                />,
                                            )
                                        }}
                                    />
                                ) : (
                                    <a
                                        href="#"
                                        key={idOrUrl}
                                        className=""
                                        onClick={() => {
                                            const a =
                                                document.createElement('a')
                                            a.style = 'display: none'
                                            a.href = idOrUrl
                                            a.download = idOrUrl
                                                .split('/')
                                                .pop()
                                            a.click()
                                        }}
                                    >
                                        {idOrUrl.split('/').pop()}
                                    </a>
                                )
                            ) : (
                                <PrivateFile
                                    openFullOnClick
                                    driveFileId={idOrUrl}
                                />
                            )}
                        </div>
                    ))}
                </Grid>
            ) : (
                <div>No files</div>
            )}
        </div>
    )
}
