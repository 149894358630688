import { useCallback } from 'react'
import fetchAPI from '../../../../common/fetchAPI'
import { getUserDisplayName } from '../../../../common/helpers'
import useMatchMutate from '../../../../common/hooks/useMatchMutate'
import useModal from '../../../../common/hooks/useModal'
import useToast from '../../../../common/hooks/useToast'
import InputModalContent from '../../../../components/common/InputModalContent'
import SelectInputModalContent from '../../../../components/common/SelectInputModalContent'
import ConfirmModalContent from '../../../../components/common/ConfirmModalContent'

export default function useBulkActions() {
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    const onSetClient = useCallback(function (items) {
        if (items.some((i) => !i.target)) {
            setToast('There was an error, please contact Support.', 'alert')
            return
        }
        setModal(
            <InputModalContent
                topHtml={
                    <p>
                        Team member tasks will be linked to this client.
                        Homework tasks will be assigned to them.
                    </p>
                }
                label="New client"
                type={'liveSearch'}
                url="/v1/users?isBlocked=false&role=user&fields=firstName,lastName,email,role"
                confirmText="Assign"
                getItemsFromResults={(data) => data?.results || []}
                getItemText={(item) => getUserDisplayName(item)}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => getUserDisplayName(item),
                    },
                ]}
                onConfirm={async (data, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0

                    for (const item of items) {
                        const body = { clientId: data.item.id }
                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            body,
                            'PATCH',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(
                        `${successCount} tasks were re-assigned to ${getUserDisplayName(data.item)}`,
                    )
                }}
            />,
            'Assign to new client',
            'modal-full',
        )
    })

    const onSetTeamMember = useCallback(function (items) {
        if (items.some((i) => !i.target)) {
            setToast('There was an error, please contact Support.', 'alert')
            return
        }
        setModal(
            <InputModalContent
                label="New client"
                type={'liveSearch'}
                url="/v1/users?isBlocked=false&role=staff&fields=firstName,lastName,email,role"
                confirmText="Assign"
                getItemsFromResults={(data) => data?.results || []}
                getItemText={(item) => getUserDisplayName(item)}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => getUserDisplayName(item),
                    },
                ]}
                onConfirm={async (data, setError) => {
                    console.log(data)
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0

                    for (const item of items) {
                        const body = { assignedTeamMemberId: data.item.id }
                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            body,
                            'PATCH',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(
                        `${successCount} tasks were re-assigned to ${getUserDisplayName(data.item)}`,
                    )
                }}
            />,
            'Assign to new team member',
            'modal-full',
        )
    })

    const onAddTags = useCallback(function (items) {
        if (items.some((i) => !i.tags)) {
            setToast('There was an error, please contact Support.', 'alert')
            return
        }
        setModal(
            <InputModalContent
                label="Tags"
                type={'liveSearchAddItems'}
                url="/v1/tags?resource=Task"
                canAddNewItem
                confirmText="Add"
                getItemText={(item) => item}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item,
                    },
                ]}
                onConfirm={async (data, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0

                    for (const item of items) {
                        const body = { tags: [...item.tags, ...data.items] }
                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            body,
                            'PATCH',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(`${successCount} tags added`)
                }}
            />,
            'Tags to add',
            'modal-full',
        )
    })

    const onRemoveTags = useCallback(function (items) {
        if (items.some((i) => !i.tags)) {
            setToast('There was an error, please contact Support.', 'alert')
            return
        }
        setModal(
            <InputModalContent
                label="Tags"
                type={'liveSearchAddItems'}
                url="/v1/tags?resource=Task"
                confirmText="Remove"
                getItemText={(item) => item}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item,
                    },
                ]}
                onConfirm={async (data, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0

                    for (const item of items) {
                        const body = {
                            tags: item.tags.filter(
                                (t) => !data.items.includes(t),
                            ),
                        }

                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            body,
                            'PATCH',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(`${successCount} tags added`)
                }}
            />,
            'Tags to remove',
            'modal-full',
        )
    })

    const onSetPriority = useCallback(function (items) {
        setModal(
            <SelectInputModalContent
                label="Priority"
                fields={[
                    {
                        name: 'priority',
                        label: 'Priority',
                        default: 'low',
                        options: [
                            {
                                label: 'Low',
                                value: 'low',
                            },
                            {
                                label: 'Medium',
                                value: 'medium',
                            },
                            {
                                label: 'High',
                                value: 'high',
                            },
                        ],
                    },
                ]}
                confirmText="Update"
                onConfirm={async (data, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0
                    for (const item of items) {
                        const body = { priority: data.priority }
                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            body,
                            'PATCH',
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(`${successCount} tasks updated`)
                }}
            />,
            'New priority',
            'modal-full',
        )
    })

    const onAttachPage = useCallback(function (items) {
        setModal(
            <InputModalContent
                label="Tags"
                topHtml={
                    <p>
                        {`A link to the page as a "Requirements page" will be appended to the task description.`}
                    </p>
                }
                type={'liveSearch'}
                getItemsFromResults={(data) => data?.results || []}
                getItemText={(item) => item.title}
                url="/v1/pages?fields[]=title&fields[]=slug&isPublished=true&limit=10&page=1"
                confirmText="Attach"
                limit={10}
                onConfirm={async (data, setError) => {
                    setError('')
                    if (isModalLoading) return
                    setModalLoading(true)

                    const errors = []
                    let successCount = 0

                    for (const item of items) {
                        const {
                            error: taskError,
                            responseData: taskResponseData,
                        } = await fetchAPI(
                            `/v1/tasks/${item.id}?fields[]=descriptionHtml`,
                        )
                        if (taskError) {
                            errors.push(taskError)
                            continue
                        }

                        const formData = new FormData()
                        const body = {
                            descriptionHtml: `${taskResponseData.descriptionHtml}<div><br /><br />Requirements page: <a href="/pages/${data.item.slug}">${data.item.title}<a/></div>`,
                        }

                        formData.append('data', JSON.stringify(body))

                        const { error } = await fetchAPI(
                            `/v1/tasks/${item.id}`,
                            formData,
                            'PATCH',
                            {},
                        )
                        if (error) {
                            errors.push(error)
                            continue
                        }

                        successCount++
                    }
                    setModalLoading(false)
                    setModal(null)
                    if (errors?.length) {
                        setError(errors.join(', '), [true])
                    }
                    const urlRegexes = items.map(
                        (i) => new RegExp(`/v1/tasks/${i.id}`),
                    )
                    matchMutate(new RegExp('/v1/tasks'))
                    for (const regex of urlRegexes) {
                        matchMutate(regex)
                    }
                    setToast(`Attached page to ${successCount} tasks`)
                }}
            />,
            'Tags to add',
            'modal-full',
        )
    })

    const onSetAllToOpen = useCallback(function (queryStr) {
        setModal(
            <ConfirmModalContent
                text={'This action cannot be reversed.'}
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    let finalQueryStr = ''
                    if (queryStr) {
                        const query = new URLSearchParams(queryStr)
                        query.delete('fields')
                        query.delete('populate')
                        query.delete('sortBy')
                        for (const [key] of query.entries()) {
                            if (key.startsWith('status')) {
                                query.delete(key)
                            }
                        }
                        finalQueryStr = query.toString()
                    }

                    const url = `/v1/tasks/bulk/update-status${
                        finalQueryStr ? `?${finalQueryStr}` : ''
                    }`

                    const { error } = await fetchAPI(
                        url,
                        { from: 'accepted', to: 'open' },
                        'POST',
                    )
                    setModalLoading(false)

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setToast('Update initiated, it might take up to a minute.')
                }}
            />,
            'Are you sure you want to mark all of the in-progress tasks to open?',
        )
    })

    return {
        onAddTags,
        onRemoveTags,
        onSetPriority,
        onSetClient,
        onSetTeamMember,
        onAttachPage,
        onSetAllToOpen,
    }
}
