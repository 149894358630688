import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import BlockStack from '../../../components/common/BlockStack'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Spinner from '../../../components/common/Spinner'
import ContactCard from './ContactCard'
import ContactCardContactInfo from './ContactCardContactInfo'

export default function ContactCardPopoverContent({
    userId,
    contactId,
    orgId: _orgId,
}) {
    const auth = useAuth()

    const isOwnProfile = auth.user.id === userId

    let url = null
    if (userId && !isOwnProfile) {
        url = `/v1/users/${userId}?fields=avatarUrl,firstName,lastName,email,isPremiumUser,role,orgsData${auth.isAdmin || auth.isAssistant ? ',labels' : ''}&populate=contactDetails,orgsData`
    } else if (contactId) {
        url = `/v1/contacts/${contactId}?fields=avatarUrl,firstName,lastName,email,labels,companyName&populate=contactDetails`
    }
    const { user, userError, userLoading } = useData(url, 'user')

    if (userLoading) {
        return <Spinner />
    }

    if (userError) {
        return <ErrorMessage>{userError}</ErrorMessage>
    }

    return (
        <BlockStack gap={'sm'}>
            <ContactCard
                largeThumbnail
                user={user || (isOwnProfile && auth.user)}
            />
            <ContactCardContactInfo
                user={user || (isOwnProfile && auth.user)}
            />
        </BlockStack>
    )
}
