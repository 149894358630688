import { useRef } from 'react'
import Button from '../Button'
import XIcon from '../../../assets/icons/XIcon'

export default function TextInput({
    type = 'text',
    value,
    onChange,
    id,
    errors,
    ...rest
}) {
    const ref = useRef(null)

    function handleChange(e) {
        onChange(e.target.value)
    }
    const textValue = value || ''

    function handleClear() {
        onChange('')
        ref.current?.focus()

        if (rest.onClear) {
            rest.onClear()
        }
    }

    const showClearButton =
        !rest.disabled &&
        rest.withClear &&
        !!value?.length &&
        type !== 'textarea' &&
        type !== 'date' &&
        type !== 'password' &&
        type !== 'datetime-local' &&
        type !== 'hidden'

    return (
        <div
            className={`text-input${showClearButton ? ' with-clear' : ''}${errors?.length ? ' has-error' : ''}`}
        >
            {type === 'textarea' ? (
                <textarea
                    id={id}
                    value={textValue}
                    onChange={handleChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    rows={rest.rows}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                    className={rest.noMargin ? 'm-0' : ''}
                ></textarea>
            ) : (
                <input
                    ref={showClearButton ? ref : undefined}
                    id={id}
                    type={type}
                    value={textValue}
                    onChange={handleChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    min={rest.min}
                    step={rest.step}
                    max={rest.max}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                    className={rest.noMargin ? 'm-0' : ''}
                    onPaste={rest.onPaste}
                    pattern={rest.pattern}
                />
            )}

            {Boolean(rest.placeholderContent) && (
                <div className="placeholder-content">
                    {rest.placeholderContent}
                </div>
            )}

            {showClearButton && (
                <div className="clear-btn">
                    <Button
                        text={'Clear'}
                        onClick={handleClear}
                        tiny
                        plain
                        icon={<XIcon />}
                        iconTop
                    />
                </div>
            )}
        </div>
    )
}
