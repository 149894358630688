import SectionContainer from '../../components/common/SectionContainer'
import { useEffect, useState } from 'react'
import Button from '../../components/common/Button'

// https://app.aco.digital/pages/criticalbugs
// https://app.aco.digital/tasks/66c391ba94871c5bc52b906f
export default function Dev() {
    const [v, setV] = useState(0)
    const [a, setA] = useState(0)
    const [b, setB] = useState(0)
    const [c, setC] = useState(0)

    useEffect(
        function () {
            if (v === 0) {
                return
            }
            setA((v) => v + 1)
            setTimeout(function () {
                setB((v) => v + 1)
                setC((v) => v + 1)
            }, 2000)
        },
        [v],
    )

    console.log({ a, b, c })

    return (
        <div>
            <SectionContainer>
                <Button text="Click me" onClick={() => setV((v) => v + 1)} />
            </SectionContainer>
        </div>
    )
}
