import LiveSearch from './LiveSearch'

export default function LiveSearchInput({
    value,
    hasStrValueOnNull,
    onChange,
    ...input
}) {
    function onItemClick(item) {
        onChange(item?.id || null, item || null)
    }

    return (
        <LiveSearch
            onItemClick={onItemClick}
            {...input}
            defaultLabel={value}
            {...(hasStrValueOnNull ? { onChange } : {})}
        />
    )
}
