import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/common/Button'
import { ICONS } from '../../../common/constants'
import useAuth from '../../../common/hooks/useAuth'

function Wrapper({ children, hideLink, small, url }) {
    return hideLink ? (
        <div className={`contact-thumbnail${small ? ' small-size' : ''}`}>
            {children}
        </div>
    ) : (
        <Link
            to={url}
            className={`contact-thumbnail${small ? ' small-size' : ''}`}
        >
            {children}
        </Link>
    )
}

export default function ContactThumbnail({
    data,
    isContact,
    isOrg,
    withEdit,
    small,
    hideLink,
}) {
    const auth = useAuth()
    const navigate = useNavigate()

    const isOwnProfile = auth.user.id === data.id
    const isUser = !isOrg && !isContact

    let url
    let editUrl
    if (isOrg) {
        url = `/${
            data.organizationType === 'company' ? 'companies' : 'organizations'
        }/${data.id}`
        editUrl = `/companies/${data.id}/edit?scrollField=logoUrl`
    } else {
        url = isOwnProfile
            ? '/profile'
            : `/${isContact ? 'contacts' : 'users'}/${data.id}`
        editUrl = `/${
            isContact ? 'contacts' : 'users'
        }/${data.id}/edit?type=${isContact ? 'contact' : 'user'}&scrollField=avatarUrl${
            isOwnProfile ? '&isOwnProfile=true' : ''
        }`
    }

    return (
        <Wrapper hideLink={hideLink} small={small} url={url}>
            {!!withEdit && (
                <div className="contact-thumbnail-edit">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            navigate(editUrl)
                        }}
                        title={'Edit'}
                        plain
                        hideText
                        icon={ICONS.EDIT_WHITE}
                    />
                </div>
            )}
            {isOrg ? (
                <div className={`photo`}>
                    {data.logoUrl ? (
                        <img src={data.logoUrl} alt="Logo" />
                    ) : (
                        <span>{data?.acronym?.slice(0, 3)}</span>
                    )}
                </div>
            ) : (
                <div
                    className={`photo ${data.isPremiumUser ? 'photo-premium' : ''}${
                        isContact ? ' photo-lead' : ''
                    }${isUser && !data.isPremiumUser && data.role !== 'admin' ? ' photo-free' : ''}${data.role === 'admin' ? ' photo-admin' : ''}`}
                >
                    {data.avatarUrl ? (
                        <img src={data.avatarUrl} alt="Avatar" />
                    ) : (
                        <>
                            {data.firstName ? (
                                <span>
                                    {data.firstName?.[0]?.toUpperCase()}
                                </span>
                            ) : null}
                            {data.lastName ? (
                                <span>{data.lastName?.[0]?.toUpperCase()}</span>
                            ) : null}
                            {!data.firstName && !data.lastName && (
                                <span>{data.email?.[0]?.toUpperCase()}</span>
                            )}
                        </>
                    )}
                </div>
            )}
        </Wrapper>
    )
}
