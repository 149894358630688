import { useState, useEffect } from 'react'

export function useScrollLock() {
    const [isLocked, setIsLocked] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(function () {
        document.body.classList.remove('scroll-lock')

        return function () {
            document.body.classList.remove('scroll-lock')
        }
    }, [])

    useEffect(
        function () {
            if (isLocked) {
                const currentScroll =
                    window.pageYOffset || document.documentElement.scrollTop
                setScrollPosition(currentScroll)
                document.body.style.setProperty(
                    '--scroll-top',
                    `-${currentScroll}px`,
                )
                document.body.classList.add('scroll-lock')
            } else if (document.body.classList.contains('scroll-lock')) {
                document.body.classList.remove('scroll-lock')
                window.scrollTo(0, scrollPosition)
            }
        },
        [isLocked],
    )

    // Function to lock and unlock the scroll
    const lockScroll = () => setIsLocked(true)
    const unlockScroll = () => setIsLocked(false)

    return { lockScroll, unlockScroll, isLocked }
}
