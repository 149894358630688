import { NavLink, useLocation } from 'react-router-dom'
import { ICONS } from '../../common/constants'

function Item(props) {
    const { pathname } = useLocation()
    return (
        <li>
            <NavLink to={props.url} viewTransition>
                {pathname === props.activeUrl ? (
                    <img src={props.iconActive} alt={props.label} />
                ) : (
                    <img
                        src={props.iconWhite}
                        width="30"
                        height="30"
                        alt={props.label}
                    />
                )}
                <span className={pathname === props.activeUrl ? 'active' : ''}>
                    {props.label}
                </span>
            </NavLink>
        </li>
    )
}

function NavBar() {
    const ITEMS = {
        home: {
            label: 'Home',
            iconActive: '/img/icons/home-active.svg',
            iconWhite: '/img/icons/home-white.svg',
            url: '/',
            activeUrl: '/',
        },
        profile: {
            label: 'Profile',
            iconActive: '/img/icons/user-active.svg',
            iconWhite: '/img/icons/user-white.svg',
            url: '/profile',
            activeUrl: '/profile',
        },
        content: {
            label: 'Content',
            iconActive: '/img/icons/instagram-active.svg',
            iconWhite: '/img/icons/instagram-white.svg',
            url: '/content',
            activeUrl: '/content',
        },
        more: {
            label: 'More',
            iconActive: '/img/icons/menu-active.svg',
            iconWhite: '/img/icons/menu-white.svg',
            url: '/more',
            activeUrl: '/more',
            viewTransition: true,
        },
        billing: {
            label: 'Reports',
            iconActive: '/img/icons/bar-chart-active.svg',
            iconWhite: '/img/icons/bar-chart-white.svg',
            url: '/billing',
            activeUrl: '/billing',
        },
        contacts: {
            label: 'Contacts',
            iconActive: '/img/icons/briefcase-active.svg',
            iconWhite: '/img/icons/briefcase-white.svg',
            url: '/contacts',
            activeUrl: '/contacts',
        },
        shop: {
            label: 'Shop',
            iconActive: ICONS.SHOPPING_CART_ACTIVE,
            iconWhite: ICONS.SHOPPING_CART_WHITE,
            url: '/shop',
            activeUrl: '/shop',
        },
    }

    const itemsSet = new Set()
    itemsSet.add(ITEMS.home)

    itemsSet.add(ITEMS.billing)
    itemsSet.add(ITEMS.contacts)
    itemsSet.add(ITEMS.content)
    itemsSet.add(ITEMS.shop)
    itemsSet.add(ITEMS.more)

    const liEls = []

    const addBlank = itemsSet.size % 2 !== 0
    const clearfixPos = Math.floor((itemsSet.size + Number(addBlank)) / 2) - 1
    let i = 0
    const itemsArray = Array.from(itemsSet)
    for (const item of itemsArray) {
        liEls.push(<Item key={i} {...item} />)
        if (i === clearfixPos) {
            liEls.push(<li className="clearfix" key={i + 0.5} />)
        }
        i++
    }
    if (addBlank) {
        liEls.push(<li className="placeholder-item" key={itemsSet.size}></li>)
    }

    return (
        <div className="nav-bar">
            <div className="main-overlay"></div>

            <nav>
                <div className="button-surround"></div>
                <ul>{liEls}</ul>
                <div className={'logo'}>
                    <img
                        src="/img/agency-couturemulticolor-1-sm.png"
                        alt="logo"
                        width="40"
                        height="49"
                    />
                </div>
            </nav>
        </div>
    )
}

export default NavBar
