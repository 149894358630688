import React, { useEffect, useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import {
    copyToClipboard,
    getErrorsForInput,
    getImageDimensions,
    getUserDisplayName,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import { API_URL, DEFAULT_TEAM_ROLES, ICONS } from '../../../common/constants'
import { Link, useNavigate, useParams } from 'react-router-dom'
import MessageSection from '../../../components/common/MessageSection'
import ContactName from '../clients/ContactName'
import MainButton from '../../../components/admin/MainButton'
import { useUploads } from '../../../common/UploadContext'
import Input from '../../../components/common/data-form/Input'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

const permissions = {
    user: {
        admin: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,teamRoles,isPremiumUser,role,startDate,labels',
            populate: 'contactDetails,orgsData',
        },
        assistant: {
            fields: 'firstName,lastName,email,onHold,onBlackList,isBlocked,onCollections,teamRoles,isPremiumUser,role,startDate,labels',
            populate: 'contactDetails,orgsData',
        },
        sales: {
            fields: 'firstName,lastName,email,onHold,isPremiumUser,role,labels',
            populate: 'contactDetails,orgsData',
        },
        collections: {
            fields: 'firstName,lastName,email,onHold,onCollections,isPremiumUser,role,labels',
            populate: 'contactDetails,orgsData',
        },
        staff: {
            fields: 'firstName,lastName,email,isPremiumUser,role,labels',
        },
    },
}

const userFilterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
        asc: true,
    },
}

const staffFilterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
}

function getAllowedTargetOptions(auth, isUserPage) {
    return [
        {
            value: 'me',
            label: 'Me',
        },
        {
            value: 'client',
            label: 'Client homework',
        },
        ...(auth.isAdmin || auth.isAssistant
            ? [
                  {
                      value: 'team',
                      label: 'Team member task',
                  },
              ]
            : []),
        ...(!isUserPage
            ? [
                  {
                      value: 'role',
                      label: 'Team task',
                  },
              ]
            : []),
    ]
}

function parseRequirements(str) {
    if (str === 'text_link') {
        return {
            text: true,
            link: true,
        }
    } else if (str === 'text_image') {
        return {
            text: true,
            imageFileId: true,
        }
    } else if (str === 'text_file') {
        return {
            text: true,
            otherFileId: true,
        }
    }
}

function CreateTask() {
    const { userId } = useParams()

    const { user, userError, userLoading } = useData(
        userId
            ? `/v1/users/${userId}?fields=email,firstName,lastName,role`
            : null,
        'user',
    )

    const auth = useAuth()

    const [actionLoading, setActionLoading] = useState(false)
    const [error, setError, errorFields] = useError()
    const [message, setMessage] = useState('')
    const [lastCreatedId, setLastCreatedId] = useState(null)
    const navigate = useNavigate()
    const { uploadFiles } = useUploads()
    const [isNewUploads, setIsNewUploads] = useState(true)
    const [editorMode, setEditorMode] = useState(null)

    const [data, setData] = useState({
        tags: [],
        priority: 'low',
        order: '0',
        requirements: auth.isClient ? '' : 'text_image',
        target: auth.isClient ? 'me' : undefined,
        attachmentFiles: [],
    })

    const noUserParam = !userLoading && !user && !userError

    const { teamRoles, teamRolesError, teamRolesLoading } = useData(
        auth.isClient ? null : '/v1/settings/teamRoles',
        'teamRoles',
        (data) =>
            data?.settingValue
                ?.split('\n')
                ?.sort((a, b) => a?.localeCompare(b)) || [],
    )

    useEffect(
        function () {
            if (userLoading) return
            if (auth.isClient) return
            if (noUserParam) {
                setData({ ...data, target: 'role' })
            } else {
                const newData = { ...data }
                newData.target = user.role === 'user' ? 'client' : 'team'
                if (user.role === 'user') {
                    newData.clientId = user.id
                } else if (user.role === 'staff') {
                    newData.assignedTeamMemberId = user.id
                }
                setData(newData)
            }
        },
        [user],
    )

    const setToast = useToast()

    function onAssignedIdChange(id) {
        const newData = { ...data }

        if (data.target === 'client') {
            delete newData.assignedTeamMemberId
            delete newData.teamRoles
            newData.clientId = id
        } else if (data.target === 'team') {
            delete newData.clientId
            delete newData.teamRoles
            newData.assignedTeamMemberId = id
        }
        setData(newData)
    }

    function onTargetChange(newTarget) {
        const newData = { ...data }
        newData.target = newTarget
        newData.attachmentFileIds = []
        newData.attachmentFiles = []
        if (newTarget === 'client') {
            delete newData.assignedTeamMemberId
            delete newData.teamRoles
            newData.clientId = ''
        } else if (newTarget === 'team') {
            delete newData.clientId
            delete newData.teamRoles
            newData.assignedTeamMemberId = ''
        } else if (newTarget === 'role') {
            delete newData.clientId
            delete newData.assignedTeamMemberId
            newData.teamRoles = ['all']
        } else if (newTarget === 'me') {
            delete newData.teamRoles
            delete newData.clientId
            newData.assignedTeamMemberId = auth.user.id
        }

        setData(newData)
    }

    function onRoleChange(items) {
        const newData = { ...data }
        delete newData.assignedTeamMemberId
        delete newData.clientId
        newData.teamRoles = items
        newData.attachmentFiles = []
        setData(newData)
    }

    function initializeAfterSave() {
        const initData = {
            ...data,
            title: '',
            descriptionHtml: '',
            descriptionSassCode: '',
            attachmentFiles: [],
        }
        setData(initData)
        window.scrollTo(0, 0)
    }

    async function onSubmit(e) {
        setError('')
        setMessage('')
        setLastCreatedId(null)

        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        // if (['bulkTeam', 'bulkClient'].includes(data.target)) {
        //     return onSubmitBulk()
        // }

        if (data.target === 'client' && !data.clientId) {
            setError('No client selected', ['clientId'])
            return
        }

        if (data.target === 'team' && !data.assignedTeamMemberId) {
            setError('No team member selected', ['assignedTeamMemberId'])
            return
        }

        const MAX_FILE_SIZE = 50 * 1024 * 1024
        // const SEPARATE_UPLOAD_SIZE = 0.2 * 1024 * 1024

        // let totalSize = 0
        for (const file of data.attachmentFiles || []) {
            // totalSize += file.size
            if (file.size > MAX_FILE_SIZE) {
                setError(`Max file size is 50MB.`, ['attachmentFiles'])
                return
            }
        }

        const shouldSeparateUpload = isNewUploads
        // totalSize >= SEPARATE_UPLOAD_SIZE || isNewUploads

        setActionLoading(true)
        const body = {
            title: data.title,
            descriptionHtml: data.descriptionHtml,
            descriptionSassCode: data.descriptionSassCode,
            priority: data.priority,
            target: data.target,
            tags: data.tags,
        }

        if (auth.isClient) {
            body.target = 'client'
            body.clientId = auth.user.id
        } else {
            body.order = Number(data.order)

            if (data.target === 'me') {
                body.target = 'team'
                body.assignedTeamMemberId = data.assignedTeamMemberId
                if (data.clientId) {
                    body.clientId = data.clientId
                }
            }

            if (data.target === 'role') {
                body.teamRoles = data.teamRoles
                if (data.clientId) {
                    body.clientId = data.clientId
                }
            } else if (user?.role === 'user') {
                body.clientId = user.id
            } else if (user?.role === 'staff') {
                body.assignedTeamMemberId = user.id
            } else if (noUserParam) {
                if (data.target === 'client') {
                    body.clientId = data.clientId
                } else if (data.target === 'team') {
                    body.assignedTeamMemberId = data.assignedTeamMemberId
                    if (data.clientId) {
                        body.clientId = data.clientId
                    }
                }
            }
            if (data.attachmentFileIds?.length) {
                body.attachmentFileIds = data.attachmentFileIds
            }
            if (['team', 'role'].includes(data.target)) {
                const requirements = parseRequirements(data.requirements)

                if (requirements) {
                    body.submissionRequirements = requirements
                }
            }
        }

        const formData = new FormData()
        const bodyData = { ...body }

        formData.append('data', JSON.stringify(bodyData))

        if (!shouldSeparateUpload && data.attachmentFiles?.length) {
            for (let i = 0; i < data.attachmentFiles.length; i++) {
                formData.append(
                    `files[attachment_${i}]`,
                    data.attachmentFiles[i],
                )
            }
        }

        const { responseData, error, meta } = await fetchAPI(
            `/v1/tasks`,
            formData,
            // body,
            'POST',
            {},
        )
        setActionLoading(false)

        if (error) {
            setError(error, meta?.fields)
            return
        }

        let uploadMessage = ''

        if (shouldSeparateUpload && data.attachmentFiles?.length) {
            for (const file of data.attachmentFiles) {
                if (file.type.startsWith('image/')) {
                    const { width, height } = await getImageDimensions(file)
                    file.width = width
                    file.height = height
                }
            }

            const { error } = uploadFiles(data.attachmentFiles, {
                patchUrl: `${API_URL}/v1/tasks/${responseData.id}`,
                patchField: 'attachmentFileUrlsAdd',
            })
            if (error) {
                setToast(error, 'alert')
            } else {
                uploadMessage =
                    'Files upload in progress, it may take a few minutes. '
            }
        }

        if (auth.isClient) {
            setToast('Created new task')
            navigate('/')
            return
        }

        setLastCreatedId(responseData.id)
        setMessage(
            <>
                Created <Link to={`/tasks/${responseData.id}`}>new task</Link>
                .&nbsp;{uploadMessage}
                <Link to={`/tasks/${responseData.id}/edit`}>Edit</Link>.
                {body.target === 'client' ? (
                    <>
                        &nbsp;
                        <Link to={`/users/${responseData.clientId}`}>
                            Go to client.
                        </Link>
                    </>
                ) : (
                    ''
                )}
                {body.target === 'team' ||
                (body.target === 'role' &&
                    responseData.assignedTeamMemberId) ? (
                    <>
                        &nbsp;
                        <Link
                            to={`/users/${responseData.assignedTeamMemberId}`}
                        >
                            Go to team member.
                        </Link>
                    </>
                ) : (
                    ''
                )}
            </>,
        )
        if (body.target === 'role' && !responseData.assignedTeamMemberId) {
            setMessage(
                <>
                    Created{' '}
                    <Link to={`/tasks/${responseData.id}`}>new task</Link>. No
                    available team member found, task will be assigned upon
                    availability.
                </>,
            )
        }
        initializeAfterSave()
    }

    if (userLoading || teamRolesLoading) {
        return <Spinner />
    }
    if (userError || teamRolesError) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {userError || teamRolesError?.message || 'Unexpected error'}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    let fields = {}
    let populate = {}
    if (auth.isAdmin) {
        fields = permissions.user.admin.fields
        populate = permissions.user.admin.populate
    } else if (auth.isAssistant) {
        fields = permissions.user.assistant.fields
        populate = permissions.user.assistant.populate
    } else if (auth.isSales) {
        fields = permissions.user.sales.fields
        populate = permissions.user.sales.populate
    } else if (auth.isCollections) {
        fields = permissions.user.collections.fields
        populate = permissions.user.collections.populate
    } else if (auth.isStaff) {
        fields = permissions.user.staff.fields
        populate = permissions.user.staff.populate
    }

    function handleCopyLink() {
        copyToClipboard(
            `${process.env.REACT_APP_PUBLIC_URL}/tasks/${lastCreatedId}`,
        )
        setToast('Copied to clipboard')
    }

    return (
        <SectionContainer>
            {/* Error message */}
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            {/* Success message */}
            {Boolean(message) && (
                <>
                    <MessageSection
                        type="success"
                        onDismiss={() => setMessage('')}
                    >
                        <div>{message}</div>
                        {Boolean(lastCreatedId) && (
                            <>
                                <br />
                                <Button
                                    icon={ICONS.COPY_WHITE}
                                    text="Copy URL"
                                    outline
                                    tiny
                                    white
                                    onClick={handleCopyLink}
                                />
                            </>
                        )}
                    </MessageSection>
                    <Break />
                </>
            )}

            {/* User name as title */}
            {!noUserParam && (
                <h2>
                    For <ContactName user={user} />
                </h2>
            )}

            <form onSubmit={onSubmit}>
                {/* Title */}
                <Input
                    label={'Title'}
                    required
                    id="form-title"
                    value={data.title}
                    onChange={(v) => setData({ ...data, title: v })}
                    infoBottom={
                        !auth.isClient &&
                        'Task titles are important. They should say what the person needs to do.'
                    }
                    autoComplete="new-password"
                    errors={getErrorsForInput('title', 'text', errorFields)}
                />
                {/* Description */}
                <Input
                    label={'Description'}
                    required={!auth.isAdmin}
                    type="html"
                    rows={8}
                    id="form-description"
                    value={data.descriptionHtml}
                    onChange={(v) => setData({ ...data, descriptionHtml: v })}
                    errors={getErrorsForInput(
                        'descriptionHtml',
                        'html',
                        errorFields,
                    )}
                    onEditorChange={(mode) => setEditorMode(mode)}
                    // TODO: below shouldn't be required.
                    setOptionsState={() => {}}
                />
                {editorMode === 'code' && (
                    <>
                        <Input
                            label={'Sass'}
                            type="textarea"
                            rows={16}
                            id="form-sass"
                            value={data.descriptionSassCode}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    descriptionSassCode: v,
                                })
                            }
                            errors={getErrorsForInput(
                                'descriptionSassCode',
                                'textarea',
                                errorFields,
                            )}
                        />
                        <Button
                            text="Copy SASS"
                            type="button"
                            onClick={() =>
                                copyToClipboard(data.descriptionSassCode)
                            }
                            small
                            outline
                            icon={ICONS.COPY_ACTIVE}
                        />
                        <br />
                    </>
                )}

                {/* Target */}
                {Boolean(noUserParam && !auth.isClient) && (
                    <Input
                        id="form-target"
                        type="select"
                        label="Assignment"
                        value={data.target}
                        onChange={onTargetChange}
                        infoBottom="Who will complete the work."
                        options={getAllowedTargetOptions(auth, !noUserParam)}
                    />
                )}

                {/* Assigned user */}
                {noUserParam && ['client', 'team'].includes(data.target) && (
                    <Input
                        getResultValue={(result) => getUserDisplayName(result)}
                        id="form-target-id"
                        type="liveSearch"
                        url={`/v1/users?role=${data.target === 'client' ? 'user' : 'staff'}&fields=${fields}&populate=${populate}`}
                        getItemsFromResults={(data) => data?.results || []}
                        onChange={onAssignedIdChange}
                        label={`Assigned ${
                            data.target === 'client' ? 'client' : 'team member'
                        }`}
                        errors={
                            data.target === 'client'
                                ? getErrorsForInput(
                                      'clientId',
                                      'liveSearch',
                                      errorFields,
                                  )
                                : getErrorsForInput(
                                      'assignedTeamMemberId',
                                      'liveSearch',
                                      errorFields,
                                  )
                        }
                        key={data.target}
                        filters={
                            data.target === 'client'
                                ? userFilterFields
                                : staffFilterFields
                        }
                    />
                )}

                {/* Team roles */}
                {noUserParam && data.target === 'role' && (
                    <Input
                        id="form-roles"
                        canAddNewItem
                        type="liveSearchAddItems"
                        label="Team roles"
                        value={data.teamRoles || []}
                        onChange={onRoleChange}
                        key={data.target}
                        items={[...teamRoles, ...DEFAULT_TEAM_ROLES]}
                        getItemText={(item) => item}
                        errors={getErrorsForInput(
                            'teamRoles',
                            'liveSearchAddItems',
                            errorFields,
                        )}
                    />
                )}

                {/* Related client */}
                {!auth.isClient &&
                    noUserParam &&
                    !['client'].includes(data.target) && (
                        <Input
                            id="form-linked-client-id"
                            type="liveSearch"
                            getResultValue={(result) =>
                                getUserDisplayName(result)
                            }
                            url={`/v1/users?role=user&fields=${fields}&populate=${populate}`}
                            getItemsFromResults={(data) => data?.results || []}
                            // value={data.clientId ? [data.clientId] : []}
                            onItemClick={(item) =>
                                setData({
                                    ...data,
                                    clientId: item?.id || '',
                                })
                            }
                            label="Client"
                            infoBottom="If the task is for a client, choose who. They will be able to see the task. Be sure the description isn't embarrassing for a client to see."
                            key={`${data.target}-client`}
                            errors={getErrorsForInput(
                                'clientId',
                                'liveSearch',
                                errorFields,
                            )}
                        />
                    )}

                {/* Tags */}
                <Input
                    id="form-tags"
                    type="liveSearchAddItems"
                    value={data.tags}
                    onChange={(items) =>
                        setData({
                            ...data,
                            tags: items,
                        })
                    }
                    label="Tags (beta)"
                    canAddNewItem
                    key={`tags`}
                    url="/v1/tags?resource=Task"
                    getItemText={(item) => item}
                    fields={[
                        {
                            column: 1,
                            getValue: (item) => item,
                        },
                    ]}
                    infoBottom={'Tags are visible to all users.'}
                    errors={getErrorsForInput(
                        'tags',
                        'liveSearchAddItems',
                        errorFields,
                    )}
                />
                {/* Priority */}
                <Input
                    label={'Priority'}
                    type="select"
                    id="form-priority"
                    value={data.priority}
                    onChange={(v) => setData({ ...data, priority: v })}
                    options={[
                        {
                            value: 'low',
                            label: 'Low',
                        },
                        {
                            value: 'medium',
                            label: 'Medium',
                        },
                        {
                            value: 'high',
                            label: 'High',
                        },
                    ]}
                    errors={getErrorsForInput(
                        'priority',
                        'select',
                        errorFields,
                    )}
                />
                {/* Order */}
                {!auth.isClient && (
                    <Input
                        label={'Order'}
                        id="form-order"
                        value={data.order}
                        onChange={(v) => setData({ ...data, order: v })}
                        type="number"
                        min={0}
                        infoBottom={'Set to 0 to add to the end of the list.'}
                        errors={getErrorsForInput(
                            'order',
                            'number',
                            errorFields,
                        )}
                    />
                )}
                {/* Requirements */}
                {['role', 'team'].includes(data.target) && (
                    <Input
                        label={'Requirements'}
                        type="select"
                        id="form-requirements"
                        disabled={!auth.isAdmin}
                        value={data.requirements}
                        onChange={(v) => setData({ ...data, requirements: v })}
                        options={[
                            {
                                value: 'none',
                                label: 'None',
                            },
                            {
                                value: 'text_link',
                                label: 'Text + Link',
                            },
                            {
                                value: 'text_image',
                                label: 'Text + Image',
                            },
                            {
                                value: 'text_file',
                                label: 'Text + File',
                            },
                        ]}
                        errors={getErrorsForInput(
                            'submissionRequirements',
                            'select',
                            errorFields,
                        )}
                    />
                )}
                {/* Browser attachments */}
                {['client', 'role', 'team', 'me'].includes(data.target) && (
                    <Input
                        label={'Attachments'}
                        type="file"
                        infoBottom="Add attachments from device."
                        id="form-attachment-browser"
                        multiple
                        onChange={(files) =>
                            setData({
                                ...data,
                                attachmentFiles: files,
                            })
                        }
                        errors={getErrorsForInput(
                            'attachmentFiles',
                            'file',
                            errorFields,
                        )}
                    />
                )}

                <div>
                    {data.attachmentFiles?.length > 0 && (
                        <Input
                            type="checkbox"
                            label={'Use backgroud upload (Beta)'}
                            value={isNewUploads}
                            onChange={(v) => setIsNewUploads(v)}
                        />
                    )}

                    <Button
                        text={'Save'}
                        type="submit"
                        isLoading={actionLoading}
                    />
                </div>
                <MainButton
                    disabled={actionLoading}
                    functionality="SAVE"
                    loading={actionLoading}
                />
            </form>
        </SectionContainer>
    )
}

export default CreateTask
