import { Link } from 'react-router-dom'
import Toggle from '../common/Toggle'
import useData from '../../common/hooks/useData'
import Spinner from '../common/Spinner'
import ErrorMessage from '../common/ErrorMessage'
import SectionContainer from '../common/SectionContainer'
import { routesHandleMap } from '../../routes'

function renderNavItems(items) {
    return items.map((item, i) =>
        item.items.length > 0 ? (
            <Toggle preventParamsUpdate key={i} title={item.title} plain>
                {renderNavItems(item.items)}
            </Toggle>
        ) : (
            <Link
                key={i}
                to={
                    item.url
                        ? item.url
                        : routesHandleMap.get(item.routeHandle)?.url || '#'
                }
            >
                {item.title}
            </Link>
        ),
    )
}

export default function Sidebar() {
    const { items, error, loading } = useData(
        `/v1/settings/sidebarItems`,
        'items',
        (data) => JSON.parse(data?.settingValue || '[]'),
    )

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!items) {
        return null
    }

    return (
        <>
            <aside className="sidebar">
                <nav>{renderNavItems(items)}</nav>
            </aside>
        </>
    )
}
