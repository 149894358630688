import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import DataFormNew from '../../../components/common/DataFormNew'
import {
    cleanSlugInput,
    copyToClipboard,
    replaceImgWithDataUrls,
} from '../../../common/helpers'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'
import Tag from '../../../components/common/Tag'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useModal from '../../../common/hooks/useModal'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import InputModalContent from '../../../components/common/InputModalContent'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import useAuth from '../../../common/hooks/useAuth'
import { COMPONENT_DATA } from '../components/ComponentData'

export default function EditPage() {
    const { pageId } = useParams()
    const [_viewLoading, setViewLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()
    const [_exportLoading, setExportLoading] = useState(false)
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()
    const auth = useAuth()

    const { page, pageLoading, pageError } = useData(
        `/v1/pages/admin/${pageId}`,
        'page',
    )

    async function onViewPage() {
        setViewLoading(true)
        const url = `/v1/pages/admin/${pageId}`
        const { responseData, error } = await fetchAPI(url)
        setViewLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        navigate(`/pages/${responseData.slug}`)
    }

    const inputs = [
        {
            key: 'title',
            label: 'Title',
            required: true,
            autoComplete: 'new-password',
            setDataOnChange: (v, data) => {
                const slug = cleanSlugInput(v)
                return {
                    ...data,
                    slug,
                }
            },
        },
        {
            key: 'content',
            label: 'Content',
            type: 'html',
            id: 'form-description',
            onEditorChange: (v, setOptsState) => {
                setOptsState((prev) => ({ ...prev, contentEditor: v }))
            },
            infoContent: (
                <>
                    <h3 className="m-0">Shortcodes - components</h3>
                    <p className="text-subdued">
                        Use the following placeholders to insert components in
                        HTML. Each can only be rendered once.
                    </p>
                    {Object.entries(COMPONENT_DATA).map(([name]) => (
                        <InlineStack key={name} gap={'sm'} spaceBetween>
                            <strong>{`<${name} />`}</strong>
                            <InlineStack>
                                {`<span class="page_component_${name.toLowerCase()}"></span>`}

                                <Button
                                    text={'copy'}
                                    hideText
                                    icon={ICONS.CLIPBOARD_ACTIVE}
                                    plain
                                    tiny
                                    onClick={() => {
                                        copyToClipboard(
                                            `<span class="page_component_${name.toLowerCase()}"></span>`,
                                        )
                                        setToast('Copied')
                                    }}
                                />
                            </InlineStack>
                        </InlineStack>
                    ))}
                </>
            ),
        },
        {
            key: 'sassCode',
            label: 'Sass',
            type: 'textarea',
            rows: 16,
            shouldHide: (data, _item, optsState) => {
                return !data.isHtml || optsState.contentEditor !== 'code'
            },
        },
        {
            key: 'tags',
            type: 'liveSearchAddItems',
            label: 'Tags',
            canAddNewItem: true,
            url: '/v1/tags?resource=Page',
            getItemText: (item) => item,
            fields: [
                {
                    column: 1,
                    getValue: (item) => item,
                },
            ],
        },
        {
            key: 'slug',
            label: 'Slug',
            required: true,
            autoComplete: 'new-password',
            shouldHide: (data) => data.appScreen,
            transformValue: (v) => cleanSlugInput(v),
        },

        {
            key: 'isPublished',
            label: 'Publish page',
            type: 'switch',
            options: [
                {
                    value: 'true',
                    label: 'Publish',
                },
                {
                    value: 'false',
                    label: 'Draft',
                },
            ],
        },
        {
            key: 'isPublic',
            label: 'Public page',
            type: 'checkbox',
            info: 'Public pages are accessible to anyone, even if they are not logged in.',
        },
        {
            key: 'showOnClientMenu',
            label: 'Show on client Menu',
            type: 'checkbox',
        },
        {
            key: 'showOnTeamMenu',
            label: 'Show on team Menu',
            type: 'checkbox',
        },
        {
            key: 'isGlossary',
            label: 'Group with alphabet',
            type: 'checkbox',
            info: 'Assign pages to it, and they will appear in glossary format in it.',
            shouldHide: (data) => data.appScreen,
        },
        {
            key: 'parentPageId',
            label: 'Parent page',
            type: 'liveSearch',
            shouldHide: (data) => data.appScreen,
            getResultValue: (item) => item.title,
            url: '/v1/pages?pageType=glossary',
            getItemsFromResults: (data) => data?.results || [],
            limit: 20,
            infoBottom:
                'Slug will be under the parent page path. If parent is type glossary, this page will be shown in glossary format in it.',
        },
    ]

    function mapItemToData(item) {
        return {
            slug: item.slug,
            title: item.title,
            isGlossary: item.pageType === 'glossary',
            content: item.content,
            tags: item.tags,
            isPublic: item.isPublic,
            isPublished: item.isPublished ? 'true' : 'false',
            isHtml: item.isHtml,
            sassCode: item.sassCode,
            showOnClientMenu: item.showOnClientMenu,
            showOnTeamMenu: item.showOnTeamMenu,
            parentPageId: item.parentPage?.title,
        }
    }

    async function getBody(data, item) {
        const body = { ...data }
        if (data.isGlossary) {
            body.pageType = 'glossary'
        } else {
            body.pageType = 'default'
        }
        delete body.isGlossary

        if (data.parentPageId === item.parentPage?.title) {
            delete body.parentPageId
        }

        body.isPublished = data.isPublished === 'true'

        const finalContent = data.content
        body.content = finalContent

        return body
    }

    async function confirmMerge(otherPageIds, setError) {
        setError('')
        if (!otherPageIds || otherPageIds.length === 0) {
            setError('No pages selected', [true])
            return
        }

        setModalLoading(true)
        const { error, meta } = await fetchAPI(
            `/v1/pages/merge?pageId=${page.id}&${otherPageIds
                .map((id) => `otherPageIds[]=${id}`)
                .join('&')}`,
            {},
            'POST',
        )
        if (error) {
            setError(error, meta?.fields?.length ? [true] : [])
            setModalLoading(false)
            return
        }

        setToast('Pages merged. Refresh the page to see the changes.')
        setModal(null)
        setModalLoading(false)
        matchMutate(/\/v1\/pages/)
    }

    function onMerge() {
        setModal(
            <InputModalContent
                topHtml={
                    <div className="text-subdued">
                        The content from the other pages will be added to this
                        page. Only styles of this page will apply. Other pages
                        will be kept as unpubished.
                    </div>
                }
                label="Tag"
                type="liveSearchAddItems"
                confirmText="OK"
                getItemText={(item) => item.title}
                getItemsFromResults={(data) => data?.results || []}
                url="/v1/pages"
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.title,
                    },
                ]}
                onCancel={() => {
                    setModal(null)
                }}
                onConfirm={async (data, setError) => {
                    confirmMerge(
                        data.items.map((item) => item.id),
                        setError,
                    )
                }}
            />,
            'Merge pages',
            'modal-full',
        )
    }

    function onDelete() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/pages/admin/${page.id}`,
                        {},
                        'DELETE',
                    )

                    if (error) {
                        setModal(null)
                        setModalLoading(false)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setModalLoading(false)
                    navigate(`/edit-pages`)
                    setToast('Page deleted')
                }}
            />,
            'Are you sure you want to delete this page?',
        )
    }

    async function onExportGroupXlsx() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?${page.pageType === 'glossary' ? `parentPageId=${page.id}` : `pageIds[]=${page.id}`}&format=xlsx`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const arrayBuffer = base64ToArrayBuffer(responseData.xlsxStr)

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a URL for the Blob and trigger the download
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportGroupCsv() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?${page.pageType === 'glossary' ? `parentPageId=${page.id}` : `pageIds[]=${page.id}`}&format=csv`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { csvStr } = responseData

        const blob = new Blob([csvStr], { type: 'text/csv' })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.csv'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportPdf() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?pageId=${page.id}&format=pdf`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { pdfStr } = responseData

        const arrayBuffer = base64ToArrayBuffer(pdfStr)

        const blob = new Blob([arrayBuffer], {
            type: 'application/pdf',
        })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'page.pdf'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportDoc() {
        const htmlContent = (page?.content || '')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/“/g, '"')
            .replace(/”/g, '"')
        const body = await replaceImgWithDataUrls(htmlContent)
        const docContent = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><style>${page.cssCode
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/“/g, '"')
            .replace(
                /”/g,
                '"',
            )}</style><title>Document</title></head><body class="page-${page.id}"><h1>${page.title}</h1><hr />${body}</body></html>`

        const blob = new Blob([docContent], {
            type: 'application/msword',
        })

        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = 'page.doc'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    function base64ToArrayBuffer(base64) {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }

    function onCopyUrl() {
        copyToClipboard(
            `${process.env.REACT_APP_PUBLIC_URL}/pages/${page.slug}`,
        )
        setToast('Copied to clipboard')
    }

    function onShare() {
        const shareData = {
            title: page.title,
            text: page.title,
            url: `${process.env.REACT_APP_PUBLIC_URL}/pages/${page.slug}`,
        }

        navigator?.share(shareData)
    }

    if (pageLoading) {
        return <Spinner />
    }

    if (pageError) {
        return (
            <SectionContainer>
                <ErrorMessage>{pageError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <InlineStack gap={'sm'} spaceBetween>
                    <InlineStack gap={'sm'} wrap>
                        {page.tags.map((tag) => (
                            <Tag outline noMargin key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </InlineStack>
                    <InlineStack gap={'sm'} justifyEnd>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'View page',
                                    onClick: onViewPage,
                                    icon: ICONS.EYE_GRAY,
                                },
                                {
                                    title: 'Export XLSX',
                                    onClick: onExportGroupXlsx,
                                    icon: ICONS.DOWNLOAD_GRAY,
                                },
                                {
                                    title: 'Export CSV',
                                    onClick: onExportGroupCsv,
                                    icon: ICONS.DOWNLOAD_GRAY,
                                },
                                {
                                    title: 'Export PDF',
                                    onClick: onExportPdf,
                                    icon: ICONS.DOWNLOAD_GRAY,
                                },
                                {
                                    title: 'Export DOC',
                                    onClick: onExportDoc,
                                    icon: ICONS.DOWNLOAD_GRAY,
                                },

                                {
                                    title: 'Merge',
                                    onClick: onMerge,
                                    hide: !auth.isAdmin && !auth.isAssistant,
                                },
                                {
                                    title: 'Permissions',
                                    icon: ICONS.LOCK_GRAY,
                                    onClick: () =>
                                        navigate(
                                            `/settings/permissions/page/${page.id}`,
                                        ),
                                    hide: !auth.isAdmin,
                                },
                                {
                                    title: 'Delete',
                                    onClick: onDelete,
                                    hide: !auth.isAdmin,
                                    type: 'alert',
                                    icon: ICONS.TRASH_RED,
                                },
                                {
                                    title: 'Share',
                                    onClick: onShare,
                                    icon: ICONS.SHARE_2_GRAY,
                                },
                                {
                                    title: 'Copy URL',
                                    onClick: onCopyUrl,
                                    icon: ICONS.CLIPBOARD_GRAY,
                                },
                            ]}
                        />
                    </InlineStack>
                </InlineStack>
            </SectionContainer>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/pages/admin/${pageId}`}
                    method="PATCH"
                    submitText="Save"
                    submitToast="Saved"
                    inputs={inputs}
                    mapItemToData={mapItemToData}
                    getBody={getBody}
                    mainButton
                    mutationRegexes={[/\/v1\/translations/, /\/v1\/pages/]}
                />
            </SectionContainer>
        </>
    )
}
