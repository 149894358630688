import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import MessageSection from '../../components/common/MessageSection'
import Break from '../../components/common/Break'

export default function useUrlMessage(key, content) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [show, setShow] = useState(searchParams.has(key))

    const messageHtml = (
        <>
            <MessageSection
                type="success"
                onDismiss={() => {
                    setSearchParams((prev) => {
                        prev.delete(key)
                        return prev
                    })
                    setShow(false)
                }}
            >
                <div>{content}</div>
            </MessageSection>
            <Break />
        </>
    )

    return show && content && messageHtml
}
